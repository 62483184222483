/* eslint-disable indent */
/* eslint-disable max-len */
import { FilterOptions } from "../models/filterOptions";
import { useGetClientIdWithSlug, usePractionerPreview } from "../services/react-query/choices.service";
import styles from './practitioner.module.scss';
import FilterControls from "./filter/FilterControls";
import ProductTypeId from "../models/productTypeId";
import PractionerCard from '../components/practitioners/PractitionerCard';
import Loading from "./loading/Loading";
import { geocode, usePosition, defaultZipCode } from '../services/geolocation.service';
import React, { createRef, useState, useEffect } from 'react';
import { useChoicesContext } from "../context/ChoicesContext";
import loadingStyles from './loading/Loading.module.scss';
import EnvironmentService from "../services/environment.service";

const PractionerPreview = () => {
  const [searchRadius, setSearchRadius] = useState(50);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [RestrictedState, setRestrictedState] = useState(false);
  const subCategories = [1, 2, 3, 4, 6, 7, 10, 13, 14, 15, 16, 18, 19, 20];
  const [currentLocation, setCurrentLocation] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [practionerData, setPractitionerData] = useState();
  const {
    latitude,
    longitude,
    searchAddress,
    searchCoordinates,
    setSearchAddress,
    setSearchCoordinates,
    isSuccess: hasFoundPosition,
    isError: isGeoError,
  } = usePosition();

  const { mutateAsync: sendPractionerRequest } = usePractionerPreview();
  const { mutateAsync: sendClientIdWithSlugRequest } = useGetClientIdWithSlug();

  const handleClearFilters = () => {
    setSelectedSubCategories(subCategories);

    if (!ProductTypeId === ProductTypeId.FACILITY)
      subCategories.map((option, index) => checkboxReferences[index].current.checked = false);
  };
  const handleRadiusFilterChange = (event) => {
    const MILE_RADIUS_UPPER_BOUNDS = 1000;
    if (event.target.value > -1 && event.target.value <= MILE_RADIUS_UPPER_BOUNDS) {
      setSearchRadius(event.target.value);
    }
  };
  const handleSearchAddressChange = (event) => {
    const newAddress = event.target.value;
    if (newAddress.length > 90) return;
    setSearchAddress(newAddress);
  };
  const handleSelectedSubCategoryChange = () => {
    let checkedSubcategories = subCategories.filter(
      (option, index) => checkboxReferences[index].current.checked);
    setSelectedSubCategories(checkedSubcategories);
  };

  const checkboxReferences = (subCategories || []).map(() => createRef());

  const handleSearchSubmission = async () => {
    await geocode(
      searchAddress,
      setRestrictedState,
      setSearchCoordinates
    ).then(async (locationResults) => {
      filterSubmission(locationResults);
    });
  };

  const filterSubmission = async (locationResults, clientId) => {
    const currentAddress = window.localStorage["choices-search-address"];
    currentAddress && currentAddress !== '' ?
      setSearchAddress(currentAddress)
      : setSearchAddress(defaultZipCode);

    if (locationResults) {
      const state = locationResults.address_components.filter(
        (components) => components['types'][0] === 'administrative_area_level_1'
      );

      currentAddress ? setCurrentLocation(locationResults.formatted_address) : null;
      window.localStorage['choices-state'] = state[0].short_name;
      const search = await sendPractionerRequest(searchRadius, clientId);

      if (search.data && search.data.totalCount > 0) {
        const searchPractitioners = Array.from(search.data.items);
        const foundPractitioners = [];
        const varyingSpecialties = new Set();

        searchPractitioners.forEach((practitioner) => {
          varyingSpecialties.add(practitioner.subCategoryNames[0]);
        });

        Array.from(varyingSpecialties).forEach((specialty, index) => {
          index <= 3 ?
            foundPractitioners.push(
              searchPractitioners.find((practitioner) => practitioner.subCategoryNames[0] === specialty)
            ) : null;
        });

        setPractitionerData(foundPractitioners);
        setShowPreview(true);

      } else {
        setShowPreview(false);
      }
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    if (window.sessionStorage['choices-referring-domainUrl'].includes('bcbsa')) {
      await sendClientIdWithSlugRequest(window.sessionStorage['choices-referring-domainUrl']).then(async () => {
        var intervalId = setInterval(() => {
          const address = window.localStorage['choices-search-address'];
          if (address) {
            setSearchAddress(address);
            geocode(address, setRestrictedState, setSearchCoordinates)
              .then((locationResults) => filterSubmission(locationResults))
              .catch((error) => console.log(error));
            clearInterval(intervalId);
          }
        }, 750);
        setTimeout(() => {
          const address = window.localStorage['choices-search-address'];
          if (address === '' || !address || !navigator.geolocation) {
            window.sessionStorage['choices-search-address'] = defaultZipCode;
          }
        }, 4000);
        setTimeout(() => {
          clearInterval(intervalId);
        }, 40000);
      });
    } else {
      // Add other referring logic here if necessary
    }

  }, []);

  return <div className={styles.practitionerContainer}>
    <div className={styles.filterOptions}>
      <div className={styles.practitionerHeader}>
        Preview a selection of practitioners in your area <br />
        <span style={{ color: "#0076BE" }}> {currentLocation ? currentLocation : '...'} </span>
      </div>

      <FilterControls
        filterOptions={[
          FilterOptions.SUBCATEGORIES,
          FilterOptions.LOCATION,
          FilterOptions.RADIUS,
        ]}
        isPreview={true}
        subCategories={[]}
        searchAddress={searchAddress}
        searchRadius={searchRadius}
        // isDisabled={isLoading || !subCategories || (!isSuccess && !isError)}
        checkboxReferences={checkboxReferences}
        handleRadiusFilterChange={handleRadiusFilterChange}
        handleSearchAddressChange={handleSearchAddressChange}
        handleClearFilters={handleClearFilters}
        handleSelectedSubCategoryChange={handleSelectedSubCategoryChange}
        handleSearchSubmission={handleSearchSubmission}
      />
    </div>

    <div className={styles.productGrid}>
      {showPreview ?
        practionerData.map((practitioner, index) => {
          return (
            <div key={practitioner.id} className={styles.cardResult}>
              <PractionerCard practitioner={practitioner} isPreview={true} />
            </div>
          );
        })
        : <div className={styles.ldn}>
          {isLoading ?
            <div className={loadingStyles.loadingContainer}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 100 100">
                <path
                  fill="#0076BE"
                  className="cls-2"
                  d="M12.44,21.5C17.8,100,61.93,80.69,61.93,80.69l-1.26-3.76-4-3.47c-29.18,7.34-33.41-35-33.41-35A54,54,0,0,1,36,50.2L40.46,43C29,29.88,12.44,21.5,12.44,21.5Z"
                  transform="translate(-12.44 -6.62)"
                ></path>
                <path
                  fill="#0076BE"
                  d="M57.34,6.62C21.12,76.48,68.86,82.9,68.86,82.9L69.73,79,68.12,74c-28.75-8.9-10.33-47.31-10.33-47.31a63.57,63.57,0,0,1,5,18.13l7.78-3.37C68.09,24.2,57.34,6.62,57.34,6.62Z"
                  transform="translate(-12.44 -6.62)"
                ></path>
                <path
                  fill="#0076BE"
                  d="M73,92.91s-37.8-29.86,29-71.5c0,0,.91,42.48-21.36,55.19l-3.4-7.71s12.38-7.18,14.91-30c0,0-37.5,25-13.34,47.89Z"
                  transform="translate(-12.44 -6.62)"
                ></path>
              </svg>
            </div>
            : 'No practitioners found from location'}
        </div>
      }
    </div>
  </div >;
};

export default PractionerPreview;
