import React, { useState } from 'react';
import validator from 'validator';
import ActionButton from '../action-button/ActionButton';
import { FormTitle, Input, invalidCharacters, invalidFieldLength } from '../form/Form';
import Loading from '../loading/Loading';
import ContactDto from '../../dto/ContactDto';
import ContactErrors from '../../models/contactErrors';
import styles from './ContactForm.module.scss';
import { useSendContactEmailMutation } from '../../services/react-query/choices.service';

const ContactForm = () => {
    const [contact, setContact] = useState({firstName: "", lastName: "", email: "", healthPlan: ""});
    const [submissionMessage, setSubmissionMessage] = useState("");
    const [messageStyle, setMessageStyle] = useState(styles.hidden);

    const { mutateAsync: sendContactEmail, isLoading: isSendingEmail } = useSendContactEmailMutation();

    const contactRules = [
        { 
            boolComparison:(invalidFieldLength(contact.healthPlan,90) || invalidCharacters(contact.healthPlan) 
                || (/[^a-zA-Z-' ]+$/.test(contact.healthPlan))),
            errorMsg: ContactErrors.HEALTH_PLAN_ERR
        },
        {
            boolComparison:!validator.isEmail(contact.email),
            errorMsg: ContactErrors.EMAIL_ERR
        },
        { 
            boolComparison:(invalidFieldLength(contact.lastName,90) || invalidCharacters(contact.lastName) 
                || (/[^a-zA-Z-' ]+$/.test(contact.lastName))),
            errorMsg: ContactErrors.LAST_NAME_ERR
        },
        {
            boolComparison: (invalidFieldLength(contact.firstName,90) || invalidCharacters(contact.firstName) 
                || (/[^a-zA-Z-' ]+$/.test(contact.firstName))),
            errorMsg: ContactErrors.FIRST_NAME_ERR
        }
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();
        let isContactRuleViolation = false;
        contactRules.forEach(rule=> { 
            if(rule.boolComparison) {
                setSubmissionMessage(rule.errorMsg); 
                setMessageStyle(styles.error);
                isContactRuleViolation = rule.boolComparison;
            }
        });
        if(isContactRuleViolation) return;
        
        const contactRequest = await sendContactEmail(new ContactDto(contact))
            .catch(error => error);

        if(contactRequest && contactRequest.isSuccess) {
            setSubmissionMessage(contactRequest.message);
            setMessageStyle(styles.success);
            return setContact({firstName: "", lastName: "", email: "", healthPlan: ""});
        } else {
            setSubmissionMessage(ContactErrors.CONTACT_ERR); 
            return setMessageStyle(styles.error);
        }
    };

    const handleChange = (event,property) => {
        if(event.target.value.length > 90) return;
        let newState = {...contact};
        Object.defineProperty(newState,property,{value:event.target.value});
        setContact(newState);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <FormTitle title="Contact Us"/>
            <section className={styles.formInputs}>
                <div className={styles.formItem}>
                    <div className={styles.inputContainer}>
                        <Input 
                            inputId="firstName"
                            label="First Name"
                            inputType="text" 
                            inputValue={contact.firstName}
                            onChange={(event)=>handleChange(event,"firstName")}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <Input 
                            inputId="lastName"
                            label="Last Name"
                            inputType="text" 
                            inputValue={contact.lastName}
                            onChange={(event)=>handleChange(event,"lastName")}

                        />
                    </div>
                </div>
                <div>
                    <Input
                        inputId="email"
                        label="Email Address"
                        inputType="email"
                        inputValue={contact.email}
                        onChange={(event)=>handleChange(event,"email")}
                    />
                </div>
                <div>
                    <Input
                        inputId="healthPlan"
                        label="Health Plan Name"
                        inputType="text"
                        inputValue={contact.healthPlan}
                        onChange={(event)=>handleChange(event,"healthPlan")}
                    />
                </div>
                <menu className={styles.formMenu}>
                    <div className={styles.formBtns}>
                        <ActionButton btnType="submit">Submit</ActionButton>
                        <span className={`${styles.message} ${messageStyle}`}>{submissionMessage}</span>
                    </div>
                </menu>
            </section>
            <Loading isLoading={isSendingEmail} isFullScreen></Loading>
        </form>
    );
};

export default ContactForm;