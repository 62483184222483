import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Layout from "../../components/layout/Layout";
import ActionButton from '../../components/action-button/ActionButton';
import { useConfirmEmailMutation, useResendEmailConfirmationMutation } 
    from '../../services/react-query/identity.service';

const ConfirmEmail = () => {
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    
    const [confirmationMessage, setConfirmationMessage] = useState({ firstMsg: "", secondMsg: "" });
    const [confirmationAction, setConfirmationAction] = 
        useState({btnText: "Visit Site", onClick: ()=> navigate("/login")});

    const { mutateAsync: confirmEmail, isLoading: isConfirmingEmail } = useConfirmEmailMutation();
    const { mutateAsync: resendEmailConfirmation, isLoading: isResendingEmail } = useResendEmailConfirmationMutation();

    const handleResendEmail = async () => {
        const resentConfirmation = await resendEmailConfirmation({
            userId: currentParams.userId, 
            whlServerUrl: window.origin
        }).catch(error => error);
        if(resentConfirmation.error) {
            setConfirmationMessage({
                firstMsg: "There was an error processing your email confirmation.",
                secondMsg: "Please contact whlchoices@tivityhealth.com for assistance."
            });
        } else {
            setConfirmationMessage({
                firstMsg: "A new confirmation email has been sent to your email address.",
                secondMsg: "For further assistance, please contact whlchoices@tivityhealth.com."
            });
        }
        return setConfirmationAction({btnText: "Visit Site", onClick: ()=> navigate("/login")});
    };
    
    // Use CSS-In-JS here as style requirements are minimal
    const styles = {
        display: "flex",
        flexDirection: "column",
        height: "70vh", 
        width: "100%", 
        alignItems: "center", 
        fontSize: "2rem", 
    };

    useEffect(async ()=> {
        if(currentParams.code && currentParams.userId) {
            // Validate the code from URL Params
            const emailConfirmation = 
                await confirmEmail({userId: currentParams.userId, code: currentParams.code}).catch(error => error);
            
            // If code is invalid, set error text and button onClick function
            if(emailConfirmation.error) {
                
                const confirmationTokenHasExpired = 
                    JSON.stringify(emailConfirmation.error).includes("Invalid token");
                
                if(confirmationTokenHasExpired) {
                    setConfirmationMessage({
                        firstMsg: "Your email confirmation token has expired.",
                        secondMsg: "Click the link below to resend the email confirmation."
                    });
                    return setConfirmationAction({
                        btnText: "Resend",
                        onClick: async ()=> await handleResendEmail()
                    });
                } else return setConfirmationMessage({
                    firstMsg: "There was an error processing your email confirmation.",
                    secondMsg: "Please contact whlchoices@tivityhealth.com for assistance."
                });
            }
            setConfirmationMessage({
                firstMsg: "Thank you for confirming your email!",
                secondMsg: "You are being automatically redirected to whlchoices.com."
            });

            return setTimeout(()=> {
                navigate("/login");
            }, 2000);
        }
    },[searchParams]);

    return (
        <Layout pageTitle={'Confirm Email'}>
            <div style={styles}>
                <p>{confirmationMessage.firstMsg}</p>
                <p>{confirmationMessage.secondMsg}</p>
                <div style={{marginTop: "2rem"}}>
                    <ActionButton onClick={confirmationAction.onClick}>{confirmationAction.btnText}</ActionButton>
                </div>
            </div>
            <Loading isFullScreen={true} isLoading={isConfirmingEmail || isResendingEmail} />
        </Layout>
    );
};

export default ConfirmEmail;