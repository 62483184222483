import React, { useState, useEffect } from 'react';
import styles from './CancelSubscriptionInput.module.scss';
import ActionButton from "../action-button/ActionButton";
import LogoMark from './LogoMark';

const CancelSubscriptionModal = (props) => {
    
    const handleSubmit = async (event, value) => {
        event.preventDefault();
        props.onButtonClick(value);             
    };
    
    return (
        <div className={styles.modal}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeaderContainer}>
                    <h1 className={styles.modalHeader}>
                    Are you sure you want to cancel?
                    </h1>
                </div>
                <div className={styles.modalFormContainer}> 
                    <div className={styles.modalButtonComplete}>
                        <ActionButton
                            btnType="submit"
                            onClick={(event) => handleSubmit(event,false)}
                        >
                            No
                        </ActionButton>
                    </div> 
                    <div className={styles.modalButtonCompleteRight}>
                        <ActionButton
                            additionalClasses={[styles.btnBlue]}
                            btnType="submit"                              
                            onClick={(event)=>handleSubmit(event,true)}>   
                            Yes
                        </ActionButton>
                    </div>      
                </div>
                <LogoMark />
            </div>
        </div>
    );
};

export default CancelSubscriptionModal;