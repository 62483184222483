/* eslint-disable indent */
import React from "react";
import styles from "./PractitionerCard.module.scss";
import ActionButton from '../../components/action-button/ActionButton';
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../services/analytics.service";

const PractionerCard = ({practitioner, isRecentActivity, isPreview}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        AnalyticsService.trackOfferView(practitioner);
        navigate(`/practitioners/${practitioner.id}`);
    };

    return (
        <article className={styles.cardContainer}>
            <header className={styles.cardHeader}>{practitioner.subCategoryNames[0]}</header>
            <section className={styles.cardBody}>
                <div className={styles.practitionerName}>
                    {practitioner.name}{!practitioner.suffix ? '' : `, ${practitioner.suffix}`}
                </div>
                <div className={styles.distance}>
                    <img src="/images/locationIcon.svg" alt="Map Marker Icon" />
                    {isRecentActivity ? 
                        <span className={styles.distanceText}>
                            {`${practitioner.city}, ${practitioner.state}`}
                        </span> :
                        <span className={styles.distanceText}>
                            {practitioner.distance  === 1 ? 
                                `${practitioner.distance} Mile Away` :`${practitioner.distance} Miles Away`}
                        </span> }
                </div> 
                <div className={styles.discountPercentage}>
                    {practitioner.discount > 0 && practitioner.discount < 100 ? 
                        <div className={styles.discountPercentage}>
                            {practitioner.discount}%<sup><small>OFF</small></sup>
                        </div>
                        : 
                        <div className={styles.discountDisclaimer}>
                        See Offer Details
                        </div>}
                </div>
                <div className={styles.practitionerMetadata}>
                    <span>
                        <span className={styles.metadataHeader}>Gender Identity: </span>
                        {!practitioner.sex ? "N/A" : practitioner.sex}
                    </span>
                    <span>
                        <span  className={styles.metadataHeader}>Speaks: </span>
                        {!practitioner.languageDisplay ? "N/A" : <>{practitioner.languageDisplay} &nbsp;</> }
                    </span>
                    <span>
                        <span className={styles.metadataHeader}>Years of Practice: </span>
                        {practitioner.yearsInPractice < 1 || practitioner.yearsInPractice > 50 ?
                            "N/A" : practitioner.yearsInPractice}
                    </span>
                </div>
                {isPreview ? null :
                     <div className={styles.btnContainer}>
                     <ActionButton
                         onClick={handleClick}
                         btnType= "button"
                     >View Details</ActionButton>
                 </div>
                }
               
            </section>
        </article>
    );
};

export default PractionerCard;
