import { Link } from 'react-router-dom';
import styles from './IconLink.module.scss';

function LinkWithIcon({ srcIcon, linkTo, linkText }) {
    return (
        <div className={styles.iconContainer}>
            <Link to={`/resource-center/${linkTo}`} className={styles.iconLink}>
                {srcIcon}
                {linkText}
            </Link>
        </div>
    );
}

export default LinkWithIcon;