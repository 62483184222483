export default class ProductDescription {
    constructor() {
        this.tagline;
        this.details;
        this.terms;

        this._tagline = "";
        this._details = {
            introduction: "",
            features: []
        };
        this._terms = {
            offer: "",
            offerDisplayHtml: '<span>See offer details</span>',
            conditions: [],
            linkText: []
        };
    }

    get _tagline()  { return this.tagline; }
    get _details()  { return this.details; }
    get _terms()    { return this.terms; }
    
    set _tagline(string)  { this.tagline =string; }
    set _details(obj)  { this.details = obj; }
    set _terms(obj)    { this.terms = obj; }
}