import React from 'react';
import styles from './PractitionerLocations.module.scss';
import ActionButton from '../../../../components/action-button/ActionButton';

const PractitionerLocations = ({practitioner}) => {
    const handleGetDirections = (practitioner) => {
        const origin = 
            `${localStorage.getItem('choices-latitude')},${localStorage.getItem('choices-longitude')}`;
        const destination = 
            `${practitioner.addressLine1}+${practitioner.city},+${practitioner.state}+${practitioner.zipCode}`;

        window.open(`https://www.google.com/maps/dir/${origin}/${destination}`);
    };

    return (
        <div className={styles.locationMeta}>
            <div className={styles.locations}>
                <div 
                    className={styles.addressColumn}>
                    <div className={styles.distanceMeta}>
                        <img src="/images/locationIcon.svg"
                            alt="Map Marker Icon" />
                        <span>
                            {practitioner.distance > 0 ? 
                                practitioner.distance : "Less than one"} {practitioner.distance === 1 ? 
                                "Mile" : "Miles"} Away
                        </span>
                    </div>
                    <p>{practitioner.organizationName}</p>
                    <p>{practitioner.addressLine1}</p>
                    <p>{practitioner.city}, {practitioner.state} {practitioner.zipCode.substring(0,5)}</p>
                    {!practitioner.phoneNumber ? undefined : 
                        <div className={styles.phoneMeta}>
                            <img src="/images/phone.svg"
                                alt="Phone Icon" />
                            <span type="tel">
                                <a href={`tel:${practitioner.phoneNumber}`}>
                                ({practitioner.phoneNumber.substring(0, 3)}) {practitioner.phoneNumber.substring(3, 6)}
                                -{practitioner.phoneNumber.substring(6, 10)}
                                </a>
                            </span>
                        </div> }
                    <div className={styles.directionsBtn}>
                        <ActionButton 
                            btnType="button"
                            onClick={()=> handleGetDirections(practitioner)}
                        >
                            Get Directions
                        </ActionButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PractitionerLocations;