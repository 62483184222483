export default class PrintOfferDto {
    // Print Offer  data is not sent to the server, but sent to a new browser window
    constructor(practitioner, fitnessCenter) {
        if(!fitnessCenter && practitioner) {
            return this.instantiatePractitionerPrintOffer(practitioner);
        }
        if(fitnessCenter && !practitioner) {
            return this.instantiateFitnessCenterPrintOffer(fitnessCenter);
        }
    }

    instantiatePractitionerPrintOffer(practitioner) {
        this.productType = "PRACTITIONER";
        this.member = this.getMember();
        this.offer = this.getOffer(practitioner),
        this.website = this.getWebsite(practitioner),
        this.expiration = this.getExpirationDate();
        this.nameSuffix = this.getNameSuffix(practitioner),
        this.addressPrimary = this.getAddressPrimary(practitioner);
        this.phone = this.getPhone(practitioner),
        this.organizationName = this.getOrganizationName(practitioner);
        this.additionalLocations = [];
        return this;
    }

    instantiateFitnessCenterPrintOffer(fitnessCenter) {
        this.productType = "FITNESS_CENTER";
        this.member = this.getMember();
        this.offer = this.getOffer(fitnessCenter),
        this.website = this.getWebsite(fitnessCenter),
        this.expiration = this.getExpirationDate();
        this.nameSuffix = this.getNameSuffix(fitnessCenter);
        this.addressPrimary =this.getAddressPrimary(fitnessCenter),
        this.phone = this.getPhone(fitnessCenter),
        this.discountComments = fitnessCenter.discountComments || "";
        return this;
    }

    getExpirationDate() {
        let today = new Date();
        return new Date(today.setFullYear(today.getFullYear() + 1)).toLocaleDateString();
    }

    getMember() {
        let firstName = localStorage.getItem('choices-firstName') || "";
        let lastName = localStorage.getItem('choices-lastName') || "";
        return `${firstName} ${lastName}`;
    }

    getOffer(object) { return !object.discount ? 0 : object.discount; }

    getWebsite(object) { !object.webSiteAddress ? "" : object.webSiteAddress; }

    getNameSuffix(object) { return !object.suffix ? (object.name || "") : `${object.name || ""}, ${object.suffix}`;}

    getAddressPrimary(object) {
        return `${object.addressLine1 || ""}
        ${object.city || ""}, ${object.state || ""} ${!object.zipCode ? "" : object.zipCode.substring(0,5)}`;
    }

    getPhone(object) {
        return !object.phoneNumber ? "" : 
            // eslint-disable-next-line max-len
            `(${object.phoneNumber.substring(0, 3)}) ${object.phoneNumber.substring(3, 6)}-${object.phoneNumber.substring(6, 10)}`;
    }

    getOrganizationName(object) { return !object.organizationName ? "" : object.organizationName; }
}
