/* eslint-disable max-len */
import React, { useMemo , useState } from "react";
import styles from './Pagination.module.scss';

const Pagination = ({
    pageLimit,
    selectedPageNumber,
    totalRecords,
    handlePageNumberClick
}) => {
    const [pages, setPages] = useState([1]);
    const [pageButtons, setPageButtons] = useState([1,2,3]);

    // Set the number of pages displayed on the UI based on total records / page limit
    useMemo(()=> {
        let pageCount = Math.ceil(totalRecords / pageLimit);
        let pageArray = [];
        for(let i = 1; i <= pageCount; i++) {
            pageArray.push(i);
        }
        setPages(pageArray);

        if (pageCount < 3) setPageButtons(pageArray);
        else if (selectedPageNumber === 1) setPageButtons([1,2,3]);
        
        /* Timeout is set to coincide with user input debounce, 
        so the user sees a smooth transition between the loading animation,
        the practitioner card animation, and the scroll effect */
        setTimeout(()=>window.scrollTo(0,0), 700); 

    }, [selectedPageNumber,totalRecords]);

    const handleArrowClick = (operator) => {
        // Disable button if it reaches the first or last page number
        if((selectedPageNumber === 1 && operator === -1) || 
            (selectedPageNumber === pages.length && operator === 1)) return;

        // Update the three page buttons to the next three values after the user iterates past or before them
        if(operator === 1 && pageButtons[2] < selectedPageNumber + operator) 
            setPageButtons([selectedPageNumber +1, selectedPageNumber+2,selectedPageNumber+3]);
        if(operator === -1 && pageButtons[0] > selectedPageNumber + operator) 
            setPageButtons([selectedPageNumber-3, selectedPageNumber-2,selectedPageNumber-1]);

        /*
         If the user iterates to the first or last page button, 
         display it as the first or last entry in the pageButtons array
         */
        if(selectedPageNumber + operator >= pages.length) setPageButtons([pages.length-2,pages.length-1,pages.length]);
        if(selectedPageNumber + operator <= 1) setPageButtons([1,2,3]);

        handlePageNumberClick(selectedPageNumber+operator);
    };

    return (
        <menu className={styles.paginationContainer}>
            <button onClick={()=>handleArrowClick(-1)} className={selectedPageNumber === 1 ? styles.disabled : styles.btn}>
                <svg className={styles.arrow} width="30" height="25" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.08329 12.0812L7.49329 10.6712L2.91329 6.08118L7.49329 1.49118L6.08329 0.0811768L0.0832863 6.08118L6.08329 12.0812Z" fill="#001C32"/>
                </svg>
            </button>
            <div className={styles.pageNumbers}>
                {
                    pageButtons.map(btn => {
                        return (
                            <button 
                                onClick={()=>handlePageNumberClick(btn)}
                                className={`${styles.pageNumber} ${selectedPageNumber === btn ? styles.highlight : undefined}`} 
                                key={btn}>
                                <span>{btn}</span>
                            </button>
                        );
                    })
                }
            </div>
            <button type="button" onClick={()=>handleArrowClick(1)} className={selectedPageNumber === pages.length ? styles.disabled : styles.btn}>
                <svg className={styles.arrow} width="30" height="25" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.49325 0.0811768L0.083252 1.49118L4.66325 6.08118L0.083252 10.6712L1.49325 12.0812L7.49325 6.08118L1.49325 0.0811768Z" fill="#001C32"/>
                </svg>
            </button>
            <span>Displaying results for {selectedPageNumber} of {pages.length > 0 ? pages.length : 1} Page{pages.length > 1 ? "s" : ""}</span>
        </menu>
    );
};

export default Pagination;