export default class DateConverter {
    static getFutureDate(dateString, daysNum) {
        const date = new Date(dateString);
        const targetDate = new Date(date.getTime() + daysNum * 24 * 60 * 60 * 1000);
        const month = String(targetDate.getMonth() + 1).padStart(2, '0');
        const day = String(targetDate.getDate()).padStart(2, '0');
        const year = targetDate.getFullYear();
        return `${month}/${day}/${year}`;
    }
    static getFormattedDate(dateString) {
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0], 10);
        const day = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);
        const suffixes = ["th", "st", "nd", "rd"];
        let suffix;
        if (day >= 11 && day <= 13) {
            suffix = "th";
        } else {
            suffix = suffixes[day % 10] || "th";
        }
        const monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];
        const monthName = monthNames[month - 1];
        const formattedDate = `${monthName} ${day}${suffix}, ${year}`;
        return formattedDate;
    }
}