/* eslint-disable max-len */
import React from 'react';
import styles from './ImageCarousel.module.scss';
import menuCircleOuter from '../../assets/images/menuCircleOuter.svg';

const CircleButton = props => {
    return (
        <button className={styles.menuBtn} onClick={props.handleClick} type="button" data-testid={props.positionClass}>
            <img loading="lazy" className={styles.outerCircle} src={menuCircleOuter}  alt="Selectable menu item"/>
            <svg className={`${styles.innerCircle} ${props.positionClass} ${props.activeClass}`} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" fill="white"
                    d="M8.43612 0.329834C13.1725 0.329834 16.2687 3.92989 16.2687 8.37041C16.2687 13.2317 13.1725 16.41 8.43612 16.41C4.11143 16.41 0.604492 13.2317 0.604492 8.37041C0.604492 3.92989 4.11143 0.329834 8.43612 0.329834Z" 
                />
            </svg>
        </button>
    );
};

export default CircleButton;