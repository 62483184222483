export default class AccountErrors {
    static get EMAIL_ERR() { return "Email is invalid."; }
    static get OLDPASS_ERR() { return "Old password entered does not match current password.";}
    // eslint-disable-next-line max-len
    static get NEWPASS_ERR() { return "New password must be greater than 8 characters and contain at least one of each: uppercase letter, lowercase letter, number.";}
    static get CONFIRMPASS_ERR() { return "New password and confirm new password do not match.";}
    static get SAMEPASS_ERR() { return "New password cannot match current password.";}
    static get EMAIL_REQUEST_ERR() { return "Email update failed. Check entries and try again.";}
    static get PASSWORD_REQUEST_ERR() { return "Password update failed. Check entries and try again.";}
    static get DELETE_ACCOUNT_ERR() { return "Account deletion failed.";}
    static get FIRST_NAME_ERR() { return "First name must be between 1-90 characters.";}
    static get LAST_NAME_ERR() { return "Last name must be between 1-90 characters.";}
    // eslint-disable-next-line max-len
    static get PASS_ERR() { return "Please provide your current password, a new password, and a new password confirmation."; }
    static get LOGOUT_ERR() { return "Logout failed. Please try again."; }
    static get STATE_ERR() { return "State of residence is required"; }
}
