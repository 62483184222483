import React from 'react';
import styles from './Filter.module.scss';

const FilterCheckbox = ({
    subCategories, 
    isDisabled, 
    handleSelectedSubCategoryChange,
    checkboxReferences,
}) => {
    return (
        <section className={styles.checkboxesContainer}>
            <label className={styles.checkboxesTitle}>Show results for:</label>
            {subCategories.length > 0 &&
                subCategories.sort((a,b) => a.name > b.name).map((subcategory, index) => {
                    return (
                        <div 
                            key={subcategory.id} 
                            className={!isDisabled ? styles.checkboxContainer : styles.disabledCheckbox}>
                            <input 
                                type="checkbox" 
                                id={subcategory.id} 
                                className={styles.checkbox} 
                                ref={checkboxReferences[index]} 
                                onChange={handleSelectedSubCategoryChange}
                                disabled={isDisabled}
                            />
                            <label htmlFor={subcategory.id} className={styles.checkboxLabel}>{subcategory.name}</label>
                        </div>
                    );
                })}
        </section>
    );
};

export default FilterCheckbox;