import React from 'react';
import Layout from '../../components/layout/Layout';
import ErrorPage from '../../pages/error/ErrorPage';

/* 
    Using class component as provided by React Docs
    ~ https://reactjs.org/docs/error-boundaries.html ~
*/
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service - Provided if/when we implement frontend logging
        console.error(error);
    }
  
    render() {
        if (this.state.hasError) {
        // Render custom fallback UI
            return (
                <Layout>
                    <ErrorPage 
                        imagePath="/images/error.svg" 
                        title={"Something went wrong."} 
                        isErrorBoundary/>
                </Layout>
            );
        }
  
        return this.props.children; 
    }
}

// This method can be used in catch() blocks to trigger an error that renders the fallback UI error page.
export const triggerErrorCondition = (errorMessage) => {
    const error =  new Error(errorMessage);
    this.setState({ error });
};