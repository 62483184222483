import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ProductDetailsPage.module.scss';
import DetailList from '../../../components/product-details/DetailList';
import Logo from '../../../components/logo/Logo';
import FeaturedOffers from '../../../components/offer/featured-offers/FeaturedOffers';
import ProductTypeId from '../../../models/productTypeId';
import ProductDescription from '../../../models/productDescription';
import OfferDetails from '../../../components/offer/offer-details/OfferDetails';
import Loading from '../../../components/loading/Loading';
import { useGetProductDetailsMutation } from '../../../services/react-query/choices.service';
import ProductSearchDto from '../../../dto/ProductSearchDto';
import Layout from '../../../components/layout/Layout';

const ProductDetailsPage = () => {
    const { productId } = useParams();
    const [ product, setProduct] = useState({});
    const [ description, setDescription] = useState({});
    const { mutateAsync: getProductDetails, isLoading: isRetrievingDetails } = useGetProductDetailsMutation();
    const cachedProducts = JSON.parse(localStorage.getItem('choices-products'));
    const navigate = useNavigate();

    useEffect(async ()=> {
        let cachedProduct;
        if(cachedProducts !== null) 
            cachedProduct = cachedProducts.filter(product=>product.id === parseInt(productId))[0];
        
        if(!cachedProduct) {
            const productDetail = await getProductDetails({
                productTypeId:ProductTypeId.VENDOR,
                id: productId,
                productSearchDto: new ProductSearchDto()
            }).catch(error=>error);

            if(productDetail) {
                setProduct(productDetail);
                setDescription(JSON.parse(productDetail.description || JSON.stringify(new ProductDescription())));
            } else navigate("/not-found");
        } else {
            setProduct(cachedProduct);
            setDescription(JSON.parse(cachedProduct.description || JSON.stringify(new ProductDescription())));
        }
    },[productId]);

    return ( 
        <Layout pageTitle={'Product Details'}>
            <div className={styles.pageContainer}>
                {!product || Object.keys(product).length < 1 || !description.terms ? undefined :
                    <OfferDetails
                        heading="Featured Offer"
                        productTypeId={ProductTypeId.VENDOR}
                        // eslint-disable-next-line max-len
                        imagePath={!product.image ? undefined : `/images/vendors/${product.image.substring(0,product.image.indexOf("."))}_details.jpg`}
                        discount={description.terms.offerDisplayHtml || '<span>See offer details</span>'}
                        title={product.name}
                        detailsTitle1="Details"
                        detailsTitle2="Terms"
                        description={!description ? "" : description.tagline}
                        offerCode={product.offerCode}
                        offerItem={ { practitioner: null, fitnessCenter: null, product: product} }
                        detailsChild1={
                            <DetailList 
                                productDetail={!description ? undefined : description.details} 
                            />
                        }
                        detailsChild2={
                            <DetailList 
                                productDetail={!description ? undefined : description.terms} 
                                isTerms  
                            />
                        }
                    />}
                <Logo/>
                {!cachedProducts || cachedProducts.length < 1 ? undefined :
                    <FeaturedOffers 
                        items={cachedProducts} 
                        title="Related Discounts"
                    />}
                <Loading isFullScreen isLoading={isRetrievingDetails} />
            </div>
        </Layout>
    );
};

export default withAuthenticationRequired(ProductDetailsPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
