import React, { useEffect }  from "react";
import { Input } from "../form/Form";
import styles from './AccountForm.module.scss';
import AccountService from "../../services/account.service";

const EmailInput = ({emailRef, accountEmail, setAccountEmail, setIsError}) => {
    const handleChange = (event) => {
        setIsError(false); // Remove any previously displayed errors
        setAccountEmail(event.target.value);
    };

    useEffect(()=> {
        const storedEmail = localStorage.getItem('choices-email');
        if(!accountEmail && storedEmail) setAccountEmail(storedEmail);
    },[]);

    return (
        <section className={styles.formItem} onClickCapture={()=>setIsError(false)}>
            <Input 
                inputId="email"
                label="Email Address"
                inputType="email" 
                inputValue={AccountService.isLooseNullOrUndefined(accountEmail)?"":accountEmail}
                onChange={handleChange}
                inputRef={emailRef}
                autocomplete={"new-username"}
            />
        </section>
    );
};

export default EmailInput;