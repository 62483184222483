import React from "react";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from "../../components/layout/Layout";
import Icon from "../../assets/icons/icon-circle-with-x.svg";
import Styles from "./SubscriptionError.module.scss";
import Logo from '../../components/logo/Logo';
import { Link } from 'react-router-dom';
import Loading from "../../components/loading/Loading";

const SubscriptionError = () => {
    return (
        <Layout pageTitle={'SubscriptionError'}>
            <div className={Styles.subscriptionErrorContainer}>
                <div className={Styles.topSection}>
                    <div className={Styles.xImg}>
                        <img
                            loading="lazy"
                            src={Icon}
                            alt="shaded circle with x" />
                    </div>
                    <h1 className={Styles.pageHeader}> There was an issue processing your payment. </h1>
                </div>
                <div className={Styles.heroMessage}>
                    <p>You may have enter invalid payment information, or pressed the "back" button during checkout.</p>
                </div>
                <div className={Styles.btnContainer}>
                    <Link className={Styles.tryAgainBtn} to="/contact"> 
                        Try Again
                    </Link>
                    <Link className={Styles.contactUsBtn} to="/contact"> 
                        Contact Us
                    </Link>
                </div>
            </div>
            <Logo />
        </Layout>
    );
};

export default withAuthenticationRequired(SubscriptionError, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
