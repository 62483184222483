import { useEffect } from "react";
import { useLocation } from "react-router";

/*
When the user navigates between pages, React uses the last scroll position, which can cause
a side-effect of the new page automatically loading at the last scroll position. This
component wraps all routes to ensure the scroll position starts at 0.
*/

const ScrollToTop = ({children}) => {
    const location = useLocation();
  
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = 1;
    }, [location]);

    return <>{children}</>;
};

export default ScrollToTop;