//these key value pairs are temporary and used as a placeholder data source in ResourceCenterDetailsPage.  
export default [
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "Managing Knee and Join Pain",
        cardReadTime: "5 minute read",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "knee-and-joint-pain"
        }
    },
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "The Benefits of Aromatherapy",
        cardReadTime: "10 minute podcast",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "benefits-of-aromatherapy"
        }
    },
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "Staying Connected to Your Health",
        cardReadTime: "12 minute read",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "staying-connected-to-your-health"
        }
    },
    // {
    //     cardBGimg: "",
    //     cardTitle: "",
    //     cardReadTime: "",
    //     cardButton: {
    //         cardButtonText: "",
    //         cardButtonColor: "",
    //         cardButtonSize: "",
    //         cardButtonLinkTo: ""
    //     }
    // },
];
