export default class NominatePractitionerErrors {
    static get FIRST_NAME_ERR()   { return "First name must be between 1-90 valid characters."; }
    static get LAST_NAME_ERR()    { return "Last name must be between 1-90 valid characters."; }
    static get ORG_NAME_ERR()     { return "Business/Practice Name must be between 1-90 valid characters."; }
    static get EMAIL_ERR()        { return "Email is invalid."; }
    static get COUNTRY_CODE_ERR() { return "Country code must be between one and three digits."; }
    static get PREFIX_ERR()       { return "Phone number prefix must be three digits long."; }
    static get AREA_CODE_ERR()    { return "Area code must be three digits long."; }
    static get LINE_NUMBER_ERR()  { return "Final portion of the phone number must be four digits long."; }
    static get NOMINATE_ERR()     { return "An error occurred. Please try again."; }
}