import React from 'react';
import styles from './AccountForm.module.scss';

const SubmitBanner = ({isError, isSuccess, errorMessages, setIsError, setIsSuccess}) => {
    return (
        <>
            {isError && errorMessages.length > 0 &&
                <div className={styles.errorMessage}>
                    <ul>Please correct the following errors:
                        {errorMessages.map((message, index)=> {
                            return <li key={index}>{message}</li>;
                        })}
                    </ul>
                    <button type="button" onClick={()=>setIsError(false)}>&#x2716;</button>
                </div>}
            {isSuccess &&
                <div className={styles.successMessage} onAnimationEnd={()=>setIsSuccess(false)}>
                    <p id="successMessage">Account information was updated successfully.</p>
                    <button type="button" onClick={()=>setIsSuccess(false)}>&#x2716;</button>
                </div>}
        </>
    );
};

export default SubmitBanner;