import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import styles from './ResourceCenterPage.module.scss';
import ArticleCard from '../../components/article-card/ArticleCard';
import StyledLink from '../../components/styled-link/StyledLink';
import SearchBar from '../../components/search-bar/SearchBar';
import IconLink from '../../components/icon-link/IconLink';
import popularData from './tempData/popular-data';
import Loading from "../../components/loading/Loading";

import { ReactComponent as FavoriteIcon } from "../../assets/icons/icon-favorite.svg";
import { ReactComponent as PodcastIcon } from "../../assets/icons/icon-podcast.svg";
import { ReactComponent as ArticleIcon } from "../../assets/icons/icon-article.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/icon-play.svg";
import { ReactComponent as TrendingIcon } from "../../assets/icons/icon-trending.svg";
import { ReactComponent as Home } from "../../assets/icons/icon-home.svg";
import { ReactComponent as Person } from "../../assets/icons/icon-person.svg";
import { ReactComponent as Email } from "../../assets/icons/icon-email.svg";

// note for return once re-prioritized: import useResourceSearch from './useResourceSearch';
// setting up infinite scroll for pagnation feature later.
//useParams: take url and make api call after rendering results page. (current)
//useRef: make api call then render new page with results.
const SearchResults = ({ linkTo }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { searchInput } = useParams();

    const searchResultCards = popularData.map((data, id) => {
        return (
            <div key={id} className={styles.searchResultsCardContainer}>

                <div className={styles.searchResultsCard}>
                    <ArticleCard
                        key={id}
                        cardBGimg={`../${data.cardBGimg}`}
                        cardTitle={data.cardTitle}
                        cardReadTime={data.cardReadTime}
                        cardButton={
                            <StyledLink
                                styledLinkText={data.cardButton.cardButtonText}
                                styledLinkColor={data.cardButton.cardButtonColor}
                                styledLinkSize={data.cardButton.cardButtonSize}
                                styledLinkLinkTo={data.cardButton.cardButtonLinkTo}
                            />
                        }
                    />
                </div>

                <div className={styles.extendedCardInfo}>
                    <h3 className={`${styles.cardDetails} ${styles.extendCardTitle}`}>{data.cardTitle}</h3>
                    <h3 className={`${styles.cardDetails} ${styles.extendCardIcon} ${styles.extendCardAuthor}`}>
                        {<IconLink
                            srcIcon={<Person />}
                            linkTo="search?category=author"
                            linkText="Author Name and Icon"
                        />}
                    </h3>
                    <p className={`${styles.cardDetails} ${styles.extendCardText}`}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia id voluptate debitis
                        ratione quis maxime!Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Officia id voluptate debitis ratione quis maxime!
                    </p>
                    <div className={styles.flexParent}>
                        <h3 className={`${styles.cardDetails} ${styles.extendCardIcon} ${styles.extendCardFavorite}`}>
                            {<IconLink
                                srcIcon={<FavoriteIcon />}
                                linkTo="search?category=favorites"
                                linkText="Add to my favorites"
                            />}
                        </h3>
                        <h3 className={`${styles.cardDetails} ${styles.extendCardIcon} ${styles.extendCardEmail}`}>
                            {<IconLink
                                srcIcon={<Email />}
                                linkTo="emailto:testemail@email.com"
                                linkText="Email to self"
                            />}
                        </h3>
                    </div>
                </div>
            </div >
        );
    });

    const featuredCards = popularData.map((data, id) => {
        return (
            <div key={id} className={`${styles.searchResultsCardContainerBottom}`}>
                <ArticleCard
                    key={id}
                    //pathing will need to be changed once DB is added.
                    cardBGimg={`../${data.cardBGimg}`}
                    cardTitle={data.cardTitle}
                    cardReadTime={data.cardReadTime}
                    cardButton={
                        <StyledLink
                            styledLinkText={data.cardButton.cardButtonText}
                            styledLinkColor={data.cardButton.cardButtonColor}
                            styledLinkSize={data.cardButton.cardButtonSize}
                            styledLinkLinkTo={data.cardButton.cardButtonLinkTo}
                        />
                    }
                />
            </div>
        );
    });

    return (
        <Layout pageTitle={'Resource Center'}>
            <div className={styles.pageContainerTwo}>

                <div className={styles.searchResultsContainer}>
                    <div className={styles.searchResultsTopBar}>

                        <div className={styles.resultsTopBarLeftColumn}>
                            <IconLink
                                srcIcon={<Home />}
                                linkTo="/"
                                linkTo="/"
                                linkText="Home"
                            />
                            <IconLink
                                srcIcon={<FavoriteIcon />}
                                linkTo="search?category=favorites"
                                linkText="Favorites"
                            />
                            <IconLink
                                srcIcon={<PodcastIcon />}
                                linkTo="search?category=podcasts"
                                linkText="Podcasts"
                            />
                            <IconLink
                                srcIcon={<PlayIcon />}
                                linkTo="search?category=videos"
                                linkText="Videos"
                            />
                            <IconLink
                                srcIcon={<ArticleIcon />}
                                linkTo="search?category=articles"
                                linkText="Articles"
                            />
                            <IconLink
                                srcIcon={<TrendingIcon />}
                                linkTo="search?category=popular"
                                linkText="Popular"
                            />
                        </div>

                        <div className={styles.resultsTopBarRightColumn}>
                            <SearchBar
                                linkTo={`resource-center`}
                            />
                        </div>
                    </div>


                    <div className={styles.cardsDisplay}>
                        {searchResultCards}
                    </div>
                </div>

                <div className={styles.resourceCenterFeaturedSection}>
                    <h2 className={styles.searchResultsArticleTitle}>Featured</h2>
                    <div className={styles.resourceCenterFeaturedCards}>
                        {featuredCards}
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default withAuthenticationRequired(SearchResults, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
