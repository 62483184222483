import React, { useState } from 'react';
import styles from './RegisterForm.module.scss';
import { Input } from '../form/Form';
import AccountIcon from '../account-form/AccountIcon';
import RegistrationErrors from '../../models/registrationErrors';
import AccountService from '../../services/account.service';

const RegisterPassword = ({
    signUpPassword,
    setSignUpPassword,
    confirmPassword,
    setConfirmPassword,
}) => {
    const initialState = {msg:undefined,style:undefined};
    const [invalidPass, setInvalidPass] = useState(initialState);
    const [invalidConfirmPass, setInvalidConfirmPass] = useState(initialState);

    const handleChange = (event, isConfirmPass) => {
        const input = event.target.value;
        if(input.length > 90) return;
        
        const passwordError = isConfirmPass ? RegistrationErrors.CONFIRMPASS_ERR : RegistrationErrors.PASS_ERR;
        
        !isConfirmPass ? setSignUpPassword(input) : setConfirmPassword(input);
        
        if(!isConfirmPass && !AccountService.enforcePasswordRules(input)) 
            return setInvalidPass({msg:passwordError,style:styles.inputError});

        if(isConfirmPass && input !== signUpPassword) 
            return setInvalidConfirmPass({msg:passwordError,style:styles.inputError});

        if(confirmPassword === signUpPassword) {
            setInvalidConfirmPass(initialState);
            setInvalidPass(initialState);
        } else {
            isConfirmPass ? setInvalidConfirmPass(initialState) : setInvalidPass(initialState);
        }
    };

    return (
        <section className={styles.formItem}>
            <div className={styles.inputContainer}>
                <Input 
                    inputId="newPass"
                    label="Password"
                    inputType="password" 
                    inputValue={signUpPassword}
                    autocomplete="new-password"
                    onChange={event=>handleChange(event,false)}
                    additionalClasses={[invalidPass.style]}
                />
                <AccountIcon 
                    key={signUpPassword}
                    className={`${styles.inputIcon} 
                        ${!signUpPassword ? undefined : 
            !AccountService.enforcePasswordRules(signUpPassword) ? styles.negative : styles.positive}`} 
                />
                <span key={Date.now()} className={styles.errMsg}>{invalidPass.msg}</span>
            </div>
            <div className={styles.inputContainer}>
                <Input 
                    inputId="confirmPass"
                    label="Confirm Password"
                    inputType="password" 
                    inputValue={confirmPassword}
                    autocomplete="new-password"
                    onChange={event=>handleChange(event,true)}
                    additionalClasses={[invalidConfirmPass.style]}
                />
                <AccountIcon 
                    key={confirmPassword}
                    className={`${styles.inputIcon} ${!signUpPassword ? undefined : 
                        signUpPassword !== confirmPassword || !AccountService.enforcePasswordRules(confirmPassword) 
                            ? styles.negative : styles.positive}`}
                />
                <span key={Date.now()} className={styles.errMsg}>{invalidConfirmPass.msg}</span>
            </div>
        </section>
    );
};

export default RegisterPassword;