import React from 'react';
import styles from './ActionButton.module.scss';

const ActionButton = ({children, onClick, btnType, additionalClasses=[]}) => {
    return (
        <div className={styles.btnContainer}>
            <button 
                className={`${styles.btn} ${additionalClasses.join(" ")}`} 
                onClick={onClick} 
                type={btnType}
            >
                {children}
            </button>
        </div>
    );
};

export default ActionButton;