export default class RegistrationErrors {
    static get FIRST_NAME_ERR()  { return "First name must be between 1-90 valid characters."; }
    static get LAST_NAME_ERR()   { return "Last name must be between 1-90 valid characters."; }
    static get EMAIL_ERR()       { return "Email is invalid."; }
    static get STATE_ERR()       { return "State of residence is invalid"; }
    // eslint-disable-next-line max-len
    static get NO_OREGON_STATE_ERR()       { return "Choices by WholeHealth Living is not available in your area. If you have questions or would like additional information, please call 1-800-852-6285."; }
    // eslint-disable-next-line max-len
    static get PASS_ERR()        { return "New password must be greater than 8 characters and contain at least one of each: uppercase letter, lowercase letter, number."; }
    static get CONFIRMPASS_ERR() { return "New password and confirm new password do not match."; }
    static get REGISTER_ERR()    { return "Registration failed. Please try again."; }
}
