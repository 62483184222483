import React from 'react';
import styles from './Banner.module.scss';

const Banner = () => {
    return (
        <section className={styles.banner}>
            <a href="mailto:whlchoices@tivityhealth.com">whlchoices@tivityhealth.com</a>
        </section>
    );
};

export default Banner;