import React from "react";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from "../../components/layout/Layout";
import BasePage from '../base/BasePage';
import { carouselText } from "../../models/carouselText";
import NominatePractitionerForm from "../../components/nominate-practitioner-form/NominatePractitionerForm";
import Loading from "../../components/loading/Loading";

const NominatePractitioner = () => {
    const handleClick = () => {
        document.getElementById('form').scrollIntoView({ 
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    };

    return (
        <Layout pageTitle={"Nominate a Practitioner"}>
            <BasePage  
                headline={carouselText[0].headline}
                body={carouselText[0].body}
                btnText="Nominate a Practitioner"
                form={<NominatePractitionerForm/>}
                onClick={handleClick}
                linkText="Return to Home"
                linkTarget=""
            />
        </Layout>
    );
};

export default withAuthenticationRequired(NominatePractitioner, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
