import React, { useContext, useEffect, useState } from 'react';
import styles from './ImageCarousel.module.scss';
import healthDiscountNetwork from '../../assets/images/health-discount-network.webp';
import fitnessWellnessCenters from '../../assets/images/fitness-wellness-centers.webp';
import practitioners from '../../assets/images/practitioners.webp';
import lifestyleProducts from '../../assets/images/lifestyle-products.webp';
import wellbeingServices from '../../assets/images/well-being-services.webp';
import CircleButton from './CircleButton';
import { ChoicesContext } from '../../context/StateProvider';
import { useChoicesContext } from '../../context/ChoicesContext';

const ImageCarousel = () => {
    const DISPLAY_IMG_DURATION_MS = 7500;
    const images = [healthDiscountNetwork, fitnessWellnessCenters, practitioners, lifestyleProducts, wellbeingServices];
    const { imgCarouselIndex, setImgCarouselIndex } = useChoicesContext();
    const [isRunning, setIsRunning] = useState(true);
    const [imgTimeout,setImgTimeout] = useState(null);
    
    useEffect(()=> {
        setImgTimeout(isRunning ? setTimeout(()=> {
            imgCarouselIndex === 4 ? setImgCarouselIndex(0) : setImgCarouselIndex(imgCarouselIndex+1);
        }, DISPLAY_IMG_DURATION_MS) : "paused");
    }, [imgCarouselIndex]);

    const handleClick = (imageIndex) => {
        setImgCarouselIndex(imageIndex);
        setIsRunning(false);
        clearTimeout(imgTimeout);
    };

    return (
        <section className={styles.imgContainer}>
            <img 
                loading="eager" 
                key={imgCarouselIndex} 
                className={styles.stockImg} 
                src={images[imgCarouselIndex]} 
                alt="WholeHealth Living supports a variety of healthy activities"
            />
            <div className={styles.menuContainer}>
                <CircleButton
                    handleClick={()=> handleClick(0)} 
                    positionClass={styles.position0} 
                    activeClass= {imgCarouselIndex === 0 ? '' : styles.hidden} 
                />
                <CircleButton 
                    handleClick={()=> handleClick(1)} 
                    positionClass={styles.position1} 
                    activeClass= {imgCarouselIndex === 1 ? '' : styles.hidden} 
                />
                <CircleButton 
                    handleClick={()=> handleClick(2)} 
                    positionClass={styles.position2} 
                    activeClass= {imgCarouselIndex === 2 ? '' : styles.hidden} 
                />
                <CircleButton 
                    handleClick={()=> handleClick(3)} 
                    positionClass={styles.position3} 
                    activeClass= {imgCarouselIndex === 3 ? '' : styles.hidden} 
                />
                <CircleButton 
                    handleClick={()=> handleClick(4)} 
                    positionClass={styles.position4} 
                    activeClass= {imgCarouselIndex === 4 ? '' : styles.hidden} 
                />
            </div>
        </section>
    );
};

export default ImageCarousel;