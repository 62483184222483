/* eslint-disable indent */
import React from "react";
import styles from './BasePage.module.scss';
import ImageCarousel from "../../components/image-carousel/ImageCarousel";
import InfoCard from "../../components/info-card/InfoCard";
import LandingImage from "../../components/landing-image/LandingImage";
import PractionerPreview from '../../components/PractionerPreview';
import { isReferringURL, referringBaseSwitch } from '../../services/referringSwitch';

const BasePage = ({
    headline,
    body,
    btnText,
    form,
    onClick,
    linkText,
    isAccessDenied,
    linkTarget,
    supportText
}) => {
    return (
        <section className={styles.baseContainer} style={!isAccessDenied ? null : { minHeight: "70vh" }}>
            <article className={styles.topContent} style={{ marginBottom: '2rem' }}>
                { isReferringURL ?
                 referringBaseSwitch(
                    headline,
                    body,
                    btnText,
                    form,
                    onClick,
                    linkText,
                    isAccessDenied,
                    linkTarget,
                    supportText) 
                 :<div className={styles.carousel}>  <ImageCarousel /> </div> }
                { 
                isReferringURL ?
                <></> 
                 :
                <div className={styles.infoCard}>
                    <InfoCard
                        headline={headline}
                        body={body}
                        btnText={btnText}
                        onClick={onClick}
                        linkText={linkText}
                        linkTarget={linkTarget}
                        isAccessDenied={isAccessDenied}
                        supportText={supportText}
                    />
                </div>
               } 

            </article>
            {
                !form ? <div className={styles.spacer}></div> :
                    <article id="form" className={styles.baseFormSection}>
                        <div className={styles.formImg}>
                            <LandingImage isAccessDenied={isAccessDenied} />
                        </div>
                        <div className={styles.form}>
                            {form}
                        </div>
                    </article>
            }
        </section >
    );
};

export default BasePage;