import React from 'react';
import styles from './Filter.module.scss';

const FilterSelect = ({id, label, options, onChange, isDisabled}) => {
    return (
        <div className={!isDisabled ? styles.filterElementContainer : styles.disabledFilterElementContainer }>
            <label htmlFor={id} className={styles.filterElementLabel}>{label}</label>
            <div className={styles.filterSelectElement}>
                <select 
                    onChange={onChange} 
                    id={id} 
                    className={styles.filterSelectText}
                    disabled={isDisabled}
                >
                    {options.length > 0 && options.map((option)=> {
                        return (
                            <option 
                                className={styles.filterSelectOption} 
                                key={option.displayName} 
                                value={JSON.stringify(option.value)}
                            >
                                {option.displayName}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export default FilterSelect;