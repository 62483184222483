import React from 'react';
import styles from './PractitionerMetadata.module.scss';

const PractitionerMetadata = ({practitioner}) => {
    return (
        <div className={styles.practitionerMeta}>
            <div className={styles.metaDetails}>
                <div className={styles.metaColumn}>
                    <strong>Business: </strong>
                    <p>{!practitioner.organizationName ? "N/A" : practitioner.organizationName}</p>
                    <strong>Years of Practice: </strong>
                    <p>{practitioner.yearsInPractice < 1 || practitioner.yearsInPractice > 50 ? 
                        "N/A" : practitioner.yearsInPractice}</p>
                    <strong>Specialties: </strong>
                    <ul className={styles.specialties}>
                        {(Array.isArray(practitioner.subCategoryNames) ? 
                            [...new Set(practitioner.subCategoryNames)] : practitioner.subCategoryNames.split(","))
                            .map((specialty, index) => {
                                return (
                                    <li key={index}>
                                        {specialty}
                                    </li>
                                );
                            }) 
                        }
                    </ul>
                </div>
                <div className={styles.metaColumn}>
                    <strong>Gender Identity: </strong>
                    <p>{!practitioner.sex ? "N/A" : practitioner.sex}</p>
                    <strong>Speaks: </strong>
                    <p>{!practitioner.languageDisplay ? "N/A" : practitioner.languageDisplay}</p>
                </div>
            </div>
        </div>
    );
};

export default PractitionerMetadata;