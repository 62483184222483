import React, { createRef, useEffect, useState } from 'react';
import FilterControls from '../filter/FilterControls';
import FilterSelect from '../filter/FilterSelect';
import FilterSummary from '../filter/FilterSummary';
import Pagination from '../pagination/Pagination';
import ProductCard from '../../components/products/ProductCard';
import { SortOptions } from '../../models/sortOptions';
import { useSearchOffersMutation }
    from '../../services/react-query/choices.service';
import OfferService from '../../services/offer.service';
import ZeroResults from '../zero-results/ZeroResults';
import styles from './DiscountSearch.module.scss';
import Loading from '../loading/Loading';
import { geocode, geolocationIsCached, usePosition } from '../../services/geolocation.service.js';
import ProductTypeId from '../../models/productTypeId';
import PractionerCard from '../../components/practitioners/PractitionerCard';
import FitnessCenterCard from '../../components/fitness-centers/FitnessCenterCard';
import ProductSearchDto from '../../dto/ProductSearchDto';
import StateResidenceModal from '../stateOfResidence/StateResidenceModal';

const DiscountSearch = ({ productTypeId, filterOptions, sortOptions, subCategories }) => {
    const {
        mutateAsync: searchOffers,
        isLoading,
        isSuccess,
        isError
    } = useSearchOffersMutation();

    const checkboxReferences = (subCategories || []).map(() => createRef());
    const {
        searchAddress,
        setSearchAddress,
        setSearchCoordinates,
        isSuccess: hasFoundPosition,
        isError: isGeoError,
    } = usePosition();
    const RESULT_LIMIT_PER_PAGE = 9;
    const categoryFilterStringInitialState =
        productTypeId === ProductTypeId.PROVIDER ? "All Practitioners" : "All Products";

    const [sortBy, setSortBy] = useState(SortOptions[0].value);
    const [products, setProducts] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchRadius, setSearchRadius] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const [restrictedState, setRestrictedState] = useState(false);
    const [categoryFilterString, setCategoryFilterString] = useState(categoryFilterStringInitialState);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [userStateVerified, setUserStateVerified] = useState(false);
    const [residenceState, setResidenceState] = useState(localStorage.getItem("choices-state"));

    const executeSearchOnFilterChange = (value = {}) => {
        OfferService.debounceUserInput(() =>
            (
                (productTypeId === ProductTypeId.VENDOR) ?
                    Promise.resolve() :
                    geocode(
                        value.searchAddress || searchAddress,
                        setRestrictedState,
                        setSearchCoordinates,
                    )
            )
                .then(async () => {
                    let checkedSubcategories = value.selectedSubCategories || selectedSubCategories;
                    let requestSubCategories = checkedSubcategories.length <= 0 ? subCategories : checkedSubcategories;

                    const search = await searchOffers(new ProductSearchDto(
                        productTypeId,
                        value.pageNumber || pageNumber,
                        RESULT_LIMIT_PER_PAGE,
                        productTypeId === ProductTypeId.FACILITY ?
                            [{ id: 8, name: 'Fitness Centers' }] : requestSubCategories,
                        value.searchRadius || searchRadius,
                        value.sortBy || sortBy
                    )).catch(error => error);

                    if (search.items) {
                        setProducts(search.items);
                        localStorage.setItem('choices-products', JSON.stringify(search.items));
                        setTotalRecords(search.totalCount);
                    }
                })
        , debounceTimeout, setDebounceTimeout);
    };

    const updateCategoryFilterString = (checkedFilters) => {
        switch ((checkedFilters || []).length) {
        case 0:
            setCategoryFilterString(categoryFilterStringInitialState);
            break;
        case 1:
            setCategoryFilterString(checkedFilters[0].name);
            break;
        default:
            // If multiple filters, format string like "Now showing results for X, Y, and Z other(s)"
            setCategoryFilterString(
                `${checkedFilters[0].name}, ${checkedFilters[1].name}
                         ${checkedFilters.length > 2 ?
        `and ${checkedFilters.length - 2} other${checkedFilters.length - 2 > 1 ? 's' : ''}` : ''}`
            );
            break;
        }
    };

    useEffect(() => {
        if (subCategories && subCategories.length > 0) executeSearchOnFilterChange();
    }, [subCategories]);

    useEffect(() => {
        if (residenceState !== null && residenceState !== "null" && residenceState !== undefined) {
            setUserStateVerified(true);
        }
    }, [residenceState]);


    const handleRadiusFilterChange = (event) => {
        const MILE_RADIUS_UPPER_BOUNDS = 1000;
        if (event.target.value > -1 && event.target.value <= MILE_RADIUS_UPPER_BOUNDS) {
            setSearchRadius(event.target.value);
            executeSearchOnFilterChange({ searchRadius: event.target.value });
        }
    };

    const handleSelectedSubCategoryChange = () => {
        let checkedSubcategories = subCategories.filter(
            (option, index) => checkboxReferences[index].current.checked);
        setSelectedSubCategories(checkedSubcategories);
        updateCategoryFilterString(checkedSubcategories);
        executeSearchOnFilterChange({ selectedSubCategories: checkedSubcategories });
    };

    const handleClearFilters = () => {
        setSelectedSubCategories(subCategories);

        if (!productTypeId === productTypeId.FACILITY)
            subCategories.map((option, index) => checkboxReferences[index].current.checked = false);
    };

    const handleSortFilterChange = (event) => {
        setSortBy(JSON.parse(event.target.value));
        executeSearchOnFilterChange({ sortBy: JSON.parse(event.target.value) });
    };

    const handleSearchAddressChange = (event) => {
        const newAddress = event.target.value;
        if (newAddress.length > 90) return;
        setSearchAddress(newAddress);
        executeSearchOnFilterChange({ searchAddress: newAddress });
    };

    const handlePageNumberClick = (number) => {
        setPageNumber(number);
        executeSearchOnFilterChange({ pageNumber: number });
    };

    return (
        <>
            {
                !userStateVerified &&
                <StateResidenceModal
                    userStateVerified={userStateVerified}
                    setUserStateVerified={setUserStateVerified}
                />
            }
            <section className={styles.container}>


                <div className={styles.resultsHeader}>

                    <div className={styles.summaryContainer}>
                        <FilterSummary
                            productTypeId={productTypeId}
                            searchAddress={searchAddress || localStorage.getItem("choices-search-address")}
                            categoryFilterString={categoryFilterString}
                        />
                    </div>
                    <div className={styles.sortContainer}>
                        <FilterSelect
                            id="sortBy"
                            label=""
                            options={sortOptions}
                            onChange={handleSortFilterChange}
                            isDisabled={isLoading || !subCategories || (!isSuccess && !isError)}
                        />
                    </div>
                </div>
                <div className={styles.resultsBody}>
                    <FilterControls
                        filterOptions={filterOptions}
                        subCategories={subCategories || []}
                        searchAddress={searchAddress || localStorage.getItem("choices-search-address")}
                        searchRadius={searchRadius}
                        isDisabled={isLoading || !subCategories || (!isSuccess && !isError)}
                        checkboxReferences={checkboxReferences}
                        handleRadiusFilterChange={handleRadiusFilterChange}
                        handleSearchAddressChange={handleSearchAddressChange}
                        handleClearFilters={handleClearFilters}
                        handleSelectedSubCategoryChange={handleSelectedSubCategoryChange}
                    />
                    <div className={styles.productResults} key={products}>
                        {products.length > 0 ?
                            products.map(productData => {
                                return (
                                    <div key={productData.id} className={styles.cardContainer}>
                                        {
                                            (productTypeId === ProductTypeId.VENDOR) ?
                                                <ProductCard product={productData} /> :
                                                (productTypeId === ProductTypeId.PROVIDER) ?
                                                    <PractionerCard practitioner={productData} /> :
                                                    <FitnessCenterCard fitnessCenter={productData} />
                                        }

                                    </div>
                                );
                            })
                            :
                            (!isSuccess && !isError) ? undefined :
                                <ZeroResults productTypeId={productTypeId} restrictedState={restrictedState} />
                        }
                    </div>
                </div>
                <div className={styles.paginationContainer}>
                    <Pagination
                        pageLimit={RESULT_LIMIT_PER_PAGE}
                        selectedPageNumber={pageNumber}
                        totalRecords={totalRecords}
                        handlePageNumberClick={handlePageNumberClick}
                    />
                </div>
                <Loading
                    isLoading={
                        (!isSuccess && !isError) || // Search has not completed and is not in an error state
                        (
                            (
                                // Geolocation has not completed and is not in an error state
                                (!hasFoundPosition && !isGeoError)
                                || !geolocationIsCached() // Search address and/or user has not been geolocated
                            )
                            // geolocation data is not a dependency of the vendor search
                            && !productTypeId === ProductTypeId.VENDOR
                        )
                    }
                    isFullScreen
                />
            </section>
        </>
    );
};

export default DiscountSearch;