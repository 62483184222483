import React, { useEffect, useRef, useState } from "react";

const ScrollAnimation = ({ classes, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    
    const scrollRef = useRef(null);

    const handleScroll = (observer) => {
        const element = scrollRef.current;
        if(element) observer.observe(element);
    
        return  () => {
            if(element) observer.unobserve(element);
        };
    };

    useEffect(()=> {
        let observer = new IntersectionObserver((entries) => {
            const [ entry ] = entries;
            setIsVisible(entry.isIntersecting);
        }, { threshold:0 });

        handleScroll(observer);

        return () => { 
            observer = null;
            setIsVisible(true);
        } ;
    },[]);

    return (
        <section 
            className={`${classes.base} ${isVisible ? classes.animation : classes.hidden}`} 
            ref={scrollRef}
        >
            {children}
        </section>
    );
};

export default ScrollAnimation;