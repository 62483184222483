import React, { useMemo, useState } from 'react';
import styles from './Navbar.module.scss';
import Banner from '../../components/banner/Banner';
import AuthorizedNav from './AuthorizedNav';
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';

const Navbar = ({ pageTitle = 'Choices® by WholeHealth Living', layoutContainerRef }) => {
    const [navClass, setNavClass] = useState(styles.navHidden);
    const [toggleClass, setToggleClass] = useState(styles.toggleNavBtn);
    const [isToggled, setIsToggled] = useState(false);
    const setPageTitle = () => document.title = pageTitle + ' | Choices® by WholeHealth Living';
    const onClickOutsideNav = () => setIsToggled(false);
    const handleLogoClick = () => {
        // Link still responds if user attempts to navigate to same page.
        if (window && "/" === window.location.pathname) window.location.reload();
    };
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const applyMobileNavStyles = () => {
        if (!isToggled) {
            setNavClass(styles.navHidden);
            setToggleClass(styles.toggleNavBtn);
        } else {
            setNavClass(styles.navItems);
            setToggleClass(styles.toggledNavBtn);
        }
    };

    useMemo(() => {
        setPageTitle();
        applyMobileNavStyles();

        if(isToggled) {
            window.document.body.classList.add(styles.noScroll);
        } else {
            window.document.body.classList.remove(styles.noScroll);
        }

        if (layoutContainerRef.current && isToggled)
            layoutContainerRef.current.addEventListener('click', onClickOutsideNav);

        return () => {
            if (layoutContainerRef.current)
                layoutContainerRef.current.removeEventListener('click', onClickOutsideNav);
        };
    }, [pageTitle, layoutContainerRef, isToggled]);

    return (
        <header className={styles.header}>
            <Banner />
            <nav data-testid="navbar" className={styles.nav}>
                <section className={styles.brand}>
                    <Link to="/" onClick={handleLogoClick}>
                        <img
                            loading="lazy"
                            src="/images/color-logo.jpg"
                            alt="WholeHealth Living logo" />
                    </Link>
                </section>
                <ul className={navClass}>
                    <AuthorizedNav onClick={handleToggle} />
                    {isToggled && <Logo />}
                </ul>

                <section className={styles.mobileToggle}>
                    <button type="button" className={toggleClass} onClick={handleToggle} data-testid="mobile-toggle">
                    </button>
                </section>

            </nav>
        </header>
    );
};

export default Navbar;
