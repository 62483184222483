import React from 'react';
import Layout from '../../components/layout/Layout';
import ErrorPage from '../error/ErrorPage';

const NotFound = () => {
    return (
        <Layout pageTitle={"Not Found"}>
            <ErrorPage 
                imagePath="/images/not-found.svg" 
                title="Page Not Found" />
        </Layout>
    );
};

export default NotFound;