export const initialState = {
    imgCarouselIndex: 0,
    firstName: '',
    lastName: '',
    email: '',
    state: '', 
    userName: '',
    sessionId: '',
    accessToken: null,
    refreshToken: null,
    accessExpires: null,
    clientId: 0,
    domainId: 0,
    referringClientId: null,
    referringDomainId: null,
    referringDomainUrl: null,
    latitude: 0,
    longitude: 0,
    searchAddress: '',
    userId:''
};



export const reducer = (state, action) => {
    switch (action.type) {
    case 'SET_IMG_CAROUSEL_INDEX':
        return { ...state, imgCarouselIndex: action.payload };
    case 'SET_FIRST_NAME':
        return { ...state, firstName: action.payload };
    case 'SET_LAST_NAME':
        return { ...state, lastName: action.payload };
    case 'SET_EMAIL':
        return { ...state, email: action.payload };
    case 'SET_STATE':
        return { ...state, state: action.payload }; 
    case 'SET_USERNAME':
        return { ...state, username: action.payload };
    case 'SET_SESSION_ID':
        return { ...state, sessionId: action.payload };
    case 'SET_ACCESS_TOKEN':
        return { ...state, accessToken: action.payload };
    case 'SET_REFRESH_TOKEN':
        return { ...state, refreshToken: action.payload };
    case 'SET_ACCESS_EXPIRES':
        return { ...state, accessExpires: action.payload };
    case 'SET_CLIENT_ID':
        return { ...state, clientId: action.payload };
    case 'SET_DOMAIN_ID':
        return { ...state, domainId: action.payload };
    case 'SET_REFERRING_CLIENT_ID':
        return { ...state, referringClientId: action.payload };
    case 'SET_REFERRING_DOMAIN_ID':
        return { ...state, referringDomainId: action.payload };
    case 'SET_REFERRING_DOMAIN_URL':
        return { ...state, referringDomainUrl: action.payload };
    case 'SET_LATITUDE':
        return { ...state, latitude: action.payload };
    case 'SET_LONGITUDE':
        return { ...state, longitude: action.payload };
    case 'SET_SEARCH_ADDRESS':
        return { ...state, searchAddress: action.payload };
    case 'SET_USER_ID':
        return { ...state, userId: action.payload };
    default:
        throw new Error('Invalid action type.');
    }
};
