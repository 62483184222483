import React from 'react';
import ForgotPasswordForm from '../../components/forgot-password-form/ForgotPasswordForm';
import Layout from "../../components/layout/Layout";
import Logo from '../../components/logo/Logo';

const ForgotPassword = () => {
    // Use CSS-In-JS here as style requirements are minimal
    const pageContainer = {
        display: "flex", 
        height: "100%", 
        width: "100%",
        minHeight: "100vh", 
        justifyContent: "center"
    };

    return (
        <Layout pageTitle={"Forgot Password"}>
            <div style={pageContainer}>
                <ForgotPasswordForm />
            </div>
            <Logo />
        </Layout>
    );
};

export default ForgotPassword;