import { Link } from 'react-router-dom';
import styles from './StyledLink.module.scss';

const linkColor = [styles.styledLinkNavy, styles.styledLinkWhite];
const linkSize = [styles.styledLinkSmall, styles.styledLinkLarge];


const StyledLink = ({ styledLinkText, styledLinkColor, styledLinkSize, linkTo }) => {
    const getColor = styledLinkColor.includes("navy") ? linkColor[0] : linkColor[1];
    const getSize = styledLinkSize.includes("small") ? linkSize[0] : linkSize[1];

    return (
        <div className={styles.styledLink}>
            <Link to={linkTo} className={` 
                        ${styles.linkStyle}   
                        ${getColor} 
                        ${getSize}
                    `}>
                {styledLinkText}
            </Link>
        </div>

    );
};

export default StyledLink;
