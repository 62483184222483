/* eslint-disable indent */
import PractionerPreview from "../components/PractionerPreview";
import ImageCarousel from "../components/image-carousel/ImageCarousel";
import InfoCard from "../components/info-card/InfoCard";
import styles from '../pages/base/BasePage.module.scss';
import EnvironmentService from "../services/environment.service";

export const isReferringURL =  window.sessionStorage['choices-referring-domainUrl'] ;

export const referringBaseSwitch = (
  headline,
  body,
  btnText,
  form,
  onClick,
  linkText,
  isAccessDenied,
  linkTarget,
  supportText
  ) =>{
  let component;
  const referringURL = window.sessionStorage['choices-referring-domainUrl'];
  const referingUrlBSBC = EnvironmentService
      .getConfigurationVariable("ECOMMERCE_CLIENT_URLS")
      .indexOf(window.sessionStorage['choices-referring-domainUrl']
      ?.toLowerCase()) > -1 ? true : false;

  if(EnvironmentService.getConfigurationVariable("ECOMMERCE_CLIENT_URLS").indexOf(referringURL?.toLowerCase()) > -1)
  {
    component = <PractionerPreview/>;
  }
  else
  {
      component = <><div className={styles.carousel}>  <ImageCarousel /> </div> <div className={styles.infoCard}>
        <InfoCard
            headline={headline}
            body={body}
            btnText={btnText}
            onClick={onClick}
            linkText={linkText}
            linkTarget={linkTarget}
            isAccessDenied={isAccessDenied}
            supportText={supportText}
        />
    </div> </>;
  }

  // switch(referringURL)  {
  //   // BCBS Referring URL
  //   case 'https://bcbsa.southcarolinamp.tivityhealth.com':
  //     component = <PractionerPreview/>;
  //   break;
  //   default:
  //     component = <><div className={styles.carousel}>  <ImageCarousel /> </div> <div className={styles.infoCard}>
  //     <InfoCard
  //         headline={headline}
  //         body={body}
  //         btnText={btnText}
  //         onClick={onClick}
  //         linkText={linkText}
  //         linkTarget={linkTarget}
  //         isAccessDenied={isAccessDenied}
  //         supportText={supportText}
  //     />
  // </div> </>;
  // }
  return component;
};
