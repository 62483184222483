import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from "../../components/layout/Layout";
import ResetPasswordForm from '../../components/reset-password-form/ResetPasswordForm';
import Logo from '../../components/logo/Logo';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
   
    // Use CSS-In-JS here as style requirements are minimal
    const pageContainer = {
        display: "flex", 
        height: "100%", 
        width: "100%",
        minHeight: "100vh", 
        justifyContent: "center"
    };

    return (
        <Layout pageTitle={"Reset Password"}>
            <div style={pageContainer}>
                <ResetPasswordForm userId={currentParams.userId} token={currentParams.token}/>
            </div>
            <Logo />
        </Layout>
    );
};

export default ResetPassword;