import React from "react";
import styles from './OfferButton.module.scss';

const OfferButton = ({icon, text, onClick}) => {
    return (
        <button className={styles.offerBtn} onClick={onClick}>
            <img loading="lazy" height="25" width= "25" src={icon} alt={`${text} icon`}/>
            <span>{text}</span>
        </button>
    );
};

export default OfferButton;