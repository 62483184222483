
export class SortOrder {
    static get Ascending() { return 1; }
    static get Descending() { return 2; }
}

export class SortType {
    static get Distance() { return 0; }
    static get Discount() { return 1; }
    static get Name() { return 2; }
}

export const SortOptions = Object.freeze([
    { value: { sortType: SortType.Name,     sortOrder: SortOrder.Ascending  }, displayName: "Sort By Name (A-Z)"},
    { value: { sortType: SortType.Name,     sortOrder: SortOrder.Descending }, displayName: "Sort By Name (Z-A)"},
    { value: { sortType: SortType.Distance, sortOrder: SortOrder.Ascending  }, displayName: "Sort By Distance (Near)"},
    { value: { sortType: SortType.Distance, sortOrder: SortOrder.Descending }, displayName: "Sort By Distance (Far)"},
    { value: { sortType: SortType.Discount, sortOrder: SortOrder.Ascending  }, displayName: "Sort By Discount (Least)"},
    { value: { sortType: SortType.Discount, sortOrder: SortOrder.Descending }, displayName: "Sort By Discount (Most)"},
]);