import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import styles from './ResourceCenterPage.module.scss';
import { ReactComponent as FavoriteIcon } from "../../assets/icons/icon-favorite.svg";
import { ReactComponent as PodcastIcon } from "../../assets/icons/icon-podcast.svg";
import { ReactComponent as ArticleIcon } from "../../assets/icons/icon-article.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/icon-play.svg";
import { ReactComponent as TrendingIcon } from "../../assets/icons/icon-trending.svg";
import ArticleCard from '../../components/article-card/ArticleCard';
import ResourceCenterBottomHalf from './ResourceCenterBottomHalf';
import StyledLink from '../../components/styled-link/StyledLink';
import SearchBar from '../../components/search-bar/SearchBar';
import IconLink from '../../components/icon-link/IconLink';
import featuredData from './tempData/featured-data';
import landingArticleData from './tempData/landingArticle-data';
import popularData from './tempData/popular-data';
import StateResidenceModal from "../../components/stateOfResidence/StateResidenceModal";
import Loading from "../../components/loading/Loading";

const ResourceCenterPage = () => {
    const { searchInput } = useParams();

    const [userStateVerified, setUserStateVerified] = useState(false);
    const [residenceState, setResidenceState] = useState(localStorage.getItem("choices-state"));

    useEffect(() => {
        if (residenceState !== null && residenceState !== "null" && residenceState !== undefined) {
            setUserStateVerified(true);
        }
    }, [residenceState]);



    const featuredCards = featuredData.map((data, id) => {
        return (
            <div className={styles.resourceCenterCardContainer} key={id}>
                <ArticleCard
                    key={id}
                    cardBGimg={data.cardBGimg}
                    cardTitle={data.cardTitle}
                    cardReadTime={data.cardReadTime}
                    cardButton={
                        <StyledLink
                            styledLinkText={data.cardButton.cardButtonText}
                            styledLinkColor={data.cardButton.cardButtonColor}
                            styledLinkSize={data.cardButton.cardButtonSize}
                            styledLinkLinkTo={data.cardButton.cardButtonLinkTo}
                        />
                    }

                />
            </div>
        );
    });


    const popularCards = popularData.map((data, id) => {
        return (
            <div className={styles.resourceCenterCardContainer} key={id}>
                <ArticleCard
                    key={id}
                    cardBGimg={data.cardBGimg}
                    cardTitle={data.cardTitle}
                    cardReadTime={data.cardReadTime}
                    cardButton={
                        <StyledLink
                            styledLinkText={data.cardButton.cardButtonText}
                            styledLinkColor={data.cardButton.cardButtonColor}
                            styledLinkSize={data.cardButton.cardButtonSize}
                            styledLinkLinkTo={data.cardButton.cardButtonLinkTo}
                        />
                    }
                />
            </div>
        );
    });

    return (
        <>
            {
                !userStateVerified &&
                <StateResidenceModal
                    userStateVerified={userStateVerified}
                    setUserStateVerified={setUserStateVerified}
                />
            }
            <Layout pageTitle={'Resource Center'}>
                <div className={styles.pageContainerTwo}>

                    {/* top half starts here */}
                    <div className={styles.landingTop}>
                        <h2 className={styles.landingTopTitle}>Resource Center</h2>
                        <div className={styles.searchFormContainer}>

                            <SearchBar
                                linkTo={`search?query=${searchInput}`}
                            />

                            <div className={styles.iconLinksContainer}>
                                <IconLink
                                    srcIcon={<FavoriteIcon />}
                                    linkTo="search?category=favorites"
                                    linkText="Favorites"
                                />
                                <IconLink
                                    srcIcon={<PodcastIcon />}
                                    linkTo="search?category=podcasts"
                                    linkText="Podcasts"
                                />
                                <IconLink
                                    srcIcon={<PlayIcon />}
                                    linkTo="search?category=videos"
                                    linkText="Videos"
                                />
                                <IconLink
                                    srcIcon={<ArticleIcon />}
                                    linkTo="search?category=articles"
                                    linkText="Articles"
                                />
                                <IconLink
                                    srcIcon={<TrendingIcon />}
                                    linkTo="search?category=popular"
                                    linkText="Popular"
                                />
                            </div>
                        </div>
                    </div>
                    {/* top half ends here */}

                    <ResourceCenterBottomHalf
                        headerText={landingArticleData[0].headerText}
                        buttonText={landingArticleData[0].buttonText}
                        buttonColor={landingArticleData[0].buttonColor}
                        buttonSize={landingArticleData[0].buttonSize}
                        buttonLinkTo={landingArticleData[0].buttonLinkTo}

                        articleHeader={landingArticleData[0].articleHeader}
                        articleReadTime={landingArticleData[0].articleReadTime}
                        articleBGimg={landingArticleData[0].articleBGimg}

                        articleButtonLinkTo={landingArticleData[0].articleButtonLinkTo}
                        articleButtonText={landingArticleData[0].articleButtonText}
                        articleButtonColor={landingArticleData[0].articleButtonColor}
                        articleButtonSize={landingArticleData[0].articleButtonSize}
                    />

                    <div className={styles.articleSections}>
                        <h2 className={styles.articleSectionTitle}>Featured</h2>
                        <div className={styles.articleSectionsCardContainer}>
                            {featuredCards}
                        </div>

                    </div>
                    <div className={styles.articleSections}>
                        <h2 className={styles.articleSectionTitle}>Popular</h2>
                        <div className={styles.articleSectionsCardContainer}>
                            {popularCards}
                        </div>
                    </div>

                </div>
            </Layout>
        </>

    );
};

export default withAuthenticationRequired(ResourceCenterPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
