import { createContext, useContext } from "react";
import { initialState } from "./reducer";

export const ChoicesContext = createContext(initialState);

export const useChoicesContext = () => {
    const context = useContext(ChoicesContext);

    if (context === undefined) {
        throw new Error("useChoicesContext must be used within ChoicesContext");
    }

    return context;
};
