import React from "react";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styles from './SearchPage.module.scss';
import DiscountSearch from "../../components/discount-search/DiscountSearch";
import { useGetSubCategoriesForProductTypeIdQuery } from "../../services/react-query/choices.service";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";

const SearchPage = ({productTypeId,filterOptions,sortOptions,pageTitle}) => {
    const { 
        data, 
        isSuccess,
        isLoading,
        isError
    } = useGetSubCategoriesForProductTypeIdQuery(productTypeId);

    return (
        <Layout pageTitle={pageTitle}>
            <section className={styles.pageContainer}>
                {!isSuccess || isLoading || isError ? undefined :
                    <DiscountSearch 
                        productTypeId={productTypeId} 
                        filterOptions={filterOptions} 
                        sortOptions={sortOptions}
                        subCategories={data}
                    />}
            </section>
        </Layout>
    );
};

export default withAuthenticationRequired(SearchPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
