//these key value pairs are temporary and used as a placeholder data source in ResourceCenterDetailsPage.  

export default [
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "Generate Income From Selling Frozen Bananas",
        cardReadTime: "5 minute read",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "firstOrLast"
        }
    },
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "#TeamJacob",
        cardReadTime: "120 minute video",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "aTwilightStory"
        }
    },
    {
        cardBGimg: "images/stretching_full.jpg",
        cardTitle: "The Perfect Kamehameha",
        cardReadTime: "22 minute read",
        cardButton: {
            cardButtonText: "View Now",
            cardButtonColor: "white",
            cardButtonSize: "large",
            cardButtonLinkTo: "thirdLinkHere"
        }
    },
    // {
    //     cardBGimg: "",
    //     cardTitle: "",
    //     cardReadTime: "",
    //     cardButton: {
    //         cardButtonText: "",
    //         cardButtonColor: "",
    //         cardButtonSize: "",
    //         cardButtonLinkTo: ""
    //     }
    // },
];
