import { useChoicesContext } from "../../context/ChoicesContext";
import EnvironmentService from "../environment.service";
import { useMutation, useQuery } from 'react-query';
import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';

// UPDATED FOR AUTH0
const useGetIdentityApiHeaders = (contentType) => {
    const { getAccessTokenSilently } = useAuth0();
    return async () => {
        const accessToken = await getAccessTokenSilently();
        return {
            headers: {
                "Content-Type": contentType,
                "Authorization": `Bearer ${accessToken}`
            }
        };
    };
};

// UPDATED FOR AUTH0
export const useGetAuthTokenMutation = () => {
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("OAUTH_DOMAIN");

    return useMutation(async (credentials) => {
        const { data } = await axios.post(
            baseApiUrl + '/oauth/token',
            credentials,
            { headers: useGetIdentityApiHeaders("application/x-www-form-urlencoded; charset=utf-8") }
        );
        return data;
    });
};

//REPLACE OR REMOVE
export const useVerifyCurrentPasswordMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (verifyCurrentPasswordDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/user/password',
            verifyCurrentPasswordDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

// UPDATED FOR AUTH0
export const useAuthenticateUserMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("OAUTH_DOMAIN");
    return useMutation(async (authenticateUser) => {
        const { data } = await axios.post(
            baseApiUrl + '/oauth/authenticate',
            authenticateUser,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

//REPLACE OR REMOVE
export const useUpdateUserMutation = () => {
    const getHeaders = useGetIdentityApiHeaders();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    const userId = localStorage.getItem('choices-userId');
    return useMutation(async (updateUser) => {
        const headers = await getHeaders();
        const response = await axios.patch(
            baseApiUrl + `/api/v2/users/${userId}`,
            updateUser,
            headers
        );
        return response.data;
    });
};

//REPLACE OR REMOVE
export const useConfirmEmailMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (confirmationDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/confirm-email',
            confirmationDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
    
};

//REPLACE OR REMOVE
export const useResendEmailConfirmationMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (resendEmailDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/resend-email-confirmation',
            resendEmailDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

//REPLACE OR REMOVE
export const useGetCommunicationPreferencesQuery = (username) => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useQuery('getCommunicationPreferences', async () => {
        const { data } = await axios.get(baseApiUrl + `/api/accounts/user/${username}/communication`,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

//REPLACE OR REMOVE
export const useSendPasswordResetEmailMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (forgotPasswordDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/send-password-reset',
            forgotPasswordDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

//REPLACE OR REMOVE
export const useVerifyPasswordResetTokenMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (verifyPasswordTokenDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/verify-password-token',
            verifyPasswordTokenDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};

//REPLACE OR REMOVE
export const useResetPasswordMutation = () => {
    const { accessToken } = useChoicesContext();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("IDENTITY_API");
    return useMutation(async (resetPasswordDto) => {
        const { data } = await axios.post(
            baseApiUrl + '/api/accounts/reset-password',
            resetPasswordDto,
            { headers: useGetIdentityApiHeaders(accessToken, "application/json") });
        return data;
    });
};
