import React, { useState } from 'react';
import { Input } from '../../components/form/Form';
import styles from './RegisterForm.module.scss';
import validator from 'validator';
import RegistrationErrors from '../../models/registrationErrors';

const RegisterEmail = ({
    signUpEmail,
    setSignUpEmail,
}) => {
    const initialState = {msg:undefined,style:undefined};
    const [invalidEmail, setInvalidEmail] = useState(initialState);

    const handleChange = (event) => {
        const input = event.target.value;
        if(input.length > 90) return;

        setSignUpEmail(input);
        setInvalidEmail(undefined);
        
        if(!validator.isEmail(input))
            return setInvalidEmail({msg:RegistrationErrors.EMAIL_ERR,style:styles.inputError});
            
        setInvalidEmail(initialState);
    };

    return (
        <section className={`${styles.formItem} ${styles.emailItem}`}>
            <Input 
                inputId="email"
                label="Email Address"
                inputType="email" 
                inputValue={signUpEmail}
                onChange={handleChange}
                autocomplete={"username"}
                additionalClasses={[invalidEmail.style]}
            />
            <span key={Date.now()} className={styles.errMsg}>{invalidEmail.msg}</span>
        </section>
    );
};

export default RegisterEmail;