import React, { useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../footer/Footer.js";
import styles from './Layout.module.scss';

const Layout = ({children, pageTitle, isLoading}) => {
    const mainRef = useRef();

    return (
        <div className={styles.container}>
            {!isLoading && <Navbar pageTitle={pageTitle} layoutContainerRef={mainRef} />}
            <main ref={mainRef} className={styles.main}>
                {children}
            </main>
            {!isLoading && <Footer />}
        </div>
    );
};

export default Layout;
