import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import EnvironmentService from '../../services/environment.service';

const ClientReferringUrl = () => {
    const navigate = useNavigate();
    const { clientUrl } = useParams();

    useEffect(() => {
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
        const redirectUrl = window.origin;

        const apiUrl = `${baseApiUrl}/api/client/url?referringUrl=${clientUrl}`;

        const fetchData = async () => {
            const { data: referringUrl } =
                await axios.get(apiUrl).catch(err => {
                    if (err.response.status === 404) {
                        navigate("/access-denied");
                    }
                });

            const fullUrl = `${redirectUrl}${referringUrl}`;
            window.location.href = fullUrl;

        };

        fetchData();

    }, []);

    return null;
};

export default ClientReferringUrl;