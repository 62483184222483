import UpdateUserDto from '../dto/UpdateUserDto';
import EnvironmentService from "./environment.service";

export default class OAuthService {
    static async getAuthorizationCode(loginHint) {
        const identityApiBaseUri = EnvironmentService.getEnvironmentVariable("OAUTH_DOMAIN");
        const redirectUri = EnvironmentService.getEnvironmentVariable("REDIRECT_URI");

        const identityApiAuthorizeEndpoint = new URL(identityApiBaseUri + "/oauth/authorize");
        
        identityApiAuthorizeEndpoint.searchParams.append('response_type', "code");
        identityApiAuthorizeEndpoint.searchParams.append('client_id', 
            EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID"));
        identityApiAuthorizeEndpoint.searchParams.append('redirect_uri', redirectUri);
        identityApiAuthorizeEndpoint.searchParams.append('login_hint', loginHint);

        return await fetch(identityApiAuthorizeEndpoint.toString(),{ method: "POST" });
    }

    static createAccessTokenRequest(authCode) {
        return new URLSearchParams({
            'grant_type': "authorization_code",
            'client_id': EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID"),
            'code': authCode,
        });
    }

    static createRefreshTokenRequest(refreshToken) {
        return new URLSearchParams({
            'grant_type': "refresh_token",
            'client_id': EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID"),
            'refresh_token': refreshToken,
        });
    }

    static createTokenRevocationRequest(refreshToken) {
        return new URLSearchParams({
            'token_type_hint': "refresh_token",
            'client_id': EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID"),
            'token': refreshToken,
        });
    }

    static isAuthorized(accessToken, accessExpires) {
        return accessToken !== null 
        && accessToken !== undefined 
        && accessToken.length > 0
        && !this.authorizationHasExpired(accessExpires);
    }

    static setAuthorizationExpiresSeconds(secondsUntilExpiration) {
        let expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + secondsUntilExpiration);
        return expirationDate.toString();
    }

    static authorizationHasExpired(expirationDateString) {
        const expirationDate = new Date(expirationDateString);
        if(isNaN(expirationDate.getTime())) return true;
        return expirationDate <= new Date();
    }

    static verifyReferralSource() {
        const { referringClientId, referringDomainId, referringDomainUrl } = this.getReferralData();
        // Checks if both the id values have been set and that they are integers, checks for valid domainUrl
        return Number.isSafeInteger(referringClientId) && Number.isSafeInteger(referringDomainId) 
                && referringDomainUrl && referringDomainUrl.toString().includes('http');
    }

    static healthPlanHasChanged(userData) {
        if(this.verifyReferralSource()) {
            const {clientId, domainId} = userData;
            const { referringClientId, referringDomainId, referringDomainUrl } = this.getReferralData();

            // Health plan will not be updated if referring domain was silver sneakers
            return (clientId !== referringClientId || domainId !== referringDomainId) 
                    && !referringDomainUrl.toString().includes('silversneakers');
        }
        return false;
    }

    static getReferralData() {
        return {
            referringClientId: parseInt(sessionStorage.getItem("choices-referring-clientId")),
            referringDomainId: parseInt(sessionStorage.getItem("choices-referring-domainId")),
            referringDomainUrl: sessionStorage.getItem("choices-referring-domainUrl")
        };
    }

    static async updateHealthPlanInfo(userData, updateUserFunction) {
        // Check if the user arrived from a different health plan and update their data
        if(this.healthPlanHasChanged(userData)) {
            const { referringClientId, referringDomainId } = this.getReferralData();

            // Post change of client & domain Id with react-query function passed as parameter
            const healthPlanChange = await updateUserFunction(
                new UpdateUserDto(
                    userData.email,
                    userData.firstName,
                    userData.lastName,
                    referringDomainId, 
                    referringClientId));

            // Update the user object with the new health plan Ids
            if(healthPlanChange.status === 200) {
                userData = {
                    ...userData,
                    clientId: referringClientId,
                    domainId: referringDomainId
                };
                sessionStorage.clear();
            }
        }
        return userData;
    }
}

