import React, { useState, useEffect, useRef } from 'react';
import styles from './StateOfResidenceInput.module.scss';

const StateOfResidenceInput = ({
    stateName,
    setStateName,
    stateAbbreviation,
    setStateAbbreviation,
    placeHolder
}) => {
    const states = {
        'Alabama': 'AL',
        'Alaska': 'AK',
        'American Samoa': 'AS',
        'Arizona': 'AZ',
        'Arkansas': 'AR',
        'California': 'CA',
        'Colorado': 'CO',
        'Connecticut': 'CT',
        'Delaware': 'DE',
        'District of Columbia': 'DC',
        'Federated States of Micronesia': 'FM',
        'Florida': 'FL',
        'Georgia': 'GA',
        'Guam': 'GU',
        'Hawaii': 'HI',
        'Idaho': 'ID',
        'Illinois': 'IL',
        'Indiana': 'IN',
        'Iowa': 'IA',
        'Kansas': 'KS',
        'Kentucky': 'KY',
        'Louisiana': 'LA',
        'Maine': 'ME',
        'Marshall Islands': 'MH',
        'Maryland': 'MD',
        'Massachusetts': 'MA',
        'Michigan': 'MI',
        'Minnesota': 'MN',
        'Mississippi': 'MS',
        'Missouri': 'MO',
        'Montana': 'MT',
        'Nebraska': 'NE',
        'Nevada': 'NV',
        'New Hampshire': 'NH',
        'New Jersey': 'NJ',
        'New Mexico': 'NM',
        'New York': 'NY',
        'North Carolina': 'NC',
        'North Dakota': 'ND',
        'Northern Mariana Islands': 'MP',
        'Ohio': 'OH',
        'Oklahoma': 'OK',
        'Oregon': 'OR',
        'Palau': 'PW',
        'Pennsylvania': 'PA',
        'Puerto Rico': 'PR',
        'Rhode Island': 'RI',
        'South Carolina': 'SC',
        'South Dakota': 'SD',
        'Tennessee': 'TN',
        'Texas': 'TX',
        'Utah': 'UT',
        'Vermont': 'VT',
        'Virgin Islands': 'VI',
        'Virginia': 'VA',
        'Washington': 'WA',
        'West Virginia': 'WV',
        'Wisconsin': 'WI',
        'Wyoming': 'WY',
    };

    const [filteredStates, setFilteredStates] = useState([]);
    const [selectState, setSelectState] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [focusGiven, setFocusGiven] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const inputRef = useRef();

    const convertState = (state, conversionDirection) => {
        if (conversionDirection === 'nameToAbbreviation') {
            return states[state];
        } else if (conversionDirection === 'abbreviationToName') {
            for (const key in states) {
                if (states[key] === state) {
                    return key;
                }
            }
        }
    };

    useEffect(() => {
        if (stateAbbreviation && !stateName) {
            const convertedStateName = convertState(stateAbbreviation, 'abbreviationToName');
            setStateName(convertedStateName);
        }

        if (stateName || (stateName && !stateAbbreviation)) {
            const convertedStateAbbreviation = convertState(stateName, 'nameToAbbreviation');
            setStateAbbreviation(convertedStateAbbreviation);
        }

    }, [stateName, stateAbbreviation]);

    const handleFocus = () => {
        setShowMenu(true);
        setFocusGiven(true);
        filterStatesArray();
    };

    useEffect(() => {
        if (focusGiven) {

            if (filteredStates.length === 1) {
                setShowValidationMessage(false);
            } else {
                setShowValidationMessage(true);
            }
        }

    }, [filteredStates, selectState, inputValue]);

    const handleBlur = () => {
        setTimeout(() => {
            setShowMenu(false);
        }, 200);

        if (
            filteredStates.length === 1 ||
            inputValue.toLowerCase() === selectState &&
            inputValue.length > 1
        ) {
            inputRef.current.value = filteredStates[0];
            setInputValue(filteredStates[0]);
            setSelectState(filteredStates[0]);

            setStateName(filteredStates[0]);

            setShowValidationMessage(false);
        } else {
            setShowValidationMessage(true);
        }
        if (filteredStates.includes(selectState)) {
            setShowValidationMessage(false);
        }
    };

    const handleInput = () => {
        filterStatesArray();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            const direction = event.key === 'ArrowUp' ? -1 : 1;
            const currentIndex = filteredStates.findIndex((state) => state === inputRef.current.value);
            const newIndex = currentIndex + direction;

            if (newIndex >= 0 && newIndex < filteredStates.length) {
                inputRef.current.value = filteredStates[newIndex];
                setSelectState(inputRef.current.value);
                setInputValue(inputRef.current.value);
                setStateName(inputRef.current.value);
            }
        }
    };

    const filterStatesArray = () => {
        const searchString = inputRef.current.value.toLowerCase().trim();

        const filteredStates = Object.keys(states).filter(
            (state) => state.toLowerCase().indexOf(searchString) !== -1
        );
        setInputValue(searchString);
        setFilteredStates(filteredStates);
    };

    const handleItemClick = (event) => {
        const clickedState = event.target.innerText.trim();
        inputRef.current.value = clickedState;
        setFilteredStates([clickedState]);
        setSelectState(clickedState);

        setStateName(clickedState);

        setInputValue(clickedState);
        setShowMenu(false);
    };

    const handleReset = () => {
        inputRef.current.value = "";
        setInputValue("");
        setFilteredStates([]);
        setSelectState("");

        setStateName("");
        setStateAbbreviation("");

        setShowMenu(false);
        setFocusGiven(false);
        setShowValidationMessage(false);
    };

    return (
        <section className={styles.stateOfResidenceContainer}>
            <label htmlFor="stateOfResidenceInput" className={styles.stateOfResidenceLabel} >
                State Of Residence
            </label>

            <input
                id="stateOfResidenceInput"
                data-testid="stateResidenceId"
                className={styles.stateOfResidenceInput}
                type="text"
                ref={inputRef}
                defaultValue={stateName ? stateName : ""}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                placeholder={placeHolder}
                style={{

                    border: showValidationMessage
                        ? "1px solid #D33E43"
                        : showMenu
                            ? "1px solid #0076BE"
                            : "1px solid #999",

                    boxShadow: !showMenu
                        ? "none"
                        : !showValidationMessage
                            ? "0 0 .2rem .2rem rgba(0,118,190, 0.25)"
                            : "none",
                }}
            />
            {
                showMenu &&
                filteredStates.length > 0 &&
                (
                    <ul
                        className={styles.stateOfResidenceUL}
                        style={{
                            maxHeight: filteredStates.length !== 1 ? "150px" : "50px",
                            overflowY: "scroll",
                        }}
                        data-testid="testIdUl"
                    >
                        {filteredStates.map((state, index) => (
                            <li
                                className={styles.stateOfResidenceLI}
                                key={state}
                                onClick={handleItemClick}
                                data-testid={`statesListed-${index}`}
                            >
                                {state}
                            </li>
                        ))}
                    </ul>
                )
            }
            {
                showMenu &&
                (
                    <div className={styles.input_XContainer} onClick={handleReset} data-testid="xButton">
                        <div className={styles.input_X} ></div>
                    </div>
                )
            }
            {
                showValidationMessage &&
                <span
                    className={styles.stateNotValidMSG}
                    data-testid="invalidStateMSG"
                >
                    State of residence is invalid.
                </span>
            }
        </section >
    );
};


export default StateOfResidenceInput;