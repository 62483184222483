import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuthLogic, TokenContext } from '../../hooks/useAuthLogic';

export const OAuthRedirectStripe = ({ children }) => {
    const { token, isAuthenticated } = useAuthLogic();

    if (isAuthenticated === undefined) {
        return <Spinner animation="border" />;
    }

    return <TokenContext.Provider value={token}>{children}</TokenContext.Provider>;
};
