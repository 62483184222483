import PropTypes from 'prop-types';

import styles from './ResourceCenterPage.module.scss';
import StyledLink from '../../components/styled-link/StyledLink';
import ArticleLanding from '../../components/article-landing/ArticleLanding';


const ResourceCenterBottomHalf = (props) => {
    // { articleBGimg, articleButtonColor, articleButtonLinkTo, articleButtonSize, articleButtonText,
    // articleHeader, articleReadTime, buttonColor, buttonLinkTo, buttonSize, buttonText, headerText }

    return (
        <div className={styles.landingBottomHalfContainer}>

            <div className={styles.landingBottomContent}>
                <h1 className={styles.landingBottomHeader}>
                    {props.headerText}
                </h1>
                <StyledLink
                    styledLinkText={props.buttonText}
                    styledLinkColor={props.buttonColor}
                    styledLinkSize={props.buttonSize}
                    linkTo={props.buttonLinkTo}
                />
            </div>

            <ArticleLanding
                bgIMG={props.articleBGimg}
                articleButtonText={props.articleButtonText}
                articleButtonColor={props.articleButtonColor}
                articleButtonSize={props.articleButtonSize}
                articleHeader={props.articleHeader}
                articleButtonLinkTo={props.articleButtonLinkTo}
                articleReadTime={props.articleReadTime}
            />
        </div>
    );
};

// { articleBGimg, articleButtonColor, articleButtonLinkTo, articleButtonSize, articleButtonText, articleHeader,
// articleReadTime, buttonColor, buttonLinkTo, buttonSize, buttonText, headerText }

ResourceCenterBottomHalf.propTypes = {
    articleBGimg: PropTypes.string,
    articleButtonColor: PropTypes.string,
    articleButtonLinkTo: PropTypes.string,
    articleButtonSize: PropTypes.string,
    articleButtonText: PropTypes.string,
    articleHeader: PropTypes.string,
    articleReadTime: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonLinkTo: PropTypes.string,
    buttonSize: PropTypes.string,
    buttonText: PropTypes.string,
    headerText: PropTypes.string
};

export default ResourceCenterBottomHalf;
