/* eslint-disable indent */
import React from "react";
import styles from "./Form.module.scss";

export const FormTitle = ({ title }) => {
  return <p className={styles.title}>{title}</p>;
};

export const Input = ({
  placeholder,
  inputId,
  label,
  inputType,
  inputValue,
  onChange,
  inputRef,
  additionalClasses = [],
  disabled = false,
  autocomplete = "",
}) => {
  return (
    <section className={styles.inputContainer}>
      <div className={styles.label}>
        <label htmlFor={inputId}>{label}</label>
      </div>
      <input
        type={inputType}
        id={inputId}
        placeholder={placeholder}
        className={`${styles.input} ${additionalClasses.join(" ")}`}
        value={inputValue}
        onChange={onChange}
        ref={inputRef}
        disabled={disabled}
        autoComplete={autocomplete}
      />
    </section>
  );
};

export const PhoneInput = ({
  inputId,
  label,
  areaCode,
  onAreaCodeChange,
  lineNumber,
  onLineNumberChange,
  countryCode,
  onCountryCodeChange,
  prefix,
  onPrefixChange,
}) => {
  return (
    <section className={styles.inputContainer}>
      <div className={styles.label}>
        <label htmlFor={inputId}>{label}</label>
      </div>
      <div className={styles.phoneInput}>
        <span className={styles.countryCodePrefix}>+</span>
        <input
          type="number"
          id="countryCode"
          value={countryCode}
          onChange={onCountryCodeChange}
          className={styles.countryCode}
          placeholder="1"
          data-testid="countryCode"
        />
        <span>(</span>
        <input
          type="number"
          id="areaCode"
          value={areaCode}
          onChange={onAreaCodeChange}
          className={styles.areaCode}
          placeholder="000"
          data-testid="areaCode"
        />
        <span>)</span>
        <input
          type="number"
          id="prefix"
          value={prefix}
          onChange={onPrefixChange}
          className={styles.prefix}
          placeholder="000"
          data-testid="prefix"
        />
        <span className={styles.hyphen}>-</span>
        <input
          type="number"
          id="lineNumber"
          value={lineNumber}
          onChange={onLineNumberChange}
          className={styles.lineNumber}
          placeholder="0000"
          data-testid="lineNumber"
        />
      </div>
    </section>
  );
};

export const CheckBox = ({ inputId, label, inputRef, disabled = false }) => {
  return (
    <section className={styles.checkboxContainer}>
      <input
        type="checkbox"
        id={inputId}
        className={styles.checkbox}
        ref={inputRef}
        disabled={disabled}
      />
      <div className={styles.checkboxLabel}>
        <label htmlFor={inputId}>{label}</label>
      </div>
    </section>
  );
};

export const invalidFieldLength = (value, desiredLength) =>
  !value || value.length <= 0 || value.length > desiredLength ? true : false;

export const invalidCharacters = (value) =>
  value.match(/--|;/gi) || value.match(/[<>=/":]+/gi) ? true : false;

export const invalidRegisterCharacters = (value) =>
  value.match(/--|;|-|_/gi) || value.match(/[<>=/":@_)(*&^%$#@!)+]+/gi)
    ? true
    : false;
