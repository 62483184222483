/* eslint-disable indent */
import React, { useState } from 'react';
import styles from './Filter.module.scss';
import FilterInput from './FilterInput';
import FilterCheckbox from './FilterCheckbox';
import FilterMobileBanner from './FilterMobileBanner';
import { FilterOptions } from '../../models/filterOptions';

const FilterControls = ({
    filterOptions, 
    subCategories, 
    searchAddress, 
    searchRadius, 
    isDisabled,
    isPreview,
    checkboxReferences,
    handleRadiusFilterChange,
    handleSearchAddressChange,
    handleClearFilters,
    handleSelectedSubCategoryChange,
    handleSearchSubmission
}) => {
    const [mobileBannerIsToggled, setMobileBannerIsToggled] = useState(false);
    
    return (
        <>
            <FilterMobileBanner
                isPreview={isPreview}
                mobileBannerIsToggled={mobileBannerIsToggled}
                setMobileBannerIsToggled={setMobileBannerIsToggled}
            />
            <div className={mobileBannerIsToggled ? styles.filterContainer : styles.dynamicFilterContainer } >
                {filterOptions.includes(FilterOptions.LOCATION) && 
                    <FilterInput 
                        isLocation 
                        id="location" 
                        label="Your Location:" 
                        inputType="text" 
                        inputValue={!searchAddress ? '' : searchAddress} 
                        onChange={handleSearchAddressChange}
                        isDisabled={isDisabled}
                    />}
                {filterOptions.includes(FilterOptions.RADIUS) && 
                    <FilterInput 
                        id="radius" 
                        label="Show Results Within:" 
                        inputType="number" 
                        inputValue={searchRadius} 
                        onChange={handleRadiusFilterChange}
                        isDisabled={isDisabled}
                    />}
                {filterOptions.includes(FilterOptions.SUBCATEGORIES) &&
                    !isPreview?<FilterCheckbox 
                        subCategories={subCategories}
                        isDisabled={isDisabled}
                        handleSelectedSubCategoryChange={handleSelectedSubCategoryChange}
                        checkboxReferences={checkboxReferences}
                    />
                    :<></>
                }
                <button 
                    type="button" 
                    className={isPreview ? styles.submitSearchBtn : styles.clearFiltersBtn }
                    onClick={ isPreview ? handleSearchSubmission : handleClearFilters }>
                    { isPreview ? "Submit Search" : "Clear Filters"} 
                </button>
                
            </div>
        </>
    );
};

export default FilterControls;