import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './FitnessCenterDetailsPage.module.scss';
import Logo from '../../../components/logo/Logo';
import FeaturedOffers from '../../../components/offer/featured-offers/FeaturedOffers';
import ProductTypeId from '../../../models/productTypeId';
import FitnessCenterDetails from '../../../components/fitness-centers/fitness-center-details/FitnessCenterDetails';
import FitnessCenterLocation from '../../../components/fitness-centers/fitness-center-details/FitnessCenterLocation';
import OfferDetails from '../../../components/offer/offer-details/OfferDetails';
import { useGetProductDetailsMutation } from '../../../services/react-query/choices.service';
import ProductSearchDto from '../../../dto/ProductSearchDto';
import Layout from '../../../components/layout/Layout';
import Loading from "../../../components/loading/Loading";

const FitnessCenterDetailsPage = () => {
    const { fitnessCenterId } = useParams();
    const [ fitnessCenter, setFitnessCenter] = useState({});
    const { mutateAsync: getProductDetails, isLoading: isRetrievingDetails } = useGetProductDetailsMutation();
    const cachedFitnessCenters= JSON.parse(localStorage.getItem('choices-products'));
    const navigate = useNavigate();
    
    useEffect(async ()=> {
        let cachedFitnessCenter;
        if(cachedFitnessCenters !== null) 
            cachedFitnessCenter = 
                cachedFitnessCenters.filter(fitnessCenter=>fitnessCenter.id === parseInt(fitnessCenterId))[0];
        
        if(!cachedFitnessCenter) {
            const productDetail = await getProductDetails({
                productTypeId:ProductTypeId.FACILITY,
                id: fitnessCenterId,
                productSearchDto: new ProductSearchDto()
            }).catch(error=>error);

            if(productDetail) setFitnessCenter(productDetail);
            else navigate("/not-found");

        } else {
            setFitnessCenter(cachedFitnessCenter);
        }
    },[fitnessCenterId]);

    return ( 
        <Layout pageTitle={'Fitness Center Details'}>
            <div className={styles.pageContainer}>
                {!fitnessCenter || Object.keys(fitnessCenter).length < 1 ? undefined :
                    <OfferDetails
                        heading="Fitness & Wellness"
                        productTypeId={ProductTypeId.FACILITY}
                        imagePath="/images/fitness-center.jpg"
                        discount={fitnessCenter.discount || fitnessCenter.webDiscount}
                        title={fitnessCenter.name}
                        detailsTitle1="Location"
                        detailsTitle2="Details"
                        offerItem={ { practitioner: null, fitnessCenter: fitnessCenter, product: null} }
                        detailsChild1={<FitnessCenterLocation fitnessCenter={fitnessCenter} />}
                        detailsChild2={
                            <FitnessCenterDetails 
                                discountComments={!fitnessCenter.discountComments ? "" : fitnessCenter.discountComments}
                            />
                        }
                    />}
                <Logo/>
                {!cachedFitnessCenters || cachedFitnessCenters.length < 1 ? undefined :
                    <FeaturedOffers 
                        items={cachedFitnessCenters} 
                        title="Related Discounts"
                    />}
            </div>
        </Layout>
    );
};

export default withAuthenticationRequired(FitnessCenterDetailsPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
