import AccountService from "../services/account.service";

export default class UpdateUserDto {
    constructor(newEmail, firstName, lastName, state, domainId = null, clientId = null) {
        if (!newEmail || !firstName || !lastName) throw new Error("Email or full name is null or undefined");

        this.userName = newEmail;
        this.email = newEmail;
        this.state = state; 
        this.firstName = firstName;
        this.lastName = lastName;
        this.domainId = !domainId ? parseInt(localStorage.getItem('choices-domainId')) : domainId;
        this.clientId = !clientId ? parseInt(localStorage.getItem('choices-clientId')) : clientId;
        this.consentsToCommunication = AccountService.getCommunicationConsent();
    }
}
