import React, { useState } from 'react';
import validator from 'validator';
import ActionButton from '../action-button/ActionButton';
import { FormTitle, Input, invalidCharacters, invalidFieldLength, PhoneInput } from '../form/Form';
import Loading from '../loading/Loading';
import NominatePractitionerErrors from '../../models/nominatePractitionerErrors';
import styles from './NominatePractitionerForm.module.scss';
import NominatePractitionerDto from '../../dto/NominatePractitionerDto';
import { useNominatePractitionerMutation } from '../../services/react-query/choices.service';

const NominatePractitionerForm = () => {
    const [nominee, setNominee] = useState({
        firstName: "", 
        lastName: "", 
        orgName: "", 
        email: "", 
        areaCode: "", 
        lineNumber: "",
        countryCode:1,
        prefix:""
    });
    const [submissionMessage, setSubmissionMessage] = useState("");
    const [messageStyle, setMessageStyle] = useState(styles.hidden);
    const { mutateAsync: nominatePractitioner, isLoading: isSendingFormContents} = useNominatePractitionerMutation();

    const nominationRules = [
        { 
            boolComparison:(!nominee.lineNumber || !(/^\d{4}$/).test(nominee.lineNumber)),
            errorMsg: NominatePractitionerErrors.LINE_NUMBER_ERR
        },
        { 
            boolComparison:(!nominee.prefix || !(/^\d{3}$/).test(nominee.prefix)),
            errorMsg: NominatePractitionerErrors.PREFIX_ERR
        },
        { 
            boolComparison:(!nominee.areaCode || !(/^\d{3}$/).test(nominee.areaCode)),
            errorMsg: NominatePractitionerErrors.AREA_CODE_ERR
        },
        { 
            boolComparison:(!nominee.countryCode || !(/^[0-9]{1,3}$/).test(nominee.countryCode)),
            errorMsg: NominatePractitionerErrors.COUNTRY_CODE_ERR
        },
        {
            boolComparison:!validator.isEmail(nominee.email),
            errorMsg: NominatePractitionerErrors.EMAIL_ERR
        },
        { 
            boolComparison:(invalidFieldLength(nominee.orgName,90) || invalidCharacters(nominee.orgName) 
            || (/[^a-zA-Z-' ]+$/.test(nominee.orgName))),
            errorMsg: NominatePractitionerErrors.ORG_NAME_ERR
        },
        { 
            boolComparison:(invalidFieldLength(nominee.lastName,90) || invalidCharacters(nominee.lastName) 
            || (/[^a-zA-Z-' ]+$/.test(nominee.lastName))),
            errorMsg: NominatePractitionerErrors.LAST_NAME_ERR
        },
        {
            boolComparison: (invalidFieldLength(nominee.firstName,90) || invalidCharacters(nominee.firstName) 
            || (/[^a-zA-Z-' ]+$/.test(nominee.firstName))),
            errorMsg: NominatePractitionerErrors.FIRST_NAME_ERR
        }
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();
        let isContactRuleViolation = false;
        nominationRules.forEach(rule=> { 
            if(rule.boolComparison) {
                setSubmissionMessage(rule.errorMsg); 
                setMessageStyle(styles.error);
                isContactRuleViolation = rule.boolComparison;
            }
        });
        if(isContactRuleViolation) return;

        const nomination = await nominatePractitioner(new NominatePractitionerDto(nominee))
            .catch(error => error);

        if(nomination && nomination.isSuccess) {
            setSubmissionMessage(nomination.message);
            setMessageStyle(styles.success);
            return setNominee({
                firstName: "", 
                lastName: "", 
                orgName: "", 
                email: "", 
                areaCode: "", 
                lineNumber: "",
                countryCode:1,
                prefix:""
            });
        } else {
            setSubmissionMessage(NominatePractitionerErrors.NOMINATE_ERR); 
            return setMessageStyle(styles.error);
        }
    };

    const handleChange = (event,property,maxLength=90) => {
        if(event.target.value.length > maxLength) return;
        let newState = {...nominee};
        Object.defineProperty(newState,property,{value:event.target.value});
        setNominee(newState);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <FormTitle title="Nominate a Practitioner"/>
            <section className={styles.formInputs}>
                <div className={styles.formItem}>
                    <div className={styles.inputContainer}>
                        <Input 
                            inputId="firstName"
                            label="First Name"
                            inputType="text" 
                            inputValue={nominee.firstName}
                            onChange={(event)=>handleChange(event,"firstName")}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <Input 
                            inputId="lastName"
                            label="Last Name"
                            inputType="text" 
                            inputValue={nominee.lastName}
                            onChange={(event)=>handleChange(event,"lastName")}
                        />
                    </div>
                </div>
                <div className={styles.formItem}>
                    <Input
                        inputId="orgName"
                        label="Business/Practice Name"
                        inputType="text"
                        inputValue={nominee.orgName}
                        onChange={(event)=>handleChange(event,"orgName")}
                    />
                </div>
                <div className={styles.formItem}>
                    <div className={styles.inputContainer}>
                        <Input
                            inputId="email"
                            label="Email Address"
                            inputType="email"
                            inputValue={nominee.email}
                            onChange={(event)=>handleChange(event,"email")}
                        />
                    </div>
                    <div className={`${styles.inputContainer} ${styles.phoneContainer}`}>
                        <PhoneInput
                            inputId="phone"
                            label="Phone Number"
                            areaCode={nominee.areaCode}
                            onAreaCodeChange={(event)=>handleChange(event,"areaCode",3)}
                            lineNumber={nominee.lineNumber}
                            onLineNumberChange={(event)=>handleChange(event,"lineNumber",4)}
                            countryCode={nominee.countryCode}
                            onCountryCodeChange={(event)=>handleChange(event,"countryCode",3)}
                            prefix={nominee.prefix}
                            onPrefixChange={(event)=>handleChange(event,"prefix",3)}
                        />
                    </div>
                </div>
                <menu className={styles.formMenu}>
                    <div className={styles.formBtns}>
                        <ActionButton btnType="submit">Submit</ActionButton>
                        <span className={`${styles.message} ${messageStyle}`}>{submissionMessage}</span>
                    </div>
                </menu>
            </section>
            <Loading isLoading={isSendingFormContents} isFullScreen></Loading>
        </form>
    );
};

export default NominatePractitionerForm;