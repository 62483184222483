import DownloadHistoryDto from "../dto/DownloadHistoryDto";
import OfferEvent from "../models/OfferEvent";
import ProductTypeId from "../models/productTypeId";

export default class OfferService {

    static getDirections(address) {
        const origin = `${localStorage.getItem('choices-latitude')},${localStorage.getItem('choices-longitude')}`;
        const destination = `${address.addressLine1}+${address.city},+${address.state}+${address.zipCode}`;
        return window.open(`https://www.google.com/maps/dir/${origin}/${destination}`);
    }

    static async publishEvent(publishEvent, event, productTypeId, productId) {
        let eventDto = new DownloadHistoryDto(productTypeId, productId, event);
        const statusCode = await publishEvent(eventDto).catch(err => err).catch(err => err);
        if(statusCode === 201) return;
        else throw new Error("Failed to publish event to Choices API");
    }

    static getSearchPagePath(productTypeId) {
        if(productTypeId === ProductTypeId.FACILITY) return "/fitness-centers";
        if(productTypeId === ProductTypeId.VENDOR) return "/products";
        return "/practitioners";
    }

    static getOfferItemType(productTypeId) {
        if(productTypeId === ProductTypeId.FACILITY) return "fitnessCenter";
        if(productTypeId === ProductTypeId.VENDOR) return "product";
        return "practitioner";
    }

    static getOfferActionVerb(action) {
        if(!action) return null;
        if(action === OfferEvent.EMAIL) return "Emailed";
        if(action === OfferEvent.PRINT) return "Printed";
        return "Redeemed";
    }

    static getOfferConfiguration(productTypeId) {
        if(productTypeId === ProductTypeId.VENDOR) 
            return {
                btnOneText: "Redeem Offer",
                btnOneImage: "redeemOffer.svg",
                btnTwoText: "Copy Code",
                btnTwoImage: "copyCode.svg",
                btnTwoPerformsCopy: true,
            };
        
        return {
            btnOneText: "Print Offer",
            btnOneImage: "print.svg",
            btnTwoText: "Email to Self",
            btnTwoImage: "email.svg",
            btnTwoPerformsCopy: false,
        };
    }

    static useFallbackImage(event, imagePath) {
        event.target.src = imagePath;
    }

    static debounceUserInput(callback, debounceTimeout, setDebounceTimeout) {
        const DEBOUNCE_TIMEOUT_MS = 700;
        clearTimeout(debounceTimeout);
        setDebounceTimeout(setTimeout(() => {
            /* 
            This will reduce the workload for the server - request will not be sent every time
            the user modifies the input field; instead, we will "Debounce" user input - the 
            method below will only run once the user stops typing (delay XX ms).
            */
            callback(); 
        }, DEBOUNCE_TIMEOUT_MS));
    }

    // testing this, leaving for now -evan
    static getClientName(name) {
        const clientName = localStorage.getItem("choices-client-name");
        const isMember = clientName === name;
        return isMember;
    }
}