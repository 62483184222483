import React, { useEffect, useMemo, useState } from "react";
import styles from './FeaturedOffers.module.scss';
import PractitionerCard from '../../../components/practitioners/PractitionerCard';
import ProductCard from '../../../components/products/ProductCard';
import ScrollAnimation from "../../scroll-animation/ScrollAnimation";
import ProductTypeId from "../../../models/productTypeId";
import FitnessCenterCard from '../../../components/fitness-centers/FitnessCenterCard';
import { Link } from "react-router-dom";

const FeaturedOffersHero = ({items = []}) => {
    const CARD_WIDTH = 210;
    const LOOP_INTERVAL_MS = 5000;
    const CAROUSEL_MARGIN = 0.5; 
    const carouselButtonInitialState = {left: true, right: false};
    const animationInitialState = {slideLeft: false, slideRight: false};

    const [disableCarouselButton, setDisableCarouselButton] = useState(carouselButtonInitialState);
    const [animation, setAnimation] = useState(animationInitialState);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [sliceItemsStart, setSliceItemsStart] = useState(0);
    const [displayedItemsCount, setDisplayedItemsCount] = useState(1);
    const [loopTimeout, setLoopTimeout] = useState(null);
    const [incrementLoopDisplay, setIncrementLoopDisplay] = useState(null);
    
    const detectScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };
    
    useEffect(() => {
        /*
         The number of items displayed is equal to the width of the screen, 
         minus a % of the screen width, all divided width of each card.
         */
        setDisplayedItemsCount(screenWidth <= 460 ? 1 : 
            Math.floor((screenWidth - (screenWidth * CAROUSEL_MARGIN))/CARD_WIDTH));
        // If the screen is resized, the #items displayed changes dynamically to fit the screen size
        window.addEventListener('resize', detectScreenWidth);
        return () => {
            window.removeEventListener('resize', detectScreenWidth);
        };
    }, [screenWidth]);

    useMemo(()=>{
        /* 
            The hook moves the carousel forward if it has not reached the end of the list,
            minus the number of items to be displayed. It will move backwards once it
            reaches that point, and forwards again once it hits the beginning of the list. 
            We clear the timeout at the beginning so that if the user interacts with the
            carousel, it will not add another setTimeout() onto the call stack.
        */
        clearTimeout(loopTimeout);
        if(items.length <= displayedItemsCount) return setDisableCarouselButton({left: true, right: true});
        
        if(incrementLoopDisplay === null) setIncrementLoopDisplay(true);
        
        if (incrementLoopDisplay && sliceItemsStart < items.length - displayedItemsCount) 
            return setLoopTimeout(setTimeout(()=>handleClick(true),LOOP_INTERVAL_MS));
        
        if (incrementLoopDisplay && sliceItemsStart >=  items.length - displayedItemsCount) {
            setLoopTimeout(setTimeout(()=>handleClick(false),LOOP_INTERVAL_MS));
            setIncrementLoopDisplay(false);
        }
        
        if(!incrementLoopDisplay && sliceItemsStart > 0) 
            setLoopTimeout(setTimeout(()=>handleClick(false),LOOP_INTERVAL_MS));
        
        if (sliceItemsStart <= 1 && !incrementLoopDisplay) {
            setIncrementLoopDisplay(true);
            setLoopTimeout(setTimeout(()=>handleClick(true),LOOP_INTERVAL_MS));
        }
    },[sliceItemsStart]);

    const handleClick = (isNextButton) => {
        // Reset any disabled buttons
        setDisableCarouselButton({left: false, right: false});
        
        isNextButton ? setAnimation({...animationInitialState, slideRight: true}) : 
            setAnimation({...animationInitialState, slideLeft: true});
        
        // Check for first/last item in array & disable corresponding next/previous button accordingly
        if(isNextButton && sliceItemsStart >= items.length - displayedItemsCount -1)
            setDisableCarouselButton({left: false, right: true});
        if(!isNextButton && sliceItemsStart <= 1) 
            setDisableCarouselButton({left: true, right: false});
        
        return setSliceItemsStart(isNextButton ? sliceItemsStart + 1 : sliceItemsStart - 1);
    };

    return (
        <ScrollAnimation 
            classes={{base:styles.featuredOffersHero,animation:styles.animate,hidden:styles.hidden}}
        >
            <section className={styles.heroText}>
                <p className={styles.titleHero}>Featured Offers</p>
                <Link to="/products">VIEW DISCOUNTS</Link>
            </section>
            <section className={styles.carouselControlHero}>
                <button 
                    style={{justifyContent: "flex-end", opacity: disableCarouselButton.left ? 0.25 : 1}}
                    className={styles.carouselBtnHero}
                    onClick={()=>handleClick(false)}
                    disabled={disableCarouselButton.left}
                >
                    <img src="/images/arrow-left-white.svg"
                        alt="Toggle previous featured offer"/>
                </button>
                <div 
                    className={`${styles.carouselHero} 
                    ${animation.slideLeft ? styles.animateHeroLeft : undefined} 
                    ${animation.slideRight ? styles.animateHeroRight : undefined}`}
                    onAnimationEnd={()=>setAnimation(animationInitialState)}>
                    {/* Next/Previous items change slice() arguments, so we add the start value to the display count
                     to make sure we are on the appropriate ending array index */}
                    {items.slice(sliceItemsStart, displayedItemsCount + sliceItemsStart).map((item) => {
                        if (item.productTypeId === ProductTypeId.PROVIDER) {
                            return (
                                <div 
                                    key={items.indexOf(item)} 
                                    style={{maxWidth: `${CARD_WIDTH}px`}} 
                                    className={styles.cardContainer}>
                                    <PractitionerCard practitioner={item}/>
                                </div>
                            );
                        }
                        if (item.productTypeId === ProductTypeId.VENDOR) {
                            return (
                                <div 
                                    key={items.indexOf(item)} 
                                    style={{maxWidth: `${CARD_WIDTH}px`}} 
                                    className={styles.cardContainer}>
                                    <ProductCard product={item}/>
                                </div>
                            );
                        }
                        if (item.productTypeId === ProductTypeId.FACILITY) {
                            return (
                                <div 
                                    key={items.indexOf(item)} 
                                    style={{maxWidth: `${CARD_WIDTH}px`}} 
                                    className={styles.cardContainer}>
                                    <FitnessCenterCard fitnessCenter={item}/>
                                </div>
                            );
                        }
                    })}
                </div>
                <button 
                    style={{justifyContent: "flex-start", opacity: disableCarouselButton.right ? 0.25 : 1}} 
                    className={styles.carouselBtnHero}
                    onClick={()=>handleClick(true)}
                    disabled={disableCarouselButton.right}
                >
                    <img src="/images/arrow-right-white.svg"
                        alt="Toggle next featured offer"/>
                </button>
            </section>
        </ScrollAnimation>
    );
};

export default FeaturedOffersHero;