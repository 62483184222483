import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../components/action-button/ActionButton';
import styles from './ErrorPage.module.scss';

const ErrorPage = ({imagePath, title, isErrorBoundary}) => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate("/");
        if(isErrorBoundary) window.location.reload();
    };

    return (
        <section className={styles.pageContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.text}>
                    <h1>{title}</h1>
                    <ActionButton 
                        onClick={handleClick} 
                        additionalClasses={[styles.btn]}>
                        Return to Home
                    </ActionButton>
                </div>
                <div className={styles.image}>
                    <img src={imagePath} alt={title} loading="lazy"/>
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;