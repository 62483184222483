import { useAuth0 } from '@auth0/auth0-react';

const AuthorizedFooterLinks = ({authorizedLinks, unauthorizedLinks}) => {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated)
        return <>{authorizedLinks.map((link, index) => {return <span key={index}>{link}</span>;})}</>;
    else return <>{unauthorizedLinks.map((link, index) => { return <span key={index}>{link}</span>;})}</>;
};

export default AuthorizedFooterLinks;