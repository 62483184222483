import React from "react";
import ActionButton from "../action-button/ActionButton";
import styles from './AccountForm.module.scss';


const SubmitUpdate = ({onClick}) => {
    return (
        <section className={styles.submitContainer}>
            <div className={styles.submitBtn}>
                <ActionButton 
                    btnType="submit"
                    onClick={onClick}>
                            Update Account
                </ActionButton>
            </div>
        </section>
    );
};

export default SubmitUpdate;