import React, { useState, useEffect } from 'react';
import styles from './CancelSubscriptionInput.module.scss';
import ActionButton from "../action-button/ActionButton";
import LogoMark from './LogoMark';

const CancelSubscriptionSuccessModal = (props)=> { 
    const handleSubmit = async (event) => {
        event.preventDefault();
        props.onButtonClick();             
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeaderContainer}>
                    <h1 className={styles.modalHeader}>
                    Your account has been successfully cancelled
                    </h1>
                </div>
                <div className={styles.modalFormContainer}>                                         
                    <div className={styles.modalButtonComplete}>
                        <ActionButton
                            btnType="submit"
                            onClick={(event)=>handleSubmit(event)}
                        >
                            Done
                        </ActionButton>
                    </div>                   
                </div>
                <LogoMark />
            </div>
        </div>
    );
};

export default CancelSubscriptionSuccessModal;