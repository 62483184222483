/* eslint-disable max-len */
/* eslint-disable indent */
import React from 'react';
import Layout from '../../components/layout/Layout';

const PrivacyPolicy = () => {
    { setTimeout(()=>{
        const anchor = window.location.hash;
        const hashlessAnchor = anchor.replace('#', '');
       anchor && anchor!=='' ? document.getElementById(hashlessAnchor).scrollIntoView({behavior : 'smooth'}) : null;
    }, 200);}
    return (
        <Layout pageTitle={'Privacy Policy'}>
            <section style={{ width: "100%", display: "flex", justifyContent: "center", marginBlock: "1rem 4rem" }}>
                <div style={{ width: "90%" }}
               
                    // Using https://wordtohtml.net/ to simplify policy updates
                    dangerouslySetInnerHTML={{__html: `
                    <h1 style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-family:"Times New Roman",serif;text-align:justify;background:white;'><u>PRIVACY POLICY</u></h1>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:7.5pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><strong><u><span style='font-family:"Times New Roman",serif;'><span style="text-decoration:none;"><br></span></span></u></strong></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><em><span style='font-family:"Times New Roman",serif;color:black;'>Last Update: &nbsp;January 1, 2023</span></em></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;'>Tivity Health, Inc.</span><span style="color:black;">&reg;</span><span style='font-family:"Times New Roman",serif;color:black;'>&nbsp;(&ldquo;<u>Tivity</u>&rdquo;) owns and operates&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>websites, online platforms, mobile applications, and other services that link to this Privacy Policy (collectively, the &ldquo;<u>Services</u>&rdquo;)</span><span style='font-family:"Times New Roman",serif;color:black;'>. Tivity and its subsidiaries are referred to as &ldquo;<u>we</u>,&rdquo; &ldquo;<u>us</u>,&rdquo; or &ldquo;<u>our</u>.&rdquo;&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><strong><span style='font-family:"Times New Roman",serif;color:black;'>PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THE SERVICES.</span></strong></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We value and respect the privacy of our customers, visitors, and users of the Services and our workforce (including employees, job applicants, contractors, consultants, and any owners, officers, or other members of Tivity (collectively, &ldquo;<u>Personnel</u>&rdquo;)) (all together, &ldquo;<u>you</u>&rdquo; or &ldquo;<u>your</u>&rdquo;). The purpose of the following Privacy Policy (&ldquo;<u>Privacy Policy</u>&rdquo;) is to provide you with information about Tivity&rsquo;s policies regarding the collection, processing, use, storage, and disclosure of information when you use our Services or that we otherwise collect from our Personnel. This Privacy Policy also describes the choices you can make about our use of your information.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>When you use the Services, you are consenting to the collection and use of your information as described in this Privacy Policy, though we may ask for specific consent to particular uses of your information or collection of specific types of sensitive information. By accessing or using the Services, you&nbsp;</span><span style='font-family:"Times New Roman",serif;'>represent and warrant that you are at least eighteen (18) years old and possess the legal right and ability to agree to the following Privacy Policy</span><span style='font-family:"Times New Roman",serif;'>. If you do not agree to all of the terms and conditions of this Privacy Policy, you should not access or use the Services or disclose any Personal Information (defined below) through the Services.&nbsp;</span><span style='font-family:"Times New Roman",serif;'>By using the Services and accepting these terms, you agree to the terms of this Privacy Policy and the collection and use of information in accordance with this Privacy Policy.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;'>From time to time, we may revise or modify this Privacy Policy, by posting the amended version including the effective date of the updated version. You should regularly review this Privacy Policy whenever you access, visit, and/or use the Services.</span><span style='font-size:11px;font-family:"Times New Roman",serif;color:black;'>&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>If you do not agree to be bound by this Privacy Policy or any subsequent modifications, you should not access or use our Services or disclose any Personal Information through our Services.</span><span style='font-size:11px;font-family:"Times New Roman",serif;color:black;'>&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>This Privacy Policy is incorporated into and governed by our Terms of Use, which is accessible within the footer of our websites.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:11px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:15px;font-family:"Times New Roman",serif;color:black;'>Depending upon where you live, additional terms and protections may apply. Click&nbsp;</span><span style="color:black;"><a href="#State_Specific_Rights"><span style='font-family:"Times New Roman",serif;'>here</span></a></span><span style='font-size:15px;font-family:"Times New Roman",serif;color:black;'>&nbsp;for state-specific terms and protections.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-size:11px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol style="margin-bottom:0in;padding-left:1rem;list-style-type: decimal;">
                            <li id='How_to_Contact_Us' style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>HOW TO CONTACT US</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>If you have any questions or concerns about our use of your Personal Information (defined below) or wish to communicate with our senior privacy official or senior security official, please do not hesitate to contact us&nbsp;</span><span style='font-family:"Times New Roman",serif;'>by email at <span style="color:blue;text-decoration:underline;"><a href="mailto:privacy@tivityhealth.com">privacy@tivityhealth.com</a></span><span style="color:#232226;">.</span></span></p>
                    <br>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="2" style="padding-left:1rem;margin-bottom:0in;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>HOW WE COLLECT PERSONAL INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>While using, accessing, or otherwise engaging with our Services, we may collect or ask you to provide us with certain personally identifiable information (&ldquo;<u>Personal Information</u>&rdquo;). Personal Information is information that identifies, relates to, describes, can reasonably be associated with, or can reasonably be linked to a particular individual or household.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We collect information from you both when you provide it voluntarily and also automatically when you use our Services. &nbsp;We may also collect Personal Information from other sources, as described below.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We collect Personal Information from you and any devices (including mobile devices) you use when you use our Services, register for an account with us, provide us information on a web form or over the telephone, update or add information to your account, or when you otherwise correspond with us or interact with us through the Services or engage or connect with us through one of our social media pages. &nbsp;Please remember that any information that you disclose in public comments, forums, or other areas where users can submit comments (&ldquo;<u>Forums</u>&rdquo;) becomes public, is not maintained or treated as confidential, and you should exercise caution when deciding to disclose your Personal Information.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>In addition, we also collect Personal Information about you from third parties in connection with our Services, including from the following sources:</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><em><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span></em><span style='font-family:"Times New Roman",serif;'>Our customers, including health plans and others</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Business partners, including participating fitness locations</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Data analytics service providers</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Email, chat, and other communications service providers</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Customer service providers</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Advertising providers</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Social media platforms</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Promotional partners&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;' id="information_collection">
                        <ol start="3" style="padding-left:1rem;margin-bottom:0in;list-style-type: decimal; padding: 0.5rem 1rem;">
                            <li id='Information_we_collect_and_how_we_use' style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>INFORMATION WE COLLECT AND HOW WE USE PERSONAL INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;background:white;'>We may collect the following categories of Personal Information from you, depending on your interactions with our&nbsp;</span><span style='font-family:"Times New Roman",serif;color:black;'>Services</span><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;and the choices you make, as further described below:&nbsp;</span></p>
                    <ul style="list-style-type: disc;">
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Identifiers.&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Your name, email address, postal address, telephone number, unique personal identifier, and Internet Protocol (IP) address.</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Interactions.&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Records of products or services obtained, considered, or other consuming histories.</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>User data</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>. Any data entered voluntarily into a form on the Services.</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Financial Information</span></strong><span style="background:white;">. Your name, billing address, and payment information (including credit or debit card type, expiration date, and certain digits of your card number).&nbsp;</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Internet or other electronic network activity information.&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>For example:</span></li>
                    </ul>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:1.0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;background:white;'><span style="font-family:Symbol;color:black;background:white;">&middot;</span><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp; &nbsp;&nbsp;</span><strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Browsing history.</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;Data about the webpages you visit.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:1.0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;background:white;'><span style="font-family:Symbol;color:black;background:white;">&middot;</span><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp; &nbsp;&nbsp;</span><strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Search history.</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;Data about the search terms you enter.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:1.0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;background:white;'><span style="font-family:Symbol;color:black;background:white;">&middot;</span><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp; &nbsp;&nbsp;</span><strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Device, connectivity, and configuration data.</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;Data about your device and nearby networks, including regional and language settings.</span></p>
                    <ul style="list-style-type: disc;">
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Geolocation data.</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;Data about your device&rsquo;s location, which is inferred from your IP address and your postal address, and if you have enabled the location tracking features, data from your device may also be gathered pertaining to GPS signals, device sensors, WiFi access points, and cellular tower IDs. If you have enabled the location tracking features, location data may be collected when the mobile application is closed or not in use to enable the mobile application to promptly display nearby fitness locations, reduce check-in times, and automatically keep track of your route, distance, and speed stats.</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Professional or employment-related information.</span></strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>&nbsp;Data is collected from Personnel, such as your career history, and license credentials if you are applying for a job position that requires a license.</span>
                            <ol style="list-style-type: circle;">
                                <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Personnel Sensitive Personal Information</span></strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>. Data collected from Personnel may include sensitive personal information such as social security number, state identification card/driver&rsquo;s license, passport number, financial account information or bank account information, racial or ethnic origin.&nbsp;</span></li>
                            </ol>
                        </li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Educational information.</span></strong><span style="background:white;">&nbsp;Data collected from individuals seeking or maintaining employment through Tivity.</span></li>
                        <li><strong><span style='font-family:"Times New Roman",serif;color:black;color:black;background:white;'>Demographic data.</span></strong><span style='font-family:"Times New Roman",serif;color:black;background:white;'>&nbsp;Data about you such as your age (date of birth), preferred language, and other data reflecting your consumer preferences and characteristics.</span></li>
                    </ul>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;background:white;'><span style='font-family:"Times New Roman",serif;color:black;background:white;'>Your Personal Information is used by Tivity to enhance your relationship with us, respond to your requests, tailor offerings to you, communicate with you about products, services, special offerings, and events or programs offered by us or our marketing partners that may be of interest to you. Tivity also may use this information to analyze and manage our businesses. We generally process your information when we need to do so to fulfill a contractual obligation or where we or someone we work with needs to use your Personal Information for a reason related to our/their business. These interests are further detailed in the chart below. We only process Personal Information for these interests after considering the potential risks to your privacy. &nbsp;&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;background:white;'><span style='font-family:"Times New Roman",serif;color:#333333;background:white;'>&nbsp;</span></p>
                    <div style="overflow-x:auto;">
                        <table style="border: none;width:100.0%;border-collapse:collapse;">
                            <tbody>
                                <tr>
                                    <td colspan="2" style="width:56.68%;border:solid windowtext 1.0pt;background:#ADB9CA;padding:0in 5.4pt 0in 5.4pt;height:13.9pt;">
                                        <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><em><span style='font-size:16px;font-family:  "Times New Roman",serif;color:black;'>Information collected through use of our&nbsp;</span></em></strong><strong><em><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Services</span></em></strong></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: windowtext windowtext windowtext currentcolor;border-style: solid solid solid none;border-width: 1pt 1pt 1pt medium;border-image: none 100% / 1 / 0 stretch;background: rgb(173, 185, 202);padding: 0in 5.4pt;height: 13.9pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><em><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></em></strong></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: windowtext windowtext windowtext currentcolor;border-style: solid solid solid none;border-width: 1pt 1pt 1pt medium;border-image: none 100% / 1 / 0 stretch;background: rgb(173, 185, 202);padding: 0in 5.4pt;height: 13.9pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;background: rgb(173, 185, 202);padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;color:black;'>Categories of Information</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;background: rgb(173, 185, 202);padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;color:black;'>Description of category</span></strong></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;background: rgb(173, 185, 202);padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;color:black;'>How We Use Information</span></strong></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;background: rgb(173, 185, 202);padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;color:black;'>Categories of Third Parties with Whom we Disclose Information (discussed further in &ldquo;</span></strong><span style="color:black;"><a href="#Disclosure_of_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Disclosure of Information</span></em></strong></a></span><strong><span style='font-family:"Times New Roman",serif;color:black;'>&rdquo; below)</span></strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p id='Account_Information' style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Account Information&nbsp;</span></strong><strong><span style='font-family:"Times New Roman",serif;'>(Identifiers, Interactions, User data, Internet or electronic network activity information)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This is the Personal Information that is provided by you to enable you to login and access your account and/or our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>. This may include your name, address, email address, and phone number.&nbsp;</span></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Registering for an account is an optional service provided by some of our Services. Some of the Personal Information we will ask you to provide is required in order to create your account.&nbsp;</span></p>
                                    </td>
                                    <td style="border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide, maintain, personalize and improve our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To respond to your questions and requests.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To create, maintain and personalize your account with us.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide support.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To allow you to participate in interactive features of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;when you choose to do so.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To contact you with newsletters, marketing or promotional materials and other information that may be of interest to you.&nbsp;</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and Service Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Service and offering-related service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Analytics service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p id='Email_Registration_Information' style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Email Registration Information&nbsp;</span></strong><strong><span style='font-family:"Times New Roman",serif;'>(Identifiers, User Data)</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>You may provide your email address in order to receive information, news and alerts via email.&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To contact you with newsletters, marketing or promotional materials and other information that may be of interest to you.</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates&nbsp;</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Marketing service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Advertising service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p id='Customer_Service_Information' style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Customer Service Information&nbsp;</span></strong><strong><span style='font-family:"Times New Roman",serif;'>(Identifiers, User Data)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This includes any Personal Information that you choose to provide, whether by phone, email, mail, or chat, to our customer service representatives or other Tivity employees when making a request for information or otherwise contacting us through the Services.</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide, maintain, operate, personalize, and improve our Services.</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To respond to your questions and requests.&nbsp;</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide support.&nbsp;</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To allow you to participate in interactive features of the Services when you choose to do so.</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Service and offering-related service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Qualification Information (Identifiers, User data, Demographic data)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This is the Personal Information that you provide if you choose to determine if you qualify to participate in certain Services. This includes your name, date of birth, email, phone number, and zip code.</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To respond to your questions and requests.</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To contact you with newsletters, engagement, marketing, or promotional materials and other information that may be of interest to you.</span></li>
                                                </ul>
                                                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                    <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To gather analysis and assess trends and interests.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and Service Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Job Application, Personnel, and Business Contact Information (Identifiers, User data, Professional or employment-related information, Educational information)</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This is the Personal Information and Sensitive Personal Information that you may provide and that is collected when you engage with our Services, including if you choose to apply for and/or accept a job position with Tivity through the&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services or otherwise provide Personal Information as part of business contact information</span><span style='font-family:"Times New Roman",serif;'>. This includes your name, phone number, postal address, email address, career and educational history, and may include, with respect to Personnel, your Social Security number, state identification card/driver&rsquo;s license number and other information needed to pay you, verify your identity, provide you benefits or otherwise act in accordance with our relationship. We may ask for your license credentials if you are applying for a job position that requires a license.&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To assess your job application and respond to you concerning a job position, at our discretion.</span></li>
                                            </ul>
                                        </div>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:  normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Human resources software and/or service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Payment and Purchasing Information (Identifiers, Interactions, User data)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>We may use third parties to collect Personal Information if you make a purchase through our Services. The exact Personal Information collected will vary depending on the payment method but will include information such as:</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Name;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Credit or debit card type, expiration date, and certain digits of your card number;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Billing and shipping addresses; and</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Postal code.</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:  normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Please note that we do not collect or maintain the above payment information and you should refer to the privacy policies of our payment gateways and processors. We may also collect information concerning the products purchased or considered, or other purchasing or consuming histories or tendencies.</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To process payments and provide you with the products purchased.&nbsp;</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To respond to your questions and requests.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide customer support.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide with more relevant content and advertisements.</span></li>
                                                </ul>
                                                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                    <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To send you personalized emails, including news, announcements, reminders and opportunities from Tivity.&nbsp;</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Service or offering-related service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Demographic Data</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>We may collect such Personal Information when you contact us or respond to our communications to you, provide feedback or post on a forum through our Services, including if you leave a comment on articles posted on the Services.&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide, maintain, personalize, and improve our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide customer support.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To monitor the usage of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.</span></li>
                                                </ul>
                                                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                    <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To gather analysis and assess trends and interests.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Service or offering-related service providers</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Location Information (Identifiers, User data, Geolocation data)</span></strong></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>We may collect general location information if you use features on our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;that provide location-based services.&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide you with location-based services.</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To monitor the usage of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.</span></li>
                                                </ul>
                                                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                    <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To gather analysis and assess trends and interests.&nbsp;</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Affiliates</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Service or offering-related service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Analytics providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
                                        <p id='Usage_Information' style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Usage Information&nbsp;</span></strong><strong><span style='font-family:"Times New Roman",serif;'>(Interactions, Internet or electronic network activity information, Geolocation data)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This can be Personal Information and non-Personal Information that is collected about you when you are using our&nbsp;</span><span style='font-family:  "Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>, and this may include:&nbsp;</span></p>
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:-0.2in;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Information about your interactions with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>, which includes the date and time of any information you enter into our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;and your interactions with other users of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;and what content or features you interacted with.</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>User content you post to our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;including messages you send and/or receive in the Forums and your interactions with our customer service team and other users.&nbsp;</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Technical data which may include URL information, cookie data, web beacons and other tracking technology information, the website you were visiting before you visited the Services, your IP address, the types of devices you are using to access or connect to our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>, unique device IDs, device attributes, network connection type (e.g., WiFi, 3G, LTE, Bluetooth) and provider, network and device performance, browser type, language, and operating system.<strong>&nbsp;</strong>Further details about the technical data that is processed by us can be found below.</span></li>
                                            </ul>
                                        </div>
                                        <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>We or our third-party partners use common tracking tools (including cookies, web beacons, and pixels) which we and our third-party service providers may use to collect information about the pages you view, our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;functions that you access, the buttons and icons you click, and to make it easier and more efficient for you to use our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>, and to market to you and/or provide advertising content that we think may be of interest to you.</span></p>
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>You can choose whether to accept cookies by changing the settings on your browser or device. &nbsp;For more information regarding your choices with respect to cookies and other tracking technologies, please see &ldquo;</span><a href="#Your_Rights_and_Choices_Regarding_Your_I"><strong><em><span style='font-family:"Times New Roman",serif;'>Your Rights and Choices Regarding Your Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo; below. However, if you choose to disable this function, your experience with our&nbsp;</span><span style='font-family:  "Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;may be impaired and some features may not work as they were intended. When we use cookies or other similar technologies, we may set the cookies ourselves or ask third parties to do so to help us.&nbsp;</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To optimize the display of our Services on your device.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To create, maintain and personalize your experience.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide, maintain, personalize, and improve our&nbsp;</span><span style=";">Services</span><span style='font-family:"Times New Roman",serif;'>.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide customer support.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To monitor the usage of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To allow you to participate in interactive features of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;when you choose to do so.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To gather analysis and assess trends and interests.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To detect, prevent, and address technical issues.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide you with content and advertising in which we think you will be interested. As part of this customization, we may observe your behaviors on the&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;or on other websites.&nbsp;</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To help maintain the safety, security, and integrity of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;and technology assets.&nbsp;</span></li>
                                                    </ul>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Analytics providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Advertising service providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions&nbsp;</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 23.1%;border-color: currentcolor windowtext windowtext;border-style: none solid solid;border-width: medium 1pt 1pt;border-image: none 100% / 1 / 0 stretch;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:  0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-family:"Times New Roman",serif;'>Third Party Data (Identifiers, Internet or electronic network activity information)</span></strong></p>
                                    </td>
                                    <td style="width: 33.58%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:  0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>This includes both Personal Information and non-personally identifiable data from our affiliates, customers, partners or vendors, data brokers, or public sources.</span></p>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To provide, maintain, operate, personalize, and improve the Services.</span></li>
                                            </ul>
                                            <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.100000000000001px;">
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To monitor the usage of the Services.</span></li>
                                                    <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To gather analysis and assess trends and interests.</span></li>
                                                </ul>
                                                <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                                    <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                        <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>To work with our service providers who perform certain business functions or services on our behalf.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width: 21.66%;border-color: currentcolor windowtext windowtext currentcolor;border-style: none solid solid none;border-width: medium 1pt 1pt medium;padding: 0in 5.4pt;vertical-align: top;">
                                        <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                                            <ul style="margin-bottom:0in;list-style-type: disc;margin-left:7.300000000000001px;">
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Software and Service Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Analytics Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Marketing Service Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>Advertising Service Providers</span></li>
                                                <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>In connection with business transactions</span></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:19px;font-family:"Times New Roman",serif;color:#232226;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Generally,&nbsp;</span><span style='font-family:"Times New Roman",serif;'>you control the amount and type of information you voluntarily provide to us when using the Services. You are not required to provide us with any Personal Information as a visitor to the Services. When you register for an account, make a request for information, or otherwise communicate with us via the Services (as discussed in &ldquo;</span><a href="#Account_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Account Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo;, &ldquo;</span><a href="#Email_Registration_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Email Registration Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo; and &ldquo;</span><a href="#Customer_Service_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Customer Service Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo; in the table above), &nbsp;you may be required to provide us with certain Personal Information such as your name, email address, company name, as applicable. We will offer you choices when we ask for Personal Information whenever reasonably possible. Any information you provide may be combined with demographic and other information that is available in order to allow us to better communicate with you and enhance your experience. If we combine or associate information from other sources with Personal Information that we collect through the Services, we will treat the combined information as Personal Information in accordance with this Privacy Policy.</span></p>
                    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-family:"Times New Roman",serif;'>Anonymized Information</span></em><span style='font-family:"Times New Roman",serif;'>. We may collect non-personally identifiable Usage Information via cookies, web beacons, and similar technologies as further described in the &ldquo;</span><a href="#Usage_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Usage Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo; section above when you visit our Services. We may also use anonymized and aggregated information that may be created or derived from your Personal Information or usage of our Services for purposes that include data analysis, improving our Services, advertising, and developing new features and functionality within our Services. We may further use such anonymized and aggregated information to</span><span style='font-family:"Times New Roman",serif;'>&nbsp;conduct aggregate or research analysis to enhance, protect, and make informed decisions on the Services.&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="4" style="padding-left:1rem;margin-bottom:0in;margin-left:0in;">
                            <li id='Disclosure_of_Information' style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>DISCLOSURE OF INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We disclose non-Personal Information with third parties at our discretion. We do not sell or rent any Personal Information (including the Personal Information of consumers under 16 years of age) with third parties and have not done so in the past twelve (12) months. In connection with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>, we may disclose your Personal Information with certain third parties who we engage for business purposes to help us run our business and perform the Services, including under the following circumstances: &nbsp;&nbsp;</span></p>
                    <ul style="list-style-type: disc;margin-left:66.5px;">
                        <li><span style='font-family:"Times New Roman",serif;'>Software and service providers we use to manage and process your information, for example, hosting providers, analytics providers and payment processors.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Affiliates, subsidiaries and customers (for certain Services, this may include your health plan).</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Service or offering-related service providers, such as our vendors and instructors.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Human resources software and/or service providers we use to support Tivity&rsquo;s management of employees, contractors, and consultants (for example, a company utilized to assist with recruiting and evaluating potential job applicants, or a company whose services we use to manage timekeeping, payroll, and employee benefits).&nbsp;</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Analytics service providers, including Google Analytics, a service provided by Google, Inc., to gather information about how you and other users engage with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>. For more information about Google Analytics, please visit&nbsp;</span><a href="http://www.google.com/policies/privacy/partners"><span style=";">www.google.com/policies/privacy/partners</span></a><span style='font-family:"Times New Roman",serif;'>.&nbsp;</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Marketing service providers we may use to communicate with you.&nbsp;</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Advertising service providers.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Trusted partners that offer services that may be of interest to you.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>Other third parties with whom you expressly request us to disclose your Personal Information.</span></li>
                    </ul>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>In addition to performing our own analytics and providing content and advertisements on our Services and elsewhere online, we may work with third parties and other vendors who provide us with information and services relating to our advertising and analytics. These service providers may set and access their own tracking technologies on your device (including cookies and web beacons) and may otherwise collect or have access to information about you. Some of these parties may collect Personal Information over time when you visit the Services or other online websites and services. We may disclose Usage Information or other de-identified data about visitors with third-party advertising companies, analytics providers, and other vendors for analytics and advertising purposes. In some cases, we may customize messages to you based on information that we have about you. We do not, however, sell or use Personal Information about you for targeted advertising on third-party websites.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Additionally, we will disclose your Personal Information to third parties where required by law, where it is necessary in connection with our Services or products, or where we have another legitimate interest in doing so. If we are subject to a merger or acquisition with/by another company, we may provide information to them in connection with the transaction.&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="5" style="padding-left:1rem;margin-bottom:0in;list-style-type: decimal; padding: 0.5rem 1rem;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>HOW WE SAFEGUARD YOUR INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security. Any transmission of Personal Information is at your own risk.</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="6" style="padding-left:1rem;margin-bottom:0in;list-style-type: decimal; padding: 0.5rem 1rem;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>HOW LONG WE STORE YOUR INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We will retain your Personal Information for as long as is necessary for the legitimate business purposes set out in this Privacy Policy. We will retain and use your Personal Information to the extent necessary to comply with our legal, accounting, or reporting obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies. Following termination or deactivation of your account on the Services, we may retain your Personal Information for a reasonable time for archival purposes. Additionally, we may continue to store your Personal Information contained in our standard back-ups.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We also will retain Usage Information for internal analysis purposes. Usage Information is generally retained for a shorter period of time, except when Usage Information is used to strengthen the security or to improve the functionality of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;or products or we are legally obligated to retain Usage Information for longer periods.</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="7" style="padding-left:1rem;margin-bottom:0in;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;' id='Your_Rights_and_Choices_Regarding_Your_I'>YOUR RIGHTS AND CHOICES REGARDING YOUR INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-family:"Times New Roman",serif;'>Marketing Preferences</span></em><span style='font-family:"Times New Roman",serif;'>. You can opt out from receiving future marketing communications from us at any time by using the unsubscribe function in the email you receive from us or by contacting us as set forth in &ldquo;</span><a href="#How_to_Contact_Us"><strong><em><span style='font-family:"Times New Roman",serif;'>How to Contact Us</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo; above. Please allow sufficient time for your preferences to be processed. Even if you opt out of receiving marketing messages, we may still contact you for transactional purposes like confirming or following up on an order or service request. If you later opt back in to receive marketing communications from us, we will remove your information from our opt-out databases.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-family:"Times New Roman",serif;'>Personalized Advertising</span></em><span style='font-family:"Times New Roman",serif;'>. &nbsp;You may opt out of how companies unaffiliated with us use tracking tools and collect your information while visiting our websites by visiting each of their privacy policies and following instructions there. Generally, you can opt out of the collection of data across unaffiliated sites over time for interest-based advertising and other purposes from companies participating in the Digital Advertising Alliance (DAA) Consumer Choice Page at&nbsp;</span><a href="http://www.aboutads.info/choicesa"><span style='font-family:"Times New Roman",serif;'>www.aboutads.info/choices</span></a><span style='font-family:"Times New Roman",serif;'>&nbsp;and from members of the Network Advertising Initiative (NAI) at&nbsp;</span><a href="http://www.networkadvertising.org/choices"><span style='font-family:"Times New Roman",serif;'>www.networkadvertising.org/choices</span></a><span style='font-family:"Times New Roman",serif;'>. You also may opt out of receiving interest-based ads from Amazon, Facebook and Google. You will continue to see ads on each platform, but they will not be personalized as a result of your actions on our website. Please visit the links below if you wish to opt out or update your preferences:&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Amazon:&nbsp;</span><a href="https://www.amazon.com/adprefs"><span style='font-family:"Times New Roman",serif;'>https://www.amazon.com/adprefs</span></a></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Facebook:&nbsp;</span><a href="https://www.facebook.com/ads/preferences"><span style='font-family:"Times New Roman",serif;'>https://www.facebook.com/ads/preferences</span></a></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp; &nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Google AdWords:&nbsp;</span><a href="https://adssettings.google.com/authenticated?hl=en"><span style='font-family:"Times New Roman",serif;'>https://adssettings.google.com/authenticated?hl=en</span></a><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Please note that we and other companies may still collect information when you are online, and opting out through these mechanisms does not opt you out of being served advertising. You will continue to be served generic ads while online.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-family:"Times New Roman",serif;'>Geolocation</span></em><span style='font-family:"Times New Roman",serif;'>. The precise geographic location of your device may be transmitted to our servers in real time any time that our mobile applications are running (even if you are not actively using an app or it is minimized on your device). You may stop allowing us to have access to your device&rsquo;s location information by accessing the app&rsquo;s location settings on your device and setting your device not to provide its location with us.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-family:"Times New Roman",serif;'>Cookies</span></em><span style='font-family:"Times New Roman",serif;'>. Cookies are small pieces of information (data files) that are downloaded onto your computer or mobile device when you visit the Services. These technologies are able to store a unique identifier for a device to allow an online service provider to recognize the device whenever the device is used to visit the site. We and our third-party partners may use cookies to recognize your web browser when you visit the Services. As noted above in &ldquo;</span><a href="#Information_we_collect_and_how_we_use"><strong><em><span style='font-family:"Times New Roman",serif;'>Information We Collect And How We use Personal Information</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>,&rdquo; you can choose whether to accept cookies by changing the settings on your browser or device. However, if you choose to disable cookies, your experience with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;may be impaired and some features may not work as they were intended.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Additionally, <em>Do Not Track</em> is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable <em>Do Not Track</em> by visiting the preferences or settings page of your web browser. However, these features are not yet uniform, so we do not currently respond to such features or signals. Therefore, if you select or turn on a &ldquo;do not track&rdquo; feature in your web browser, we and our third-party providers may continue collecting information about your online activities as described in this Privacy Policy.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>You can choose to limit the data that third party services (e.g., social media platforms) disclose to us using the options provided to you by the applicable third party service (for example, the options provided by a third party social media platform when you connect your social media account with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>). &nbsp;You can also disconnect your use of our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;from the third-party service at any time using the options provided to you by the applicable third-party service. &nbsp;Please note, however, that if you disconnect from the third-party service, that will not delete the data we may have previously collected while you were connected.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p id='State_Specific_Rights' style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Depending on where you live, you may have certain rights with respect to Personal Information that we have collected and us &nbsp; &nbsp; &nbsp; &nbsp;ed under certain circumstances. Please see&nbsp;</span><strong><em><span style='font-family:"Times New Roman",serif;'>State Specific Rights</span></em></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;below for information about those rights and how to exercise them with respect to your Personal Information.&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Data associated with your interactions and activities through the Services may be &nbsp;</span> <span style='font-family:"Times New Roman",serif;'>deleted if you utilize a Tivity mobile application through Apple&rsquo;s App Store and request deletion of your account through the mobile application. After authentication and confirmation of the deletion request, your data related to the Services will be deleted. You will be informed of how long it will take to delete the account and provided confirmation of when deletion has been completed.&nbsp;</span></p>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="8" style="padding-left:1rem;margin-bottom:0in;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>TRANSFER OF DATA; SPECIAL NOTICE TO NON-U.S. USERS</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>The Services are intended solely for individuals located within the United States. If you are located outside of the United States and choose to provide information to us, please note that we transfer the data, including Personal Information, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. We will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-family:"Times New Roman",serif;'>If you are located in a country outside the United States, you should not submit Personal Information to us. If you choose to do so, you thereby consent to the general use and disclosure of such information as provided in this Privacy Policy and to the transfer to and/or storage of that information within the United States.&nbsp;</span></strong></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="9" style="padding-left:1rem;margin-bottom:0in;list-style-type: decimal; padding: 0.5rem 1rem;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>SENSITIVE PERSONAL INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We ask that you not send us, and you not disclose, any sensitive personal information as this term is defined under applicable data protection and privacy laws (for example, social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, precise geolocation, criminal background or trade union membership) on or through the&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;or otherwise to us.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:.5in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>If you send or disclose any sensitive personal information to us (e.g., for employment-related purposes), you consent to our processing and use of such sensitive personal data in accordance with this Privacy Policy. If you do not consent to our processing and use of such sensitive personal information, you must not submit such content to our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>.</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="10" style="padding-left:1.3rem;margin-bottom:0in;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>POLICY ON CHILDREN&rsquo;S INFORMATION</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Our Services are not created for or intended for use by anyone under the age of 13 (&ldquo;<u>Children</u>&rdquo; or &ldquo;<u>Child</u>&rdquo;). By using the Services, you represent that you are at least 13 years old. &nbsp;If you do not meet this age requirement, then you must not access or use our Services.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>We do not knowingly collect personally identifiable information from anyone under the age of 14, and we do not target the Services to Children. If you are a parent or guardian and you are aware that your Child has provided us with Personal Information, please contact us though one of the methods listed under &ldquo;</span><a href="#How_to_Contact_Us"><strong><em><span style='font-family:"Times New Roman",serif;'>How to Contact Us</span></em></strong></a><span style='font-family:"Times New Roman",serif;'>&rdquo;, above. If we become aware that we have collected Personal Information from Children without verification of parental consent, we will take reasonable steps to remove that information from our servers.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>For more information about the Children&rsquo;s Online Privacy Protection Act (&ldquo;<u>COPPA</u>&rdquo;), which applies to websites that direct their services to children under the age of thirteen (13), please visit the Federal Trade Commission&rsquo;s website&nbsp;</span><a href="https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions"><span style='font-family:"Times New Roman",serif;'>https://www.ftc.gov/tips-advice/business-center/guidance/complying-coppa-frequently-asked-questions</span></a><span style='font-family:"Times New Roman",serif;'>.&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="11" style="padding-left:1.4rem;margin-bottom:0in;list-style-type: decimal;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>LINKS TO OTHER SITES</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Our Services may contain links to third-party websites, content, products, and services (&ldquo;<u>Linked Sites</u>&rdquo;). If you click on one of those links, you will be taken to websites we do not control. This Privacy Policy does not apply to the information practices of third parties that we do not own or control including but not limited to the Linked Sites. You understand that these Linked Sites are not under our control and are not subject to our Privacy Policy, and you should exercise caution when deciding to disclose any Personal Information through those Linked Sites. You should read the privacy policies of third-party websites carefully. We are not responsible for the content, privacy policies, actions or security of third-party websites including the Linked Sites, and you acknowledge that your use and access of these Linked Sites is solely at your own risk.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Times New Roman",serif;border:none windowtext 1.0pt;padding:0in;'>CALIFORNIA AND VIRGINIA PRIVACY NOTICE</span></u></strong></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-family:"Times New Roman",serif;border:none windowtext 1.0pt;padding:0in;'><span style="text-decoration: none;">&nbsp;</span></span></u></strong></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Depending on where you live in the United States, you may have certain rights with respect to Personal Information that we have collected and used under certain circumstances. This Privacy Notice provides additional information to California residents and Virginia residents whose Personal Information is processed by Tivity, pursuant to the California Consumer Privacy Act, as amended by the California Privacy Rights Act (&ldquo;<u>CCPA</u>&rdquo;), and the Virginia Consumer Data Protection Act (&ldquo;<u>VaCDPA</u>&rdquo;), respectively. &nbsp;</span></p>
                    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="1" style="margin-bottom:0in;list-style-type: upper-alpha;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>CATEGORIES OF PERSONAL INFORMATION SOLD, TRANSFERRED AND SHARED</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Please see our Privacy Policy for information about our information collection practices. We generally do not &ldquo;sell&rdquo; or make available Personal Information for cross-context behavioral advertising (which is called &ldquo;sharing&rdquo; under the CCPA) for purposes of the CCPA and the VaCDPA. As set forth in the Privacy Policy, we disclose Personal Information to third parties for business purposes, including without limitation, for purposes of marketing, and other services performed on our behalf, to protect against fraud and malicious activity, and for other business purposes described in the Policy. &nbsp;During the preceding twelve (12) months, we have disclosed the categories of personal information for business purposes as set forth in &ldquo;</span><a href="#Disclosure_of_Information"><strong><em><span style='font-family:"Times New Roman",serif;'>Disclosure of Information</span></em></strong></a><strong><em><span style='font-family:"Times New Roman",serif;'>&rdquo;</span></em></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;section of the Policy. &nbsp;In addition, your information may be accessible to third parties with whom you interact or direct us to provide your information through our Services.</span></p>
                    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <div style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
                        <ol start="2" style="margin-bottom:0in;list-style-type: upper-alpha;margin-left:0in;">
                            <li style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-family:"Times New Roman",serif;'>YOUR CHOICES</span></li>
                        </ol>
                    </div>
                    <p style='margin-top:6.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>You may have certain rights with respect to Personal Information that we have collected and used under certain circumstances, including the below. However, these rights are not absolute, and in certain cases we may decline your request as permitted by law. &nbsp;</span></p>
                    <ul style="list-style-type: disc;margin-left:0in;">
                        <li><strong><em><span style='font-family:"Times New Roman",serif;'>Information.&nbsp;</span></em></strong><span style='font-family:"Times New Roman",serif;'>You can request that we provide you the<strong>&nbsp;</strong>following information regarding our use of your Personal Information in the past 12 months:</span>
                            <ol style="list-style-type: circle;">
                                <li><span style='font-family:"Times New Roman",serif;'>The categories of your Personal Information that we have collected</span></li>
                                <li><span style='font-family:"Times New Roman",serif;'>The purposes for which we have collected or used such Personal Information</span></li>
                                <li><span style='font-family:"Times New Roman",serif;'>The categories of sources for the Personal Information we have collected about you</span></li>
                                <li><span style='font-family:"Times New Roman",serif;'>The categories of third parties with whom we have disclosed your Personal Information&nbsp;</span></li>
                                <li><span style='font-family:"Times New Roman",serif;'>If we have disclosed your Personal Information for a business purpose, a list identifying the Personal Information categories that each category of recipient obtained</span></li>
                                <li><span style='font-family:"Times New Roman",serif;'>If we have sold your Personal Information, a list identifying the Personal Information categories that each category of recipient purchased</span></li>
                            </ol>
                        </li>
                        <li><strong><em><span style='font-family:"Times New Roman",serif;'>Access.</span></em></strong><strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;'>You can request that we provide you a copy of your Personal Information that we have collected in the past 12 months.</span></li>
                        <li><strong><em><span style='font-family:"Times New Roman",serif;'>Correction.</span></em></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;You can request that we correct any inaccuracies in your Personal Information.</span></li>
                        <li><strong><em><span style='font-family:"Times New Roman",serif;'>Deletion.</span></em></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;</span><span style='font-family:"Times New Roman",serif;'>You can request that we delete your Personal Information collected in the past 12 months when we no longer need such data in connection with our&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Services</span><span style='font-family:"Times New Roman",serif;'>&nbsp;(subject to certain exceptions that may apply under applicable law).</span></li>
                        <li><strong><em><span style='font-family:"Times New Roman",serif;'>Opt Out of Sale or Sharing.</span></em></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;Under applicable law in California and Virginia, you have the right to opt out of the sale or the disclosing of your Personal Information for purposes of cross-context behavioral advertising (&ldquo;sharing&rdquo;). For clarity, however, Tivity does not &ldquo;sell&rdquo; or &ldquo;share&rdquo; Personal Information for purposes of the CCPA and VaCDPA.&nbsp;</span></li>
                    </ul>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:.25in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>* If you are a resident of a jurisdiction where one or more of these rights are granted to you under applicable law, and you wish to exercise any such rights listed above, please contact us by either:&nbsp;</span></p>
                    <p style='margin-top:12.0pt;margin-right:0in;margin-bottom:8.0pt;margin-left:39.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.25in;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Web:&nbsp;</span><a href="https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66"><span style='font-family:"Times New Roman",serif;'>Your Privacy Choices</span></a><span style='font-family:"Times New Roman",serif;'>&nbsp;&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:20.9pt;'><span style="font-family:Symbol;">&middot;</span><span style='font-family:"Times New Roman",serif;'>&nbsp;&nbsp;</span><span style='font-family:"Times New Roman",serif;'>Phone:&nbsp;<strong>1-877-614-3111</strong></span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:20.9pt;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your Personal Information. Please understand that we are required to verify your request and may require you to provide some information to enable us to carry out such verification. We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm the Personal Information relates to you.</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>When you, or someone legally authorized to act on your behalf, make a consumer request related to your Personal Information, you can expect the following:&nbsp;</span></p>
                    <ol start="1" style="list-style-type: lower-alpha;">
                        <li><span style='font-family:"Times New Roman",serif;'>We will verify your identity. You will need to provide us with certain information so that we can verify that you are who you say you are. Which information may depend on the type and sensitivity of information subject to the request.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>We will confirm our receipt of your request within 10 days. If you have not received a response within a few days after that, please let us know by contacting us at the webpage or phone number listed above.&nbsp;</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>We will respond to your request within the legally required amount of time. If necessary, we may need additional time to respond where allowed by law, but we will reply either way within the legally required period and, if we need an extension, we will explain why.</span></li>
                        <li><span style='font-family:"Times New Roman",serif;'>In certain cases, a request may be denied. For example, if we cannot verify your identity or if providing you information could create an unreasonable risk to someone&rsquo;s security (for example, we do not want very sensitive information disclosed inappropriately). If we deny your request, we will explain why we denied it.</span></li>
                    </ol>
                    <p style='margin-top:0in;margin-right:0in;margin-bottom:15.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>Making a verifiable consumer request does not require you to create an account with us. We will only use Personal Information provided in a verifiable consumer request to verify the requestor&apos;s identity or authority to make the request.</span></p>
                    <p style='margin-top:24.0pt;margin-right:0in;margin-bottom:0in;margin-left:0in;background:white;font-size:11px;font-family:"Times New Roman",serif;'>&nbsp;</p>
                    `}}
                >
                </div>
            </section>
        </Layout>
    );
};

export default PrivacyPolicy;