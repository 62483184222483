import React, { useEffect } from 'react';
import ProductTypeId from '../../models/productTypeId';
import styles from './Filter.module.scss';

const FilterSummary = ({
    productTypeId,
    searchAddress, 
    categoryFilterString
}) => {
    if(productTypeId === ProductTypeId.FACILITY)
        return (
            <span className={styles.filterSummary}>
                Now showing results for <span className={styles.filterSummaryItem}>Fitness & Wellness Centers</span>
            </span>
        );
    return (
        <span className={styles.filterSummary}>
         Now showing results for 
            <span className={styles.filterSummaryItem}>
                {` ${categoryFilterString}`} 
            </span>
            {productTypeId === ProductTypeId.PROVIDER  ?
                <span className={styles.filterSummaryItem}>
                    {!searchAddress ? '' : ` in ${searchAddress}`}
                </span> :  
                <span className={styles.filterSummaryItem}>
                    {` in Products & Services`}
                </span>
            }
        </span>
    );
};

export default FilterSummary;