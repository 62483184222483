/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { useChoicesContext } from '../context/ChoicesContext';
import { Loader } from '@googlemaps/js-api-loader';
import EnvironmentService from "../services/environment.service";

const referralUrlZipCodeMap = {
    [EnvironmentService.getConfigurationVariable("BCBS_AZ_REFERRAL_URL")?.toLowerCase()]: '85004',
    [EnvironmentService.getConfigurationVariable("BCBS_TN_REFERRAL_URL")?.toLowerCase()]: '37211',
    [EnvironmentService.getConfigurationVariable("BCBS_FL_REFERRAL_URL")?.toLowerCase()]: '32202'
};

export const defaultZipCode =
    referralUrlZipCodeMap[window.sessionStorage['choices-referring-domainUrl']?.toLowerCase()] || '29607';

const googleLoader = new Loader({
    apiKey: EnvironmentService.getConfigurationVariable("GOOGLE_JS_API_KEY"),
    version: "weekly",
    libraries: ["places","geometry"]
});

export const usePosition = () => {
    const { latitude, longitude, searchAddress, setSearchAddress, setSearchCoordinates } = useChoicesContext();
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const onChange = async ({coords}) => {
        setIsSuccess(false);
        setSearchCoordinates(coords.latitude,coords.longitude);
        await reverseGeocode(coords, setSearchAddress, isError).catch(err => err);
        return setIsSuccess(true);
    };

    const onError = (error) => {
        console.error(error);
        window.localStorage['choices-search-address'] = defaultZipCode;
        setIsError(true);
    };

    useEffect(() => {
        const geo = navigator.geolocation;
        if (!geo) {
            setSearchCoordinates(0,0);
            setIsError(true);
            return;
        }
        let watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }, []);

    return { latitude, longitude, searchAddress, setSearchAddress, setSearchCoordinates, isError, isSuccess };
};

export const geocode = async (
    searchAddress,
    setRestrictedState,
    setSearchCoordinates,
) => {
    let locationResults;
    try {
        if(typeof window === 'undefined' || !searchAddress)
            return;

        const google = await googleLoader.load();
        const geocoder = new google.maps.Geocoder();

        await geocoder.geocode({address:searchAddress}, (results,status)=> {
            if(status == "OK") {
                setSearchCoordinates(results[0].geometry.location.lat(),results[0].geometry.location.lng());
                setRestrictedState(isRestrictedState(results[0].address_components));
                locationResults = results[0];
            }
            else {
                setSearchCoordinates(0,0);
                setRestrictedState(false);
            }
        }).catch(err => err);
        return locationResults;
    }
    catch(error) {
        setSearchCoordinates(0,0);
    }
};

export const reverseGeocode = async (coords, setSearchAddress) => {

    try {
        if(typeof window === 'undefined') throw new Error('Geocoder not available');
        if(geolocationIsCached()) return;

        const google = await googleLoader.load();
        const geocoder = new google.maps.Geocoder();

        let googleCoordinates = {
            lat: coords.latitude,
            lng: coords.longitude,
        };

        await geocoder.geocode({location:googleCoordinates}, (results,status)=> {
            if(status == "OK") {
                const locality = results[0].address_components.filter((component)=> component['types'][0]
                    === "locality");
                const state = results[0].address_components.filter((component)=> component['types'][0]
                    === "administrative_area_level_1");
                setSearchAddress(`${locality[0].long_name}, ${state[0].short_name}`);
            }
            else {
                setSearchAddress(defaultZipCode);
                window.sessionStorage['choices-search-address'] = defaultZipCode;
            }
        }).catch(err => err);
    }
    catch {
        setSearchAddress(localStorage.getItem('choices-search-address') || '');
    }
};

export const isRestrictedState = (addressComponentsArray) => {
    let isRestrictedState = false;
    const restrictedStateLongNames = ["California"];
    const restrictedStateShortNames = ["CA"];

    if(!addressComponentsArray) return isRestrictedState;

    addressComponentsArray.map(component => {
        if(
            (component.long_name && restrictedStateLongNames.includes(component.long_name))
            || (component.short_name && restrictedStateShortNames.includes(component.short_name))
        ) isRestrictedState = true;
    });

    return isRestrictedState;
};

export const geolocationIsCached = () => {
    return localStorage.getItem("choices-search-address") !== null &&
            localStorage.getItem("choices-latitude") !== null &&
            localStorage.getItem("choices-longitude") !== null &&
            localStorage.getItem("choices-latitude") !== "0" &&
            localStorage.getItem("choices-longitude") !== "0";
};
