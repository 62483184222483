import React from 'react';
import { NavLink } from "react-router-dom";
import styles from './Navbar.module.scss';

const NavbarLink = ({onClick, pathTo, displayText}) => {
    const handleClick = () => {
        // Link still responds if user attempts to navigate to same page.
        onClick();
        if(pathTo === window.location.pathname) window.location.reload();
    };
    return (
        <li className={styles.navLink}>
            <NavLink
                onClick={handleClick} 
                className={({ isActive }) => isActive ? styles.activeLink : undefined} to={pathTo} 
            >
                <span className={styles.linkText}>{displayText}</span>
            </NavLink>
        </li>
    );
};

export default NavbarLink;