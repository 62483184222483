/* eslint-disable max-len */
import React, { useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import styles from './SubscriptionPrompt.module.scss';
import Logo from '../../components/logo/Logo';
import ActionButton from '../../components/action-button/ActionButton';
import EnvironmentService from '../../services/environment.service';
import { useCreateCheckoutSessionMutation } from '../../services/react-query/choices.service';
import SubscriptionAgreement from '../subscription-agreement/SubscriptionAgreement';
import Loading from '../../components/loading/Loading';

const SubscriptionPrompt = () => {

    const [name, setName] = useState("");
    const { clientUrl } = useParams();
    const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
    const userEmail = localStorage.getItem("choices-email");

    const [isSubscriptionAgreementOpen, setIsSubscriptionAgreementOpen] = useState(false);

    const {
        mutateAsync: createCheckoutSession,
        isLoading: isCreatingCheckoutSession,
    } = useCreateCheckoutSessionMutation();

    const handleSubscribe = async (event) => {
        event.preventDefault();
        setIsSubscriptionAgreementOpen(!isSubscriptionAgreementOpen);

        isSubscriptionAgreementOpen ?
            document.body.classList.remove(styles["disable-scrolling"]) :
            document.body.classList.add(styles["disable-scrolling"]);
    };

    const handleCreateSubscripition = async (event) => {
        event.preventDefault();
        try {
            const url = await createCheckoutSession(userEmail);
            const urlValidation = new URL(url);

            window.location.href = urlValidation;

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className={styles.subscribePromptPage}>
            <section className={styles.contentContainer}>
                <div>
                    <h1 className={styles.title}>Start Saving on Your Well-Being</h1>
                </div>
                <section className={styles.subscriptionBody}>
                    <div>
                        <p className={styles.subTitle}>Become a Choices by WholeHealth Living Member Today</p>
                        <p className={styles.priceItem}>
                            <span className={styles.pricePerYear}>$9.99</span>
                            <span className={styles.pricePeriod}>/year</span>
                        </p>

                        <div className={styles.buttonContainer}>
                            <div className={styles.btnContainerSubscribe}>

                                <ActionButton
                                    type="submit"
                                    onClick={(event) => handleSubscribe(event)}>
                                    Subscribe
                                </ActionButton>

                            </div>
                        </div>

                        <div className={styles.planDetails}>
                            {name}
                            <p><strong>Your membership includes:</strong></p>
                            <ul className={styles.planBullets}>
                                <li>Savings up to 50% on national health & wellness brands</li>
                                <li>Access to 16,500+ integrative health practitioners nationwide</li>
                            </ul>
                            <p><em>Get support for your health goals at a reduced cost. Live life well. Now.</em></p>
                            <p style={{ fontWeight: '400' }}>If you run into technical issues with subscription, please email
                                <span 
                                    style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue', marginLeft: '0.5em' }}
                                    onClick={() => window.location.href = `mailto:${'choicesbyWHL@tivityhealth.com'}`}
                                >
                                        choicesbyWHL@tivityhealth.com          
                                </span> 
                            </p> 
                            
                        </div>
                    </div>

                    <SubscriptionAgreement
                        show={isSubscriptionAgreementOpen}
                        handleContinueBtn={(event) => handleCreateSubscripition(event)}
                    />

                </section>
            </section>

            <section className={styles.subscribeDisclousreContainer}>
                <div className={styles.subscribeDisclousre}>
                    <p>
                        {`IMPORTANT DISCLOSURES: AS PART OF THE CHOICES BY WHOLEHEALTH LIVING PROGRAM (THE “CHOICES PROGRAM”), 
                    MEMBERS ARE PROVIDED WITH ACCESS TO A NETWORK OF PARTICIPATING THIRD-PARTY PRACTITIONERS WHO OFFER DISCOUNTED 
                    INTEGRATIVE HEALTH SERVICES INCLUDING, BUT NOT LIMITED TO, CHIROPRACTIC, ACUPUNCTURE, MASSAGE/BODY WORK SERVICES 
                    (“INTEGRATIVE HEALTH SERVICES”). THE CHOICES PROGRAM IS NOT INSURANCE. DISCOUNTS VARY PER PRACTITIONER. THE CHOICES 
                    PROGRAM MEMBER MUST PAY FOR INTEGRATIVE HEALTH SERVICES, BUT WILL RECEIVE A DISCOUNT FROM THE PRACTITIONER. THE 
                    NETWORK FOR THE INTEGRATIVE HEALTH SERVICES PRACTITIONERS IS NOT CURRENTLY AVAILABLE TO RESIDENTS OF CALIFORNIA, 
                    CONNECTICUT, DELAWARE, INDIANA, LOUISIANA, MONTANA, NEBRASKA, NEW HAMPSHIRE, NEVADA, OKLAHOMA, OREGON, RHODE ISLAND, 
                    SOUTH DAKOTA, UTAH, WASHINGTON OR WEST VIRGINIA. PLEASE VISIT WWW.WHLCHOICES.COM FOR A COMPLETE LIST OF PRACTITIONERS 
                    AVAILABLE IN MEMBER&#39;S STATE OF RESIDENCE. THE CHOICES PROGRAM IS PROVIDED BY WHOLEHEALTH LIVING, INC., WITH A 
                    MAILING ADDRESS OF: ATTN: WHOLE HEALTH LIVING, 1445 S. SPECTRUM BLVD, CHANDLER, ARIZONA 85286.`}
                    </p>
                </div>
            </section>
            <Logo />
            <Loading
                isFullScreen
                isLoading={isCreatingCheckoutSession}
            />
        </div>
    );
};

export default withAuthenticationRequired(SubscriptionPrompt, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
