/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Layout from '../../components/layout/Layout';
import Styles from './SubscriptionSuccess.module.scss';
import ActionButton from '../../components/action-button/ActionButton';
import Logo from '../../components/logo/Logo';
import Loading from '../../components/loading/Loading';
import {
    useGetUserSubscriptionDetailsQuery,
    useIsSubscribedMutation
} from '../../services/react-query/choices.service';
import ChoicesCheck from '../../assets/images/choices-check.PNG';


const SubscriptionSuccess = () => {
    const [checkoutSessionId, setCheckoutSessionId] = useState("");
    const { data, error, isLoading } =
        useGetUserSubscriptionDetailsQuery(localStorage.getItem("choices-email"));

    const {
        mutateAsync: createIsSubscribedRequest,
        isLoading: IsCheckingSubscriptionRequest
    } = useIsSubscribedMutation();

    useEffect(() => {
        const fetchData = async () => {
            if (!isLoading && data) {
                setCheckoutSessionId(data?.checkoutSessionId);
            }

            if (checkoutSessionId) {
                try {
                    const accessToken = localStorage.getItem("choices-token");
                    const requestBody = {
                        sessionId: checkoutSessionId
                    };

                    const response = await createIsSubscribedRequest(requestBody, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }).catch(err => err);

                    const successfulSubscription = await response.status;
                } catch (err) {
                    console.log(err);
                }
            }
        };

        fetchData();
    }, [data, checkoutSessionId, createIsSubscribedRequest, isLoading]);

    const handleSubmit = (event) => {
        event.preventDefault();
        window.location.href = "/";
    };

    const currentDate = new Date().toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    });

    return (
        <Layout pageTitle={'Subscription Success'}>
            <section className={Styles.detailsContainer}>

                <div className={Styles.topContentContainer}>
                    <div className={Styles.imageContainer}>
                        <img src={ChoicesCheck} />
                    </div>
                    <p>Congratulations, you have successfully subscribed to Choices by WholeHealth Living!</p>
                </div>

                <div className={Styles.twoSquareAccessContainer}>
                    <div className={Styles.accessDetailsTopLeft}>
                        <p className={Styles.accessTitle}><strong>You now have access to:</strong></p>
                        <ul className={Styles.accessBullets}>
                            <li><span>Savings up to 50% on national health & wellness brands</span></li>
                            <li><span>Access to 16,500+ integrative health practitioners nationwide</span></li>
                        </ul>
                    </div>
                    <div className={Styles.accessDetailsContainerTopRight}>
                        <p className={Styles.purchaseDetailsTitle}>PURCHASE DETAILS</p>
                        <table className={Styles.purchaseDetails}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Date</th>
                                    <th className={Styles.purcahseCost}>Order Total USD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Annual Subscription</td>
                                    <td>{currentDate}</td>
                                    <td className={Styles.purcahseCost}>9.99</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={Styles.btnGotoChoices}>
                    <ActionButton
                        type="submit"
                        onClick={(event) => handleSubmit(event)}>
                        EXPLORE DISCOUNTS
                    </ActionButton>
                    
                </div>
                <Loading
                    isLoading={isLoading || IsCheckingSubscriptionRequest}
                />
            </section>
            <Logo />
        </Layout>
    );
};

export default withAuthenticationRequired(SubscriptionSuccess, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
