import React, { useMemo, useState } from "react";
import styles from "./FitnessCenterCard.module.scss";
import ActionButton from '../../components/action-button/ActionButton';
import { useNavigate } from "react-router-dom";
import AnalyticsService from "../../services/analytics.service";

const FitnessCenterCard = ({fitnessCenter, isRecentActivity}) => {
    const [fitnessCenterName, setFitnessCenterName] = useState(fitnessCenter.name);
    const navigate = useNavigate();

    const handleClick = () => {
        AnalyticsService.trackOfferView(fitnessCenter);
        navigate(`/fitness-centers/${fitnessCenter.id}`);
    };

    const removeSubstringAfterString = (targetString, name) => 
        name.substring(0, Math.min(name.length, name.indexOf(targetString)));

    useMemo(()=> {
        // Ensure fitness center name fits in the card header by removing extraneous name strings
        let lengthSpecs = {min:5, max: 28};
        let addressCouplings = ["-",",","/"," at", "@"];

        if(fitnessCenterName.length > lengthSpecs.max) {
            let maxLengthName = fitnessCenterName.substring(0, lengthSpecs.max);
            let trimmedName = 
                maxLengthName.substring(0, Math.min(maxLengthName.length, maxLengthName.lastIndexOf(" ")));
            
            addressCouplings.forEach(coupling => {
                if(trimmedName.indexOf(coupling) > lengthSpecs.min) 
                    trimmedName = removeSubstringAfterString(coupling, trimmedName);
            });
            
            if(trimmedName.trim().indexOf(" and") >= trimmedName.length - 4) 
                trimmedName = trimmedName.replace(" and","");
            
            return setFitnessCenterName(trimmedName);
        }
    },[fitnessCenterName]);

    return (
        <article className={styles.cardContainer}>
            <header className={styles.cardHeader}>{fitnessCenterName}</header>
            <section className={styles.cardBody}>
                <div className={styles.distance}>
                    <img src="/images/locationIcon.svg" alt="Map Marker Icon" />
                    {isRecentActivity ? 
                        <span className={styles.distanceText}>
                            {`${fitnessCenter.city}, ${fitnessCenter.state}`}
                        </span> :
                        <span className={styles.distanceText}>
                            {fitnessCenter.distance  === 1 ? 
                                `${fitnessCenter.distance} Mile Away` : 
                                fitnessCenter.distance  < 1 ? 
                                    "Less Than One Mile" : `${fitnessCenter.distance} Miles Away`
                            }
                        </span> }
                </div> 
                {fitnessCenter.discount > 0 && fitnessCenter.discount < 100 ? 
                    <div className={styles.discountPercentage}>
                        {fitnessCenter.discount}%<sup><small>OFF</small></sup>
                    </div>
                    : 
                    <div className={styles.discountDisclaimer}>
                        See Offer Details
                    </div>}
                <div className={styles.address}>
                    <p>
                        {fitnessCenter.addressLine1.length > 24 ? 
                            fitnessCenter.addressLine1.substring(0,21) + ".." : fitnessCenter.addressLine1}
                    </p>
                    <p>
                        {fitnessCenter.city}, {fitnessCenter.state} {fitnessCenter.zipCode.substring(0,5)}
                    </p>
                </div>
                <div className={styles.btnContainer}>
                    <ActionButton
                        onClick={handleClick}
                        btnType="button"
                    >View Details</ActionButton>
                </div>
            </section>
        </article>
    );
};

export default FitnessCenterCard;
