import styles from "./NavigationColumn.module.scss";
import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from "react-router-dom";
import Toast from "../toast/Toast";
import Loading from "../loading/Loading";
import accountIcon from '../../assets/images/account.svg';
import ActionButton from "../action-button/ActionButton";
import { useChoicesContext } from "../../context/ChoicesContext";
import { ReactComponent as LeftArrow } from "../../assets/icons/icon-arrowLeft.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/icon-arrowRight.svg";
import EnvironmentService from "../../services/environment.service";

const NavigationColumn = ({ environment }) => {
    const { firstName, lastName } = useChoicesContext();
    const [toasts, setToasts] = useState([]);
    const [columnMenu, setColumnMenu] = useState(true);
    const [ecommerceMember, setEcommerceMember] = useState();
    const { logout, isLoggingOut } = useAuth0();

    useEffect(() => {
        const clientName = localStorage.getItem('choices-client-name');
        EnvironmentService
            .getConfigurationVariable("ECOMMERCE_CLIENT_NAMES")
            .indexOf(clientName) > -1 ? setEcommerceMember(true) : setEcommerceMember(false);
    }, []);

    const handleTab = () => {
        setColumnMenu(!columnMenu);
    };

    const openCloseMenu = columnMenu
        ? styles.columnContainerOpen
        : styles.columnContainerClosed;

    const [tabStyles, setTabStyles] = useState({
        position: "fixed",
        left: "220px",
        bottom: `4%`
    });

    const columnRef = useRef(null);
    const tabRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const column = columnRef.current;
            const tab = tabRef.current;

            const columnRect = column.getBoundingClientRect();
            const tabRect = tab.getBoundingClientRect();

            const columnBottom = window.innerHeight - columnRect.bottom;
            const tabHeight = tabRect.height;

            if (tabHeight - columnBottom < 50) {
                setTabStyles((prevStyles) => ({
                    ...prevStyles,
                    position: "absolute",
                    bottom: `4%`,
                }));
            } else {
                setTabStyles((prevStyles) => ({
                    ...prevStyles,
                    position: "fixed",
                    bottom: "4%",
                }));
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [columnRef, tabRef, columnMenu]);

    const handleLogout = async () => {
        try {
            logout({ returnTo: window.location.origin });
        } catch (err) {
            console.error('Logout Error:', err);
        }
    };

    return (
        <div
            className={`${styles.columnContainer} ${openCloseMenu}`}
            onClick={handleTab}
            ref={columnRef}
        >
            <div className={styles.columnOverflow}>
                <div className={styles.columnHeaderContainer}>
                    <h2 className={styles.columnHeader}>My Account</h2>
                </div>

                <div className={styles.columnAccountName}>
                    {firstName} {lastName}
                </div>
                <div className={styles.columnImgContainer}>
                    <img src={accountIcon} alt="SVG illustration of an ID card." loading="eager" />
                </div>

                <div className={styles.columnNavContainer}>
                    <nav className={styles.columnNav}>
                        <ul>
                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/account"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Edit Profile
                                </NavLink>
                            </li>
                            {ecommerceMember &&
                                <li className={styles.columnNavLink}>
                                    <NavLink
                                        to="/manage-plan"
                                        className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                    >
                                        Manage Plan
                                    </NavLink>
                                </li>
                            }

                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/recent-activity"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Recent Activity
                                </NavLink>
                            </li>
                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/nominate-practitioner"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Nominate A Practitioner
                                </NavLink>
                            </li>
                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Contact
                                </NavLink>
                            </li>
                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/terms-of-use"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Terms & Conditions
                                </NavLink>
                            </li>
                            <li className={styles.columnNavLink}>
                                <NavLink
                                    to="/privacy-policy"
                                    className={({ isActive }) => isActive ? styles.activeColumnLink : undefined}
                                >
                                    Privacy Policy
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div id="logout" className={styles.btnContainer}>
                    <ActionButton onClick={async () => await handleLogout()}>Log Out</ActionButton>
                </div>

                <Loading isFullScreen isLoading={isLoggingOut} />
                <Toast messages={[...toasts]} />

                <div
                    className={styles.columnTabContainer}
                    ref={tabRef}
                    style={tabStyles}
                >
                    <div className={styles.columnTab}>
                        {columnMenu ? <LeftArrow /> : <RightArrow />}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default NavigationColumn;
