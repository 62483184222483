/* eslint-disable max-len */
/* eslint-disable indent */
import React from 'react';
import { Link } from 'react-router-dom';
import VisibilityFilter from '../client-filter/VisibilityFilter';
import OAuthService from '../../services/oauth.service';
import AuthorizedFooterLinks from './AuthorizedFooterLinks';
import styles from './Footer.module.scss';
import EnvironmentService from "../../services/environment.service";

const Footer = () => {    
    const referingUrlBSBC = EnvironmentService.getConfigurationVariable("ECOMMERCE_CLIENT_URLS").indexOf(window.sessionStorage['choices-referring-domainUrl']?.toLowerCase()) > -1 ? true : false;
    return (
        <footer data-testid="footer" className={styles.footer}>
            <section className={styles.optionsContainer}>
                <div className={`${styles.option} ${styles.leftOption}`}>
                    <span className={styles.optionTitle}>About</span>
                    <div className={styles.optionItems}>
                        <a className={styles.navLink} href="mailto:choicesbyWHL@tivityhealth.com">Email Us</a>
                    </div>
                </div>
                <div className={`${styles.option} ${styles.middleOption}`}>
                    <span className={styles.optionTitle}>Navigation</span>
                    <div className={styles.optionItems}>
                        <AuthorizedFooterLinks 
                            authorizedLinks={[
                                <Link to="/products" className={styles.navLink}>Products & Services</Link>,
                                /* 
                                    Use visibility filter to hide provider and facility search 
                                    from SilverSneakers members.
                                */
                                <VisibilityFilter>
                                    <Link to="/practitioners" className={styles.navLink}>Practitioners</Link>
                                </VisibilityFilter>,
                                referingUrlBSBC ? <></> :
                                <VisibilityFilter>
                                    <Link to="/fitness-centers" 
                                        className={styles.navLink}>Fitness & Wellness Centers</Link>
                                </VisibilityFilter>,
                                <Link to="/nominate-practitioner" 
                                    className={styles.navLink}>Nominate a Practitioner</Link>
                            ]}
                            unauthorizedLinks={[
                                <Link to="/login" className={styles.navLink}>Login</Link>,
                                OAuthService.verifyReferralSource() ?  
                                    <Link to="/register" className={styles.navLink}>Register</Link> : null
                            ]}
                        />
                    </div>
                </div>      
                <div className={`${styles.option} ${styles.rightOption}`}>
                    <div className={styles.optionItems}>
                        <span className={styles.optionTitle}>Support</span>
                        <AuthorizedFooterLinks 
                            authorizedLinks={[
                                <Link to="/contact" className={styles.navLink} >Contact Us</Link>,
                                <Link to="/account" className={styles.navLink} >My Account</Link>,
                                <Link to="/terms-of-use" className={styles.navLink} >Terms of Use</Link>,
                                <Link to="/privacy-policy" className={styles.navLink} >Privacy Policy</Link>,
                                <Link to="/privacy-policy#information_collection" className={styles.navLink} >California Notice at Collection</Link>,
                            ]}
                            unauthorizedLinks={[
                                <Link to="/terms-of-use" className={styles.navLink} >Terms of Use</Link>,
                                <Link to="/privacy-policy" className={styles.navLink} >Privacy Policy</Link>,
                                <Link to="/privacy-policy#information_collection" className={styles.navLink} >
                                    <div style={{width:"200px"}}>California Notice at Collection</div>
                                </Link>,
                            ]}
                        />
                    </div>
                </div>
            </section>
            <section className={styles.bottomBanner}>
                <div className={styles.bottomContainer}>
                    <p>
                    WholeHealth Living, Inc. is a Tivity Health company. 
                    WholeHealth Living is a registered trademark of Tivity Health, Inc. 
                    © {new Date().getFullYear()} Tivity Health Inc. All rights reserved.
                    </p>
                    <p>
                    Choices by WholeHealth Living is not a covered insurance benefit. 
                    It is a discount program offered at no additional cost by leading insurance companies. 
                    Certain products and services offered through WholeHealth Living, Inc. may be eligible 
                    for payment or reimbursement through a Health Savings Account (HSA) or a Flexible 
                    Spending Account (FSA). Please check with your HSA or FSA administrator 
                    to determine if the products or services you are interested in purchasing are 
                    IRS-qualified medical expenses. Any information provided here is not intended 
                    to serve as legal or tax advice. For a complete list of IRS-qualified 
                    medical expenses, visit <a target="_blank" href="https://www.irs.gov/">www.irs.gov</a>. 
                    </p>
                    <a 
                        href="https://submit-irm.trustarc.com/services/validation/55050fa3-172f-4f69-be04-985bf0513e66"
                        target="_blank"
                    >
                    Your Privacy Choices
                    </a>
                </div>
            </section>
        </footer>
    );
};

export default Footer;