import React from 'react';
import Layout from '../../components/layout/Layout';
import RegisterForm from '../../components/register-form/RegisterForm';
import BasePage from '../base/BasePage';

const Register = () => {
    const handleClick = () => {
        document.getElementById('form').scrollIntoView({ 
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    };
    return (
        <Layout pageTitle={"Sign Up"}>
            <BasePage  
                headline="Your Health Discount Network" 
                body="As the nation’s leading discount network, 
                      we partner with health plans to offer members 
                      10-40% off health & wellness products and local 
                      providers including acupuncture, chiropractic, 
                      massage, and many more." 
                btnText="Register"
                form={<RegisterForm/>}
                onClick={handleClick}
                linkText="Have an account? Login"
                linkTarget="login"
            />
        </Layout>
    );
};

export default Register;