
import React from 'react';
import Layout from '../../components/layout/Layout';
import Styles from './RegistrationRedirect.module.scss';
import Logo from '../../components/logo/Logo';
import ActionButton from '../../components/action-button/ActionButton';

const RegistrationRedirect = () => {

    return (
        <Layout pageTitle={'US Family Health Plan'}>
            <section className={Styles.contentContainer}>
                <section className={Styles.subscriptionBody}>
                    <p className={Styles.subTitle}>This link is no longer active.
                        Please select the specific US Family Health Plan you are member of from the list below
                        and you will be directed to your specific Choices by WholeHealth Living site.
                    </p>
                    <div>
                        <div className={`${Styles.btnContainerLink} ${Styles.btnBlueBg}`}>
                            <ActionButton
                                type="button"
                                onClick={()=>window.location="/christus"}>
                                Christus
                            </ActionButton>
                        </div>
                        <div className={`${Styles.btnContainerLink} ${Styles.btnBlueBg}`}>
                            <ActionButton
                                type="button"
                                onClick={()=>window.location="/Martinspoint"}>
                                Martinspoint
                            </ActionButton>
                        </div>
                        <div className={`${Styles.btnContainerLink} ${Styles.btnBlueBg}`}>
                            <ActionButton
                                type="button"
                                onClick={()=>window.location="/PacMed"}>
                                PacMed
                            </ActionButton>
                        </div>
                    </div>

                </section>
            </section>
            <Logo/>
        </Layout>
    );
};

export default RegistrationRedirect;
