import axios from 'axios';
import React, { memo, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import { useChoicesContext } from '../../context/ChoicesContext';
import EnvironmentService from '../../services/environment.service';

const ReferUrl = memo(() => {
    const { setReferringClientData, accessToken } = useChoicesContext();
    const [searchParams] = useSearchParams();

    const navigateOutsideHashRouter = (routeSuffix = "") => {
        // useNavigate react hook will return route within hashrouter (i.e, /#/route)
        // Native JavaScript methods used to navigate to base URL
        window.location = window.origin + routeSuffix;
    };

    const userExistsLocally = () => {
        const existingClient = parseInt(localStorage.getItem('choices-clientId'));
        const existingDomain = parseInt(localStorage.getItem('choices-domainId'));
        return Number.isSafeInteger(existingClient) && Number.isSafeInteger(existingDomain);
    };

    useMemo(async () => {
        // Match referUrl or ReferUrl, whichever is used by the client
        const currentParams = Object.fromEntries([...searchParams]);
        const standardizedParams = JSON.parse(JSON.stringify(currentParams).toLowerCase());

        // No referring URL or did not arrive via a link
        // Commenting out the line below until we decide to reimplement referrer checks.
        // if(!standardizedParams.referurl || !document.referrer) return navigateOutsideHashRouter("/access-denied");

        if (!standardizedParams.referurl) return navigateOutsideHashRouter("/access-denied");
        if (standardizedParams.referurl.toLowerCase().indexOf("http://usfhp.wholehealthmd.com") > -1
            || standardizedParams.referurl.toLowerCase().indexOf("https://www.usfhpnw.org") > -1)
            return navigateOutsideHashRouter("/register-redirect");

        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

        const { data: domainClient } =
            await axios.get(baseApiUrl + `/api/client/domain?url=${standardizedParams.referurl}`).catch(err => err);
        //this get request goes to the baseApiUrl + 'api/client/domain?url={}
        //base api is in environmentSerivce.getEncironmentVarialbe with choiceS_api...
        // https://apidev.wholehealthliving.com/api/client/domain?url=
        //

        if (domainClient && domainClient.client.isActive) {
            setReferringClientData(domainClient.clientID, domainClient.id, domainClient.domainUrl);
            if (userExistsLocally()) return navigateOutsideHashRouter("/login");
            return navigateOutsideHashRouter("/register");
        }
        return navigateOutsideHashRouter("/access-denied");
    }, []);

    return (
        // CSS-in-JS used as full .css file not necessary here
        <div style={{ height: "100vh", width: "100vw", backgroundColor: "#FFFF", zIndex: "10" }}>
            <Loading isLoading={true} isFullScreen></Loading>
        </div>
    );
});

export default ReferUrl;
