import React, { useRef, useState } from 'react';
import { FormTitle, Input } from '../form/Form';
import styles from './ForgotPasswordForm.module.scss';
import ActionButton from '../action-button/ActionButton';
import Loading from '../loading/Loading';
import AccountService from '../../services/account.service';
import ForgotPasswordDto from '../../dto/ForgotPasswordDto';
import { useSendPasswordResetEmailMutation } from '../../services/react-query/identity.service';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [isAnimated, setIsAnimated] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const { mutateAsync: sendPasswordResetEmail, isLoading: isSendingEmail } = useSendPasswordResetEmailMutation();
    const resultMessageReference = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsAnimated(true);
        if(AccountService.isValidEmail(email)) {
            const sendEmailResult = 
                await sendPasswordResetEmail(new ForgotPasswordDto(email)).catch(error => error);

            if(sendEmailResult) {
                setIsSuccess(sendEmailResult.isSuccess);
                resultMessageReference.current.innerText = sendEmailResult.message;
            } else {
                setIsSuccess(sendEmailResult.error.isSuccess);
                resultMessageReference.current.innerText = sendEmailResult.message;
            }

        } else {
            setIsSuccess(false);
            resultMessageReference.current.innerText = "Invalid email address";
        }

    };

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <FormTitle title="Forgot Your Password?"/>
            <span 
                ref={resultMessageReference} 
                className=
                    {`${isSuccess === null ? styles.hidden : isSuccess ? styles.successMessage : styles.errorMessage} 
                    ${isAnimated ? styles.animate : undefined}`}
                onAnimationEnd={()=>setIsAnimated(false)}
            >
            </span>
            <div className={styles.inputContainer}>
                <Input 
                    label="Enter the email address for your account:"
                    onChange={handleChange}
                    inputValue={email}
                />
            </div>
            <p className={styles.info}>
                   A password reset link will be sent to the email you provide.
            </p>
            <div className={styles.btnContainer}>
                <ActionButton
                    btnType="submit"
                >
                Send Password Reset Email
                </ActionButton>
            </div>
            <Loading isFullScreen={true} isLoading={isSendingEmail}/>
        </form>
    );
};

export default ForgotPasswordForm;   