import { useReducer } from "react";
import React from 'react';
import { initialState, reducer } from "./reducer";
import { ChoicesContext } from "./ChoicesContext";

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setImgCarouselIndex = (newIndex) => dispatch({ type: 'SET_IMG_CAROUSEL_INDEX', payload: newIndex });

    const setFirstName = (firstName) => {
        dispatch({ type: 'SET_FIRST_NAME', payload: firstName });
        localStorage.setItem('choices-firstName', firstName);
    };

    const setLastName = (lastName) => {
        dispatch({ type: 'SET_LAST_NAME', payload: lastName });
        localStorage.setItem('choices-lastName', lastName);
    };

    const setEmail = (email) => {
        dispatch({ type: 'SET_EMAIL', payload: email });
        dispatch({ type: 'SET_USERNAME', payload: email });
        return localStorage.setItem('choices-email', email);
    };

    const setState = (state) => {
        dispatch({ type: 'SET_STATE', payload: state });
        localStorage.setItem('choices-state', state);           
    };

    const setSessionId = (sessionId) => {
        dispatch({ type: 'SET_SESSION_ID', payload: sessionId });
        return localStorage.setItem('choices-sessionId', sessionId);
    };

    const setJsonWebToken = (accessToken, refreshToken, accessExpires) => {
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
        dispatch({ type: 'SET_REFRESH_TOKEN', payload: refreshToken });
        dispatch({ type: 'SET_ACCESS_EXPIRES', payload: accessExpires });
        localStorage.setItem("choices-refresh-token", refreshToken);
        localStorage.setItem("choices-access-expires", accessExpires);
    };

    const setClientDomainData = (clientId, domainId) => {
        dispatch({ type: 'SET_CLIENT_ID', payload: clientId });
        dispatch({ type: 'SET_DOMAIN_ID', payload: domainId });
        localStorage.setItem("choices-clientId", clientId);
        localStorage.setItem("choices-domainId", domainId);
    };

    const setReferringClientData = (clientId, domainId, domainUrl) => {
        dispatch({ type: 'SET_REFERRING_CLIENT_ID', payload: clientId });
        dispatch({ type: 'SET_REFERRING_DOMAIN_ID', payload: domainId });
        dispatch({ type: 'SET_REFERRING_DOMAIN_URL', payload: domainUrl });
        sessionStorage.setItem("choices-referring-clientId", clientId);
        sessionStorage.setItem("choices-referring-domainId", domainId);
        sessionStorage.setItem("choices-referring-domainUrl", domainUrl);
    };

    const setSearchCoordinates = (latitude, longitude) => {
        dispatch({ type: 'SET_LATITUDE', payload: latitude });
        dispatch({ type: 'SET_LONGITUDE', payload: longitude });
        localStorage.setItem("choices-latitude", latitude);
        localStorage.setItem("choices-longitude", longitude);
    };

    const setSearchAddress = (searchAddress) => {
        dispatch({ type: 'SET_SEARCH_ADDRESS', payload: searchAddress });
        localStorage.setItem("choices-search-address", searchAddress);
    };

    const setUserId = (userId) => {
        dispatch({ type: 'SET_USER_ID', payload: userId });
        localStorage.setItem("choices-userId", userId);
    };

    const context = {
        ...state,
        setImgCarouselIndex,
        setFirstName,
        setLastName,
        setEmail,
        setState,
        setSessionId,
        setJsonWebToken,
        setClientDomainData,
        setReferringClientData,
        setSearchCoordinates,
        setSearchAddress,
        setUserId
    };

    return <ChoicesContext.Provider value={context}>{children}</ChoicesContext.Provider>;
};

export default StateProvider;