import React from 'react';
import landingImg from '../../assets/images/landing.webp';
import styles from './LandingImage.module.scss';

const LandingImage = ({isAccessDenied}) => {
    return (
        <section className={styles.imgContainer}>
            <img 
                loading="lazy"
                className={`${styles.img} ${!isAccessDenied ? styles.container : styles.accessDenied}`} 
                src={landingImg} 
                alt="WholeHealth Living supports a variety of healthy activities"/>
        </section>
    );
};

export default LandingImage;