import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../action-button/ActionButton';
import OfferButton from '../offer-button/OfferButton';
import OfferService from '../../../services/offer.service';
import styles from './OfferDetails.module.scss';
import ProductTypeId from '../../../models/productTypeId';
import Loading from '../../loading/Loading';
import AnalyticsService from '../../../services/analytics.service';
import { 
    useGetFitbitPromoUriQuery, 
    usePublishEventMutation, 
    useSendOfferEmailMutation 
} from '../../../services/react-query/choices.service';
import OfferEvent from '../../../models/OfferEvent';
import PrintOfferDto from '../../../dto/PrintOfferDto';
import Toast from '../../toast/Toast';

const OfferDetails = ({
    heading, 
    productTypeId, 
    imagePath, 
    discount, 
    title, 
    offerCode,
    description,
    detailsTitle1, 
    detailsTitle2, 
    detailsChild1, 
    detailsChild2,
    offerItem = { practitioner: null, fitnessCenter: null, product: null}
}) => {
    const [isCopied, setIsCopied] = useState({OfferButton: false, ActionButton: false});
    const [toastMessages, setToastMessages] = useState([]);
    const offerOptions = OfferService.getOfferConfiguration(productTypeId, offerItem);

    const { 
        mutateAsync : publishEvent, 
        isLoading : isPublishingEvent,
    } = usePublishEventMutation();

    const { 
        mutateAsync : sendOfferEmail, 
        isLoading : isSendingOfferEmail,
    } = useSendOfferEmailMutation();

    const { 
        refetch: getFitbitPromoUri,
        isRefetching : isFetchingFitbitPromoUri,
    } = useGetFitbitPromoUriQuery();

    const handleCopyCode = (isOfferButton) => {
        if(!navigator.clipboard) {
            window.getSelection().selectAllChildren(document.querySelector('#copyCode'));
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
        } else navigator.clipboard.writeText(offerItem.product.offerCode.toUpperCase());

        setIsCopied({OfferButton: isOfferButton, ActionButton: !isOfferButton});
        setTimeout(()=>setIsCopied({OfferButton: false, ActionButton: false}),1200);
    };

    const handlePrintOffer = async () => {
        sessionStorage.setItem(
            'print-offer', JSON.stringify(new PrintOfferDto(offerItem.practitioner,offerItem.fitnessCenter)));
            
        let offerObject = Object.values(offerItem).filter(item => item !== null)[0];
        AnalyticsService.trackOfferEvent(offerObject,"%",OfferEvent.PRINT);
        await OfferService.publishEvent(
            publishEvent, 
            OfferEvent.PRINT, 
            offerObject.productTypeId, 
            offerObject.productId
        ).catch(err => err);
        
        window.open("/printOffer.html");
    };

    const handleRedeemOffer = async () => {
        let webSiteAddress = offerItem.product.webSiteAddress;

        // Get dynamic URL for Fitbit
        if(offerItem.product.name.toLowerCase().includes("fitbit")) {
            const response = await getFitbitPromoUri().catch(err => err);
            if(response.status === "success") {
                // Fitbit implements an offer limit, we parse their message and display our own.
                if(response.data.includes("You have reached your limit. Only two limits for each member")) {
                    setToastMessages([{
                        message:"You have reached your limit. Limit two per member.", 
                        success: false, 
                        key: Date.now()
                    }]);
                    return;
                }
                try {
                    // Force the URL to be parsed, if an error is thrown it is not a URL
                    const redeemableUrl = new URL(response.data);
                    webSiteAddress = redeemableUrl.href;
                } catch {
                    setToastMessages([{message:"Error retrieving Fitbit Promo Link", success: false, key: Date.now()}]);
                    return;
                }
            } else {
                setToastMessages([{message:"Error retrieving Fitbit Promo Link", success: false, key: Date.now()}]);
                return;
            }
        }

        if(!webSiteAddress) {
            setToastMessages([{message:"Error retrieving Fitbit Promo Link", success: false, key: Date.now()}]);
        } else {
            AnalyticsService.trackOfferEvent(offerItem.product,"%",OfferEvent.REDEEM);
            await OfferService.publishEvent(
                publishEvent, 
                OfferEvent.PRINT, 
                offerItem.product.productTypeId, 
                offerItem.product.productId
            ).catch(err => err);
            return window.open(webSiteAddress);
        }
    };

    const handleEmailOffer = async () => {
        let offerObject = Object.values(offerItem).filter(item => item !== null)[0];
        
        const response = await sendOfferEmail(offerObject).catch(err => err).catch(err => err);

        if(response.status === 204) {
            setToastMessages([{
                message:"Offer has been sent to the email listed with your account.", 
                success: true, 
                key: Date.now() 
            }]);
            AnalyticsService.trackOfferEvent(offerObject,"%",OfferEvent.EMAIL);
            return await OfferService.publishEvent(
                publishEvent, 
                OfferEvent.EMAIL, 
                offerObject.productTypeId, 
                offerObject.productId
            ).catch(err => err);
        } else {
            setToastMessages([{
                message:"Error sending email to your account's email address.", 
                success: false, 
                key: Date.now() 
            }]);
            return;
        }
    };

    return (
        <section className={styles.detailsContainer}>
            <div className={styles.detailsHeaderContainer}>
                <div className={styles.returnToOffersLink}>
                    <Link to={OfferService.getSearchPagePath(productTypeId)}>Return to Offers</Link>
                </div>
                <div className={styles.featuredOfferTitle}>
                    <span>{heading}</span> 
                </div>
                <div className={styles.headerBtnsTopRight}>
                    <OfferButton 
                        text={offerOptions.btnOneText}
                        icon={`/images/${offerOptions.btnOneImage}`} 
                        onClick={productTypeId === ProductTypeId.VENDOR ? handleRedeemOffer : handlePrintOffer }
                    /> 
                    {(offerCode || !offerOptions.btnTwoPerformsCopy) &&    
                    <OfferButton 
                        text={isCopied.OfferButton ? "Copied to Clipboard!" : offerOptions.btnTwoText} 
                        icon={`/images/${offerOptions.btnTwoImage}`}  
                        onClick={offerOptions.btnTwoPerformsCopy ? ()=>handleCopyCode(true) : handleEmailOffer}
                    />}   
                </div>
            </div>
            <div className={styles.fourSquareContainer}>
                <div className={styles.imageContainerTopLeft}>
                    <div className={styles.imgContainer}>
                        <img 
                            src={!imagePath ? 
                                "/images/detail-fallback.jpg" : imagePath} 
                            alt={title}
                        />
                    </div>
                </div>
                <div className={styles.offerInformationContainerTopRight}>
                    <h1>
                        {title}
                    </h1>
                    <div className={styles.offerDiscount}>
                        { productTypeId === ProductTypeId.VENDOR ? 
                            <span className={styles.discount} dangerouslySetInnerHTML={{ __html: discount}} ></span> :
                            <span className={styles.discount}>{discount}%<sup><small>OFF</small></sup></span>
                        }
                        {!offerCode ? undefined : 
                            <strong className={styles.offerCode}>
                                Coupon Code: <span id="copyCode">{offerCode}</span>
                            </strong>}
                    </div>
                    <div className={styles.offerDescription}>
                        {!description ? undefined : 
                            <p>
                                <span dangerouslySetInnerHTML={{ __html:description}}></span>  
                            </p>}
                    </div>
                    <div className={styles.offerBtns}>
                        <ActionButton 
                            btnType="button" 
                            onClick={productTypeId === ProductTypeId.VENDOR ? handleRedeemOffer : handlePrintOffer}
                        >
                            {offerOptions.btnOneText}
                        </ActionButton>
                        {(offerCode || !offerOptions.btnTwoPerformsCopy) &&
                            <ActionButton 
                                btnType="button" 
                                onClick=
                                    {offerOptions.btnTwoPerformsCopy ? ()=> handleCopyCode(false) : handleEmailOffer}
                            >
                                {isCopied.ActionButton ? "Copied to Clipboard!" : offerOptions.btnTwoText}
                            </ActionButton>}
                    </div>
                </div>
                <div className={styles.detailsContainerBottomLeft}>
                    <div className={styles.columnOneDetails}>
                        <p className={styles.subTitle}>{detailsTitle1}</p>
                        <div className={styles.detailsBody}>{detailsChild1} </div>
                    </div>
                </div>
                <div className={styles.termsContainerBottomRight}>
                    <p className={styles.subTitle}>{detailsTitle2}</p>
                    <div className={styles.detailsBody}>{detailsChild2}</div>
                </div>
            </div>
            <Loading 
                isFullScreen
                isLoading={isFetchingFitbitPromoUri || isSendingOfferEmail || isPublishingEvent}
            />
            <Toast messages={toastMessages}/> 
        </section>
    );
};

export default OfferDetails; 