import React from 'react';
import styles from './RegisterForm.module.scss';

const RegisterError = ({displayErrors, setDisplayErrors, registrationErrors}) => {
    return (
        <section className={displayErrors ? styles.errorBanner : styles.hidden}>
            <button className={styles.closeError} onClick={()=>setDisplayErrors(false)}>
                &times;
            </button>
            <div className={styles.errorMessage}>
                <p>Please correct the following:</p>
                {registrationErrors.length > 0 && 
                    <ul>
                        {registrationErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>}
            </div>
        </section>
    );
};

export default RegisterError;