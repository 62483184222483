import StyledLink from "../styled-link/StyledLink";
import styles from './ArticleLanding.module.scss';

const ArticleLarge = ({
    articleButtonColor,
    articleButtonLinkTo,
    articleButtonSize,
    articleButtonText,
    articleHeader,
    articleReadTime,
    bgIMG
}) => {

    const BGstyle = {
        backgroundImage: `url(${bgIMG})`,
        backgroundSize: "cover",
        backgroundPosition: "95% 10%",
        backgroundRepeat: "no-repeat"
    };

    return (
        <div className={styles.articleLandingContainer} style={BGstyle}>
            <div className={styles.articleLandingImage}>
                <div className={styles.articleLandingBottomBanner}>

                    <div className={styles.articleLandingLeft}>
                        <h2 className={styles.articleLandingHeader}>{articleHeader}</h2>
                    </div>
                    
                    <div className={styles.articleLandingCenter}>
                        <StyledLink
                            styledLinkText={articleButtonText}
                            styledLinkColor={articleButtonColor}
                            styledLinkSize={articleButtonSize}
                            linkTo={articleButtonLinkTo}
                    
                        />
                    </div>

                    <div className={styles.articleLandingRight}>
                        <h3 className={styles.articleLandingReadTime}>{articleReadTime}</h3>
                    </div>

                </div>
            </div>

            <div className={`${styles.articleContent}`}>

            </div>

        </div>
    );
};

export default ArticleLarge;