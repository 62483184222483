import React from 'react';
import EnvironmentService from '../../../services/environment.service';
import ActionButton from '../../action-button/ActionButton';
import styles from './FitnessCenterDetails.module.scss';
import OfferService from '../../../services/offer.service';

const FitnessCenterLocation = ({fitnessCenter}) => {

    return (
        <div className={styles.locationContent}>
            <div className={styles.contactMeta}>
                <div className={styles.distance}>
                    <img 
                        src="/images/locationIcon.svg"
                        alt="Location Marker" />
                    <span>{fitnessCenter.distance} Miles Away</span>
                </div>
                {fitnessCenter.phoneNumber && 
                    <div className={styles.phoneNumber}>
                        <img 
                            src="/images/phone.svg"
                            alt="Phone Icon" 
                        />
                        <a href={`tel:${fitnessCenter.phoneNumber}`}>
                                            ({fitnessCenter.phoneNumber.substring(0, 3)})- 
                            {fitnessCenter.phoneNumber.substring(3, 6)}-
                            {fitnessCenter.phoneNumber.substring(6, 10)}
                        </a>
                    </div>}
                <div className={styles.btnContainer}>
                    {!fitnessCenter.webSiteAddress ? undefined : 
                        <ActionButton onClick={()=>window.open("//" + fitnessCenter.webSiteAddress)}>
                            Visit Website
                        </ActionButton>}
                </div>
            </div>
            <div className={styles.locationMeta}>
                <div className={styles.locationAddress}>
                    <span>{fitnessCenter.name}</span>
                    <span>{fitnessCenter.addressLine1}</span>
                    <span>{fitnessCenter.city}, {fitnessCenter.state} {fitnessCenter.zipCode}</span>
                </div>
                <div className={styles.btnContainer}>
                    <ActionButton 
                        type="button" 
                        onClick={()=>OfferService.getDirections(fitnessCenter)}>
                        Get Directions
                    </ActionButton>
                </div>
            </div>
        </div>
    );
};

export default FitnessCenterLocation;