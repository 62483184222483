import React, { useState } from 'react';
import styles from './Filter.module.scss';
import mobileFilterArrow from '../../assets/images/mobileFilterArrow.svg';
import mobileFilterArrowActive from '../../assets/images/mobileFilterArrowActive.svg';

const FilterMobileBanner = ({mobileBannerIsToggled,setMobileBannerIsToggled, isPreview}) => {
    const handleMobileToggleClick = () => {
        setMobileBannerIsToggled(!mobileBannerIsToggled);
    };

    return (
        <div className={styles.mobileFilterBanner}>
            <span className={styles.bannerText}>
                {isPreview ? "Search for Practitioners": "Filter Discount Results"}
            </span>
            <button
                type="button"
                className={`${styles.bannerBtn}
                ${mobileBannerIsToggled && styles.mobileActivated}`}
                onClick={handleMobileToggleClick}
            >
                <img
                    loading="lazy"
                    src={mobileFilterArrow }
                    alt="Toggle arrow icon for page filters"
                />
            </button>
        </div>
    );
};

export default FilterMobileBanner;
