import React, { useMemo, useState } from 'react';
import styles from './ZeroResults.module.scss';
import noProviders from '../../assets/images/no_providers.svg';
import noProducts from '../../assets/images/no_products.svg';
import noFacilities from '../../assets/images/no_facilities.svg';
import ProductTypeId from '../../models/productTypeId';
import { Link } from 'react-router-dom';

const ZeroResults = ({productTypeId}) => {
    const [images, setImages] = useState({});

    useMemo(()=> {
        let imageMap = {};
        imageMap[ProductTypeId.VENDOR] = noProducts;
        imageMap[ProductTypeId.PROVIDER] = noProviders;
        imageMap[ProductTypeId.FACILITY] = noFacilities;
        setImages(imageMap);
    },[]);

    return (
        <div className={styles.container}>
            <p className={styles.heading}>
                Choices by WholeHealth Living is unable to provide this service in your area. 
                Find other cost-savings with our <Link to="/products">products and services</Link> 
                open to all members.
            </p>
            <div className={styles.imgContainer}>
                <img
                    src={images[productTypeId]}
                    alt="Choices by WholeHealth Living is unable to provide this service in your area. 
                    Find other cost-savings with our products and services  open to all members."
                />
            </div>
        </div>
    );
};

export default ZeroResults;
