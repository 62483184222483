import React, { useMemo, useState } from 'react';
import AccountService from '../../services/account.service';

const VisibilityFilter = ({children, className = undefined}) => {
    const [visibleStyle, setVisibleStyle] = useState({});

    useMemo(()=> {
        if(AccountService.isSilverSneakersMember()) setVisibleStyle({display:"none"});
    },[]);
  
    return <div style={visibleStyle} className={className}>{children}</div>;
};

export default VisibilityFilter;