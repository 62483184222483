/* eslint-disable max-len */
export  const carouselText = Object.freeze([
    {
        headline:"Your Health Discount Network",
        body:"As the nation’s leading discount network, we offer members 10-40% off health & wellness products and services."
    },
    {
        headline:"Fitness and Wellness Centers",
        body:"Increase your physical activity and improve your health by joining fitness centers across the country at a discounted rate. Check with your health plan for details."
    },
    {
        headline:"Practitioners",
        body:"Access our network of acupuncturists, chiropractors, and massage therapists to help you get back to the life your love. Check with your health plan for details."
    },
    {
        headline:"Lifestyle Products",
        body:"Receive discounts on popular lifestyle products to enhance your health and well-being including nutrition, weight management, and vitamins."
    },
    {
        headline:"Well-Being Services",
        body:"Save money by taking advantage of a variety of well-being services to make living well more affordable."
    }
]);