import OfferEvent from "../models/OfferEvent";
import ProductTypeId from "../models/productTypeId";
import OAuthService from "./oauth.service";

export default class AnalyticsService {

    static trackOfferEvent(product, discountType, offerEvent) {
        try {
            return window.gtag("event", this.getOfferActionText(offerEvent), {
                ...this.assignEventParameters(product, discountType, offerEvent)
            });
        } catch {
            return;
        }
    }

    static trackOfferView(product, discountType, offerEvent) {
        try {
            return window.gtag("event", "view_offer", {
                ...this.assignEventParameters(product, discountType, offerEvent)
            });
        } catch {
            return;
        }
    }

    static trackReturnVisitor() {
        try {
            return window.gtag("event", "return_visit", {
                username: localStorage.getItem("choices-email"),
                client_name: localStorage.getItem("choices-client-name"),
            });
        } catch {
            return;
        }
    }

    static trackRegistration(clientName, userName) {
        const { referringClientId, referringDomainId, referringDomainUrl } = OAuthService.getReferralData();
        try {
            return window.gtag("event", "new_registration", {
                username: userName, 
                client_name: clientName,
                referring_domain_url:referringDomainUrl,
                referring_domain_id: referringDomainId,
                client_id: referringClientId
            });
        } catch {
            return;
        }
    }

    static trackSignUp() {
        try {
            return window.gtag("event", "sign_up", {
                method: localStorage.getItem("choices-client-name")
            });
        } catch {
            return;
        }
    }

    static assignEventParameters(product, discountType = "%", offerEvent = null) {
        return {
            custom_event_id: `${new Date().toISOString()}_${Math.round(Math.random()*10000)}`,
            product_id: product.productId,
            product_name: product.name, 
            offer_code: product.offerCode || null,
            discount: product.discount || product.webDiscount,
            product_type: this.getOfferProductType(product.productTypeId),
            discount_type: discountType,
            offer_website_url: product.webSiteAddress || "",
            distance_from_user: product.distance || 0,
            page_title: document.title,
            page_url: window.location.href,
            client_name: localStorage.getItem("choices-client-name"),
            subcategory: Array.isArray(product.subCategoryNames) ? 
                product.subCategoryNames[0] : product.subCategoryNames.split(",")[0],
            event_type: this.getOfferActionText(offerEvent),
            search_address: localStorage.getItem("choices-search-address"),
        };
    }

    static getOfferActionText(action) {
        if(action === OfferEvent.EMAIL) return "email_offer";
        if(action === OfferEvent.PRINT) return "print_offer";
        return "redeem_offer";
    }

    static getOfferProductType(productTypeId) {
        if(productTypeId === ProductTypeId.FACILITY) return "Fitness/Wellness Center";
        if(productTypeId === ProductTypeId.VENDOR) return "Product";
        return "Practitioner";
    }

}