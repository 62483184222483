import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styles from './PractitionerDetailsPage.module.scss';
import Logo from '../../../components/logo/Logo';
import FeaturedOffers from '../../../components/offer/featured-offers/FeaturedOffers';
import PractitionerMetadata 
    from '../../../components/practitioners/practitioner-details/practitioner-metadata/PractitionerMetadata';
import PractitionerLocations 
    from '../../../components/practitioners/practitioner-details/practitioner-locations/PractitionerLocations';
import ProductTypeId from '../../../models/productTypeId';
import OfferDetails from '../../../components/offer/offer-details/OfferDetails';
import { useGetProductDetailsMutation } from '../../../services/react-query/choices.service';
import ProductSearchDto from '../../../dto/ProductSearchDto';
import Layout from '../../../components/layout/Layout';
import Loading from "../../../components/loading/Loading";

const PractitionerDetailsPage = () => {
    const { practitionerId } = useParams();
    const [ practitioner, setPractitioner] = useState({});
    const { mutateAsync: getProductDetails, isLoading: isRetrievingDetails } = useGetProductDetailsMutation();
    const cachedPractitioners = JSON.parse(localStorage.getItem('choices-products'));
    const navigate = useNavigate();

    useEffect(async ()=> {
        let cachedPractitioner;
        if(cachedPractitioners !== null) 
            cachedPractitioner = 
                cachedPractitioners.filter(practitioner=>practitioner.id === parseInt(practitionerId))[0];
        
        if(!cachedPractitioner) {
            const productDetail = await getProductDetails({
                productTypeId:ProductTypeId.PROVIDER,
                id: practitionerId,
                productSearchDto: new ProductSearchDto()
            }).catch(error=> error);
            
            if(productDetail) setPractitioner(productDetail);
            else navigate("/not-found");

        } else {
            setPractitioner(cachedPractitioner);
        }
    },[practitionerId]);

    return ( 
        <Layout pageTitle={'Practitioner Details'}>
            <div className={styles.pageContainer}>
                {!practitioner || Object.keys(practitioner).length < 1 ? undefined :
                    <OfferDetails
                        heading={Array.isArray(practitioner.subCategoryNames) ? 
                            practitioner.subCategoryNames[0] : practitioner.subCategoryNames.split(",")[0]}
                        productTypeId={ProductTypeId.PROVIDER}
                        imagePath={!practitioner ? undefined : `/images/subcategories/${practitioner.image}`}
                        discount={practitioner.discount || practitioner.webDiscount}
                        title={practitioner.name + (!practitioner.suffix ? ""  :`, ${practitioner.suffix}`)}
                        detailsTitle1="Details"
                        detailsTitle2="Location"
                        description={practitioner.description}
                        offerItem={ { practitioner: practitioner, fitnessCenter: null, product: null} }
                        detailsChild1={<PractitionerMetadata practitioner={practitioner} />}
                        detailsChild2={<PractitionerLocations practitioner={practitioner} />}
                    />}
                <Logo/>
                {!cachedPractitioners || cachedPractitioners.length < 1 ? undefined :
                    <FeaturedOffers 
                        items={cachedPractitioners} 
                        title="Related Discounts" 
                    />
                }
            </div>
        </Layout>
    );
};
export default withAuthenticationRequired(PractitionerDetailsPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
