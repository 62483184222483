/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./ToastError.module.scss";

const ToastError = ({ messages, isSuccessful }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const UNMOUNT_DELAY_MS = messages.length <= 3 ? 3000 : 6000;

    if (messages && messages.length > 0) {
      setIsMounted(true);
      setTimeout(() => {
        setIsMounted(false);
      }, UNMOUNT_DELAY_MS);
    }
    return () => setIsMounted(true);
  }, [messages]);

  return (
    <div className={styles.container}>
      <div
        className={`
        ${styles.message} 
        ${isSuccessful ? styles.success : styles.error}
        ${isMounted ? styles.fadeInUp : styles.fadeOutDown}
        `}
      >
        {isMounted && (
          <ul>
            <button
              className={styles.close}
              onClick={() => setIsMounted(false)}
            >
              &times;
            </button>
            {messages.map((message, index) => (
              <li key={message.key || index}>{message.message}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ToastError;
