/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styles from './ManagePlanPage.module.scss';
import Layout from '../../components/layout/Layout';
import Logo from '../../components/logo/Logo';
import NavigationColumn from "../../components/navigation-column/NavigationColumn";
import ActionButton from '../../components/action-button/ActionButton'; 
import {
    useGetUserSubscriptionDetailsQuery,
    useCreateRefundMutation
} from '../../services/react-query/choices.service';
import DateConverter from './DateConverter';
import Loading from '../../components/loading/Loading';
import CancelSubscriptionModal from "../../components/cancel-subscription-modal/CancelSubscriptionModal.js";
import CancelSubscriptionSuccessModal from '../../components/cancel-subscription-modal/CancelSubscriptionSuccessModal.js';
import { valueToNode } from '@babel/types';

const ManagePlanPage = ({ environment }) => {
    const [checkoutSessionId, setCheckoutSessionId] = useState("");
    const [subscriptionDate, setSubscriptionDate] = useState("");
    const [subscriptionDateFormatted, setSubscriptionDateFormatted] = useState("");
    const [refundDate, setRefundDate] = useState("");
    const [showCancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
    const [showCancelSubscriptionSuccessModal, setCancelSubscriptionSuccessModal] = useState(false);

    const [windowToRefund, setWindowtoRefund] = useState(false);

    const [renewSubscriptionDate, setRenewSubscriptionDate] = useState("");
    const [renewSubscriptionDateFormatted, setRenewSubscriptionDateFormatted] = useState("");

    const { data, error, isLoading } =
        useGetUserSubscriptionDetailsQuery(localStorage.getItem("choices-email"));

    const {
        mutateAsync: createRefundRequest,
        isLoading: isCreatingRefundRequest
    } = useCreateRefundMutation();

    useEffect(() => {
        if (!isLoading && data) {
            setSubscriptionDate(data?.createdDateUTC);
            setCheckoutSessionId(data?.checkoutSessionId);

            setSubscriptionDateFormatted(DateConverter.getFormattedDate(subscriptionDate));
            setRefundDate(DateConverter.getFutureDate(subscriptionDate, 30));

            setRenewSubscriptionDate(DateConverter.getFutureDate(subscriptionDate, 365));
            setRenewSubscriptionDateFormatted(DateConverter.getFormattedDate(renewSubscriptionDate));

            const currentDate = new Date().getTime();
            const lastDayToRefund = new Date(refundDate).getTime();

            const refundWindow = currentDate <= lastDayToRefund && data.subscriptionStatus === true;
            setWindowtoRefund(refundWindow);
        }
    }, [data, subscriptionDate, renewSubscriptionDate]);

    const requestBody = {
        sessionId: checkoutSessionId
    };

    const handleRefund = async () => {     
        
        try {

            const response = await createRefundRequest(requestBody).catch(err => err);
            const successfulRefund = await response.status;        

            if (successfulRefund === 200) {
                localStorage.clear();
                setCancelSubscriptionModal(false);
                setCancelSubscriptionSuccessModal(true);
            }

        } catch (err) { console.log(err); }
    };

    const handleCancelSubscription = async(event) =>{
        event.preventDefault();
        setCancelSubscriptionModal(true);
    };

    const handleButtonClick = (value) => {
        if(value === true){
            handleRefund();
        }
        else{
            setCancelSubscriptionModal(false);
        }
    };

    const handleButtonClickLogOut = () => {   
        window.location.href = '/';
    };

    return (
        <Layout pageTitle={"Manage Plan"}>
            <section className={styles.managePlanPageContainer}>

                <NavigationColumn
                    environment={environment}
                />

                <div className={styles.managePlanContentContainer}>
                    <h2 className={styles.managePlanTitle}>Manage Plan</h2>
                    <div className={styles.managePlanInfoContainer}>
                        <div className={styles.contentBoxContainer}>
                            <h3 className={styles.contentBoxHeader}>Subscription Type</h3>
                            <h4 className={styles.contentBoxText}>Annual</h4>
                        </div>
                        <div className={styles.contentBoxContainer}>
                            <h3 className={styles.contentBoxHeader}>Start Date</h3>
                            <h4 className={styles.contentBoxText}>{subscriptionDateFormatted}</h4>
                        </div>
                        <div className={styles.contentBoxContainer}>
                            <h3 className={styles.contentBoxHeader}>Renewal Date</h3>
                            <h4 className={styles.contentBoxText}>{renewSubscriptionDateFormatted}</h4>
                        </div>
                    </div>

                    <div className={styles.purchaseHistoryContainer}>

                        <div className={styles.contentBoxContainer}>
                            <h3 className={styles.contentBoxHeader}>Purchase History</h3>
                            <div className={styles.purchaseHistoryHeaders}>
                                <span>Product</span>
                                <span>Date</span>
                                <span>Order Total USD</span>
                            </div>

                            <div className={styles.purchaseHistoryText}>
                                <span>Annual Subscription</span>
                                <span>{subscriptionDate}</span>
                                <span>9.99</span>
                            </div>

                        </div>
                    </div>

                    {windowToRefund && (
                        <div className={styles.cancelButtonContainer}>
                            <div>
                                <button
                                    type="submit"
                                    className={styles.cancelButton}                                      
                                    onClick={(event) => handleCancelSubscription(event)}>                               
                                    Cancel Subscription
                                </button>
                            </div>
                        </div>
                    )}

                </div>
                <Logo />
                <Loading
                    isLoading={isLoading || isCreatingRefundRequest}
                />
            </section>
      
            {showCancelSubscriptionModal && <CancelSubscriptionModal onButtonClick={handleButtonClick} />}           
            {showCancelSubscriptionSuccessModal && <CancelSubscriptionSuccessModal onButtonClick={handleButtonClickLogOut} />}   
        </Layout>
    );
};

export default withAuthenticationRequired(ManagePlanPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
