import styles from "./AccountPage.module.scss";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useState } from "react";
import AccountForm from "../../components/account-form/AccountForm";
import NavigationColumn from "../../components/navigation-column/NavigationColumn";
import Logo from "../../components/logo/Logo";
import Layout from "../../components/layout/Layout";
import Loading from "../../components/loading/Loading";

const AccountPage = ({ environment }) => {
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const clearErrorMessages = () => {
        setErrorMessages([]);
        setIsError(false);
        setIsSuccess(false);
    };

    const addErrorMessage = (newError) => {
        setErrorMessages(errorMessages => [...errorMessages, newError]);
    };

    return (
        <Layout pageTitle={"My Account"}>
            <section className={styles.accountPageContainer}>
                <NavigationColumn
                    environment={environment}
                />
                <div className={styles.accountContentContainer}>
                    <h1 className={styles.accountContentTitle}>My Account</h1>
                    <div className={styles.accountInfoContainer}>
                        <AccountForm
                            isError={isError}
                            isSuccess={isSuccess}
                            errorMessages={errorMessages}
                            clearErrorMessages={clearErrorMessages}
                            addErrorMessage={addErrorMessage}
                            setIsError={setIsError}
                            setIsSuccess={setIsSuccess}
                        />
                    </div>
                </div>
                <Logo />
            </section>
        </Layout>
    );
};

export default withAuthenticationRequired(AccountPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
