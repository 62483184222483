import React, { useState } from "react";
import Styles from "./SubscriptionAgreement.module.scss";
import "./SubscriptionAgreement.module.scss";

const SubscriptionAgreement = (props) => {
    const [isChecked, setIsChecked] = useState(false);

    if (!props.show) {
        return null;
    }

    const handleCheckboxChange = (event) => {
        setIsChecked((prev) => !prev);
    };

    return (
        <div className={Styles.backdrop}>
            <div className={Styles.modalWindow}>
                <div className={Styles.centerContent}>
                    <h1>Choices by WholeHealth Living® </h1>
                    <h3>SUBSCRIBER AGREEMENT (rev.12/19/2023)</h3>
                </div>
                <p className={Styles.legalParagraph}>
                    This Subscriber Agreement (the <strong>“Agreement”</strong>) sets forth the terms and conditions
                    of membership in Choices by WholeHealth Living®  (the <strong>“Program”</strong>) a  discount
                    network provided by WholeHealth Living, Inc. (<strong>“WholeHealth Living”</strong>), as applicable
                    in connection with WholeHealth Living’s agreement with Blue Cross and Blue Shield Association
                    (the <strong>“Plan”</strong>). A purchaser of a membership in the Program shall hereinafter be
                    referred to as “<strong>Member.</strong>”  By Accepting the Terms and Conditions of this Agreement
                    and paying the enrollment fee and initial annual access fee, Subscriber agrees to purchase a
                    membership in the Program pursuant to the terms and conditions of this Agreement and to fully
                    perform Member’s obligations under this Agreement. Subscriber must be a current legal resident of
                    the United States, at least 18 years of age or older and a member of the Plan to enroll in the
                    Program.
                </p>

                <ol>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Choices by WholeHealth Living® Program Benefits.</strong>{" "}
                            During the term of this Agreement, WholeHealth Living provides
                            Members with access to a network of participating third party
                            providers who offer to Members discounted integrative health and
                            wellness services (“<strong>Integrative Health Services</strong>”), including but
                            not limited to chiropractic, acupuncture, massage/body work,
                            holistic physicians, exercise/movement specialists, yoga
                            therapists, mind-body specialties, nutritionists, dieticians, and
                            relaxation techniques (“Integrative Health Practitioners”).
                            WholeHealth Living, and its affiliates do not employ, own or
                            operate the Integrative Health Practitioners or Wellness
                            Providers. Subscriber must pay for the Integrative Health Services
                            purchased from the Integrative Health Practitioner but will
                            receive a discount on such services by the Integrative Health
                            Practitioner. The Integrative Health Services are subject to terms
                            and conditions of the Integrative Health Practitioners.
                            <strong>
                                IMPORTANT NOTICE: HEALTH CARE AND HEALTH CARE RELATED PROVIDERS IN THE INTEGRATIVE
                                HEALTH SERVICES NETWORK ARE NOT CURRENTLY AVAILABLE TO RESIDENTS OF CALIFORNIA,
                                CONNETICUT, INDIANA, LOUISIANA, MONTANA, NEBRAKSA, NEW HAMPSHIRE, NEVADA, OKLAHOMA,
                                 OREGON, SOUTH DAKOTA, UTAH OR WASHINGTON. PLEASE VISIT{" "}
                                <a
                                    href="http://www.whlchoices.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.whlchoices.com
                                </a>{" "}
                                FOR A COMPLETE LIST OF AVAILABLE INTEGRATIVE HEALTH
                                PRACTITIONERS AVAILABLE IN SUBSCRIBER’S STATE OF RESIDENCE.
                                WHOLEHEALTH LIVING, INC., HAS A MAILING ADDRESS OF: 1445 S.
                                SPECTRUM BLVD, CHANDLER, ARIZONA 85286.{" "}
                            </strong>
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Term.</strong> The Agreement shall commence upon
                            Subscriber’s acceptance of the terms and conditions of this
                            Agreement and payment of the annual access plus applicable sales
                            taxes. The Agreement shall continue thereafter on an annual basis
                            unless terminated by either WholeHealth Living or Subscriber as
                            set forth in this Agreement.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Program Membership Annual Access Fee.</strong> At the time
                            of Subscriber’s acceptance of this Subscriber Agreement,
                            Subscriber shall pay to WholeHealth Living the annual access fee
                            of $9.99 plus applicable sales taxes and Subscriber shall pay such
                            annual access fee for each subsequent year during the term unless
                            and until this Agreement is terminated. WholeHealth Living may
                            change the annual access fees for the Program from time to time.
                            WholeHealth Living will not change the annual access fees without
                            giving Subscriber at least thirty (30) days prior notice of the
                            change.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Payment Terms. </strong>
                            Subscriber agrees that the annual access fees must be paid by
                            pre-approved credit card or by "automatic" withdrawals from an
                            account that Subscriber maintains in a financial institution
                            pursuant to this Agreement. Subscriber authorizes WholeHealth
                            Living or its designee to make such automatic withdrawals from
                            Subscriber’s account. Subject to change, drafts (payments) are
                            made on or about the day of the month that Subscriber enrolled. If
                            Subscriber changes its financial institutions, Subscriber will
                            provide WholeHealth Living all information needed for the
                            replacement automatic withdrawal at least ten (10) days before the
                            effective date of the change. Any payment that remains unpaid for
                            twenty one (21) days shall entitle WholeHealth Living to terminate
                            this Agreement. If Subscriber pays the fees by an electronic funds
                            transfer arrangement, the closing of Subscriber’s account,
                            insufficient funds, or any other action which prevents the
                            automatic charge for fees to Subscriber’s account shall constitute
                            a material breach of this Agreement. Subscriber agrees to pay
                            reasonable attorney fees, legal expenses, and other lawful
                            collection costs and expenses of collection incurred after a
                            breach of this Agreement. Subscriber acknowledges and agrees that
                            Subscriber’s method of payment shall be processed by a third-party
                            PCI-DSS compliant payments processor.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Termination.</strong> Either Subscriber or WholeHealth
                            Living may terminate this Agreement at any time subsequent to the expiration
                            of the initial term. A termination will be effective at the end of the billing cycle
                            after WholeHealth Living receives Subscriber’s termination request. Subscriber must call
                            WholeHealth Living’s customer service center at (877) 562-7030 in order to terminate this
                            Agreement. If more than one person is enrolled from your household, each person must call
                            individually to terminate. WholeHealth Living is unable to accept terminations from
                            spouses or others on your behalf.  At the time of such termination, Subscriber shall
                            not be entitled to any refunds of annual access fees paid by Subscriber for the year
                            during which such termination was requested.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>ASSUMPTION OF RISK.</strong> SUBSCRIBER EXPRESSLY ASSUME
                            ALL RISK FOR SUBSCRIBER’S HEALTH AND WELL-BEING AND EXPRESSLY
                            ASSUME THE RISKS ASSOCIATED WITH PARTICIPATING IN THE PROGRAM,
                            INCLUDING, BUT NOT LIMITED TO, THE NEGLIGENCE OF WHOLEHEALTH
                            LIVING ITS AFFILIATES, ANY INTEGRATIVE HEALTH PRACTITIONER, AND
                            THEIR OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, AND REPRESENTATIVES.
                            (THE “<strong>RELEASEES”</strong>). SUBSCRIBER HEREBY COVENANTS NOT TO SUE FOR AND
                            RELEASES, WAIVES AND DISCHARGES THE RELEASEES FROM ANY AND ALL
                            DEMANDS, LIABILITIES, LOSSES, OR DAMAGES (INCLUDING DEATH OR
                            DAMAGE TO PROPERTY) CAUSED OR ALLEGED TO BE CAUSED IN WHOLE OR IN
                            PART BY THE NEGLIGENCE OF ANY RELEASEES.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Customer Service.</strong> WholeHealth Living will provide
                            customer service for the Program. For inquiries or to file
                            complaints, Customer service will be available by calling a
                            toll-free telephone number at (877) 562-7030 or by writing to
                            WholeHealth Living at fitnessinquiries@tivityhealth.com. Program
                            information will also be available in printed materials and on the
                            website for the Program.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Trademarks.</strong> All right, title and interest in and
                            to WholeHealth Living’s and its affiliates’ trademarks, service
                            marks, logotypes, symbols and names (the “<strong>WholeHealth Living
                            Marks</strong>”) shall remain in WholeHealth Living. Subscriber shall not
                            use the WholeHealth Living Marks without the prior written consent
                            of WholeHealth Living, and shall cease any such usage immediately
                            upon request.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>LIMITED WARRANTY.</strong> Except as expressly set forth
                            in this Agreement, WholeHealth Living does not warrant that the
                            website or any services provided under this Agreement will meet
                            Subscriber’s requirements or that the operation of the websites
                            prepared by or for WholeHealth Living will be uninterrupted or
                            error free.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Promotional Offers.</strong> WholeHealth Living may
                            occasionally provide Subscriber (i) the opportunity to purchase
                            selected goods and services at a discount from third-party
                            vendors; or (ii) the opportunity to access certain bundled
                            promotional items from third party vendors. These discount and
                            promotional opportunities will contain separate terms and
                            conditions that clearly outline what is required from Subscriber
                            in order to participate in the particular discount or promotion.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>IMPORTANT DISCLOSURE.</strong> SUBSCRIBER AGREES THAT
                            WHOLEHEALTH LIVING MAY PROVIDE INFORMATION DESCRIBING SUBSCRIBER
                            AND SUBSCRIBER’S PARTICIPATION IN AND USE OF THE PROGRAM TO A
                            THIRD PARTY VENDOR, AND SUBSCRIBER’S HEALTH PLAN FOR THE
                            ADMINISTRATION OF VARIOUS HEALTH AND WELLNESS PROGRAMS, INCENTIVE
                            PROGRAMS, RESEARCH AND OTHER PURPOSES, AND TO INCREASE
                            SUBSCRIBER’S AWARENESS OF ADDITIONAL AVAILABLE PROGRAMS.
                            SUBSCRIBER ALSO AGREES THAT WHOLEHEALTH LIVING MAY PROVIDE
                            SUBSCRIBER’S REGISTRATION INFORMATION (INCLUDING BUT NOT LIMITED
                            TO NAME, EMAIL ADDRESS, PHONE/FAX, AND OTHER IDENTIFICATION AND
                            CONTACT INFORMATION) TO SUBSCRIBER’S HEALTH PLAN AND SUBSCRIBER
                            HEREBY AUTHORIZES SUBSCRIBER’S HEALTH PLAN, SEPARATELY AND/OR
                            TOGETHER, TO SEND INFORMATION PROMOTING THE PROGRAM, OTHER
                            OFFERINGS, AND/OR OTHER HEALTH AND WELLNESS OFFERINGS TO
                            SUBSCRIBER.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Assignment.</strong> Subscriber may not assign this
                            Agreement to a third party without the express written approval of
                            a duly authorized representative of WholeHealth Living, and any
                            such attempted assignment shall be void.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Amendment.</strong> WholeHealth Living reserves the right
                            to amend this Agreement at any time, including but not limited to
                            making changes to the terms, conditions, and notices under which
                            the Program is offered. Please periodically review this Agreement
                            as posted in the Program online account because Subscriber’s
                            continued use of the Program will mean that Subscriber accepts any
                            such changes.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Applicable Law.</strong> The validity of this Agreement
                            and of any of its terms and provisions, as well as the rights and
                            duties of WholeHealth Living and Subscriber hereunder, shall be
                            interpreted and enforced pursuant to and in accordance with the
                            laws of the State of Tennessee.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>Disclaimer of Warranties.</strong> EXCEPT AS EXPRESSLY
                            PROVIDED ABOVE, WHOLEHEALTH LIVING PROVIDES THE PROGRAM ON AN “AS
                            IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND.
                            WHOLEHEALTH LIVING EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES,
                            EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, NON- INFRINGEMENT AND
                            FITNESS FOR A PARTICULAR PURPOSE AND ANY IMPLIED WARRANTIES
                            ARISING OUT OF COURSE OF PERFORMANCE OR COURSE OF DEALING.
                            MOREOVER, WHOLEHEALTH LIVING MAKES NO WARRANTY OF ANY KIND AS TO
                            THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE PROGRAM, OR AS TO
                            THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR
                            MATERIAL PROVIDED BY OR THROUGH THE PROGRAM.
                        </p>
                    </li>
                    <li>
                        <p className={Styles.legalParagraph}>
                            <strong>IMPORTANT ADDITIONAL TERMS.</strong> In addition to the
                            general terms and conditions above, the following table provides
                            State law specific provisions that will only apply to Subscriber
                            if such Subscriber resides in that particular State. If there is a
                            conflict in a term or condition set forth in this State law
                            specific table and another term or condition listed elsewhere in
                            this Agreement, the term or condition set forth in this State law
                            specific table shall control.
                        </p>
                    </li>
                </ol>
                <div>
                    <table className={Styles.tableLayout}>
                        <thead>
                            <tr>
                                <td className={Styles.tableHeader}>State</td>
                                <td className={Styles.tableHeader}>Term or Condition</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={Styles.legalParagraph}>Illinois</td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during
                                        the first thirty (30) days following enrollment, WholeHealth
                                        Living shall provide the Subscriber a full refund of the fees
                                        paid by the Subscriber if the request is received in
                                        accordance with the terms set forth in Section 5 of this
                                        Agreement. If WholeHealth Living terminates Subscriber’s
                                        membership in the Program for any reason other than
                                        non-payment of fees, WholeHealth Living shall provide
                                        Subscriber a pro-rata refund of any pre-paid fees paid by the
                                        Subscriber.{" "}
                                    </p>
                                    <strong>
                                        Complaints regarding the Program may be submitted to the
                                        Illinois Department of Insurance in the following ways:{" "}
                                    </strong>
                                    <p className={Styles.legalParagraph}>
                                        <strong>
                                            (a) On-line at{" "}
                                            <a
                                                href="https://mc.insurance.illinois.gov/messagecenter.nsf"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                https://mc.insurance.illinois.gov/messagecenter.nsf
                                            </a>{" "}
                                            <br />
                                            (b) By email at consumer_complaints@ins.state.il.us
                                            <br />
                                            (c) By fax to (217) 558-2083
                                            <br />
                                            (d) By mail to 320 W. Washington Street, Springfield, IL
                                            62767
                                            <br />
                                            (e) For questions to the Department, Subscriber may contact
                                            the Illinois Department of Insurance toll-free at (877)
                                            527-9431.
                                        </strong>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Maryland</p>
                                </td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during
                                        the first thirty (30) days following enrollment, and
                                        WholeHealth Living shall provide the Subscriber a full refund
                                        of the fees paid by the Subscriber if the request is received
                                        in accordance with the terms set forth in Section 5 of this
                                        Agreement.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>Missouri</td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during
                                        the first thirty (30) days following enrollment, and
                                        WholeHealth Living shall provide the Subscriber a full refund
                                        of the fees paid by the Subscriber if the request is received
                                        in accordance with the terms set forth in Section 5 of this
                                        Agreement. This refund must occur within thirty (30) days of
                                        the Subscriber’s proper notice of termination, or the
                                        Subscriber shall be entitled to interest at a rate of the
                                        treasury bill rate of the preceding calendar year, plus two
                                        (2) percentage points. If WholeHealth Living terminates
                                        Subscriber’s membership in the Program for any reason other
                                        than non-payment of fees, WholeHealth Living shall provide
                                        Subscriber a pro-rata refund of any pre-paid fees paid by the
                                        Subscriber.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={Styles.takeWrapAway}>South Carolina</td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during
                                        the first thirty (30) days following enrollment, and
                                        WholeHealth Living shall provide the Subscriber a full refund
                                        of the fees paid by the Subscriber if the request is received
                                        in accordance with the terms set forth in Section 5 of this
                                        Agreement.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={Styles.takeWrapAway}>Delaware</td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during the first
                                        thirty (30) days following enrollment, and  WholeHealth Living  shall provide
                                        the Subscriber a full refund of the fees paid by the Subscriber if the request
                                        is received in accordance
                                        with the terms set forth in Section 5 of this Agreement.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={Styles.takeWrapAway}>Rhode Island</td>
                                <td>
                                    <p className={Styles.legalParagraph}>
                                        A Subscriber may terminate enrollment in the Program during the first thirty
                                        (30) days following enrollment,  WholeHealth Living  shall provide the
                                        Subscriber a full refund of the fees paid by the Subscriber if the request is
                                        received in accordance with
                                        the terms set forth in Section 5 of this Agreement.
                                        <br/><br/>
                                        Subscribers that remain dissatisfied after following the WholeHealth Living
                                        complaint submission process may submit complaints to:
                                        <br/><br/>
                                        Office of the Health Insurance Commissioner (OHIC), <br/>
                                        1151 Pontiac Avenue, Building 69-1 <br/>
                                        Cranston, RI 02920 <br/>
                                        <a href="mailto:OHIC.HealthInsInq@ohic.ri.gov">OHIC.HealthInsInq@ohic.ri.gov</a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div className={Styles.footer}>
                    <input
                        type="checkbox"
                        className={Styles.myCheckbox}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ verticalAlign: "middle" }}
                        id="checkbox"

                    />
                    <label htmlFor="checkbox">I agree to the Choices by WholeHealth Living Subscriber Agreement</label>

                </div>
                <div className={Styles.footer}>
                    <div className={`${Styles.btnContainer} ${isChecked ? Styles.checked : ""}`}>
                        <button
                            className={`${Styles.btn} ${isChecked ? Styles.checked : ""}`}
                            onClick={props.handleContinueBtn}
                            disabled={!isChecked}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionAgreement;
