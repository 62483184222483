/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from "./Toast.module.scss";

const Toast = ({ messages }) => {
  return (
    <>
      {!messages || messages.length <= 0 ? undefined : (
        <section className={styles.container}>
          {messages
            .slice(messages.length - 3, messages.length)
            .map((message, index) => {
              return (
                <ToastItem
                  message={message.message}
                  success={message.success}
                  key={message.key || index}
                />
              );
            })}
        </section>
      )}
    </>
  );
};

export default Toast;

const ToastItem = ({ message, success }) => {
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const UNMOUNT_DELAY_MS = 3000;
    if (isMounted) {
      setTimeout(() => {
        setIsMounted(false);
      }, UNMOUNT_DELAY_MS);
    }

    return () => setIsMounted(true);
  }, []);

  return (
    <div
      className={`
            ${styles.message} 
            ${success ? styles.success : styles.error} 
            ${isMounted ? styles.fadeInUp : styles.fadeOutDown}
            `}
    >
      <button className={styles.close} onClick={() => setIsMounted(false)}>
        &times;
      </button>
      {message}
    </div>
  );
};
