import React, { useEffect, useRef, useState } from 'react';
import { FormTitle, Input } from '../form/Form';
import styles from './ResetPasswordForm.module.scss';
import Loading from '../loading/Loading';
import ActionButton from '../action-button/ActionButton';
import VerifyPasswordTokenDto from '../../dto/VerifyPasswordTokenDto';
import AccountService from '../../services/account.service';
import ResetPasswordDto from '../../dto/ResetPasswordDto';
import ResetPasswordErrors from '../../models/resetPasswordErrors';
import { useResetPasswordMutation, useVerifyPasswordResetTokenMutation } 
    from '../../services/react-query/identity.service';

const ResetPasswordForm = ({userId = null, token = null}) => {
    const [isAnimated, setIsAnimated] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSuccess, setIsSuccess] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);
    const resultMessageReference = useRef();
    const { mutateAsync: verifyPasswordResetToken, isLoading: isVerifyingToken } 
        = useVerifyPasswordResetTokenMutation();
    const { mutateAsync: resetPassword, isLoading: isResettingPassword } = useResetPasswordMutation();

    useEffect(async ()=> {
        const verification = 
            await verifyPasswordResetToken(new VerifyPasswordTokenDto(userId, token)).catch(error => error);
        if(verification) {
            setIsValidToken(verification.isValid);
            if(!verification.isValid) {
                setIsSuccess(false);
                resultMessageReference.current.innerText = verification.message;
            }
        } else {
            setIsSuccess(false);
            resultMessageReference.current.innerText = ResetPasswordErrors.INVALID_LINK;
        }
    },[userId, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSuccess(false);
        setIsAnimated(true);

        if(!AccountService.enforcePasswordRules(newPassword)) 
            return resultMessageReference.current.innerText = ResetPasswordErrors.INVALID_PASS;
        
        if(confirmPassword !== newPassword) 
            return resultMessageReference.current.innerText =  ResetPasswordErrors.PASS_NON_MATCH;

        const passwordReset = await resetPassword(new ResetPasswordDto(userId, token, newPassword, confirmPassword))
            .catch(error => error);

        if(passwordReset) {
            setIsSuccess(passwordReset.isSuccess);
            return resultMessageReference.current.innerText = passwordReset.message;
        }

        return resultMessageReference.current.innerText = ResetPasswordErrors.RESET_FAILED;
    };

    const handlePasswordChange = (event,isNewPassword) => {
        isNewPassword ? setNewPassword(event.target.value) : setConfirmPassword(event.target.value);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <FormTitle title="Reset Your Password"/>
            <Input
                label="New Password"
                onChange={(event)=>handlePasswordChange(event,true)}
                value={newPassword}
                disabled={!isValidToken}
                inputType="password"
                inputId={"newPass"}
            />
            <Input
                label="Confirm Password"
                onChange={(event)=>handlePasswordChange(event,false)}
                value={confirmPassword}
                disabled={!isValidToken}
                inputType="password"
                inputId={"confirmPass"}
            />
            <div className={styles.btnContainer}>
                <ActionButton>Reset Password</ActionButton>
                <span ref={resultMessageReference} 
                    className=
                        {`${isSuccess === null ? styles.hidden : 
                            isSuccess ? styles.successMessage : styles.errorMessage} 
                            ${isAnimated ? styles.animate : undefined}`}
                    onAnimationEnd={()=>setIsAnimated(false)}
                >
                </span>
            </div>
            <Loading isFullScreen={true} isLoading={isVerifyingToken || isResettingPassword}/>
        </form>
    );
};

export default ResetPasswordForm;   