import React, { useState } from "react";
import { Input } from "../form/Form";
import styles from './AccountForm.module.scss';
import AccountIcon from "./AccountIcon";
import PasswordMatchStates from "../../models/passwordMatchStates";
import AccountService from "../../services/account.service";
import VerifyCurrentPasswordDto from "../../dto/VerifyCurrentPasswordDto";
import { useVerifyCurrentPasswordMutation } from '../../services/react-query/identity.service';
import OfferService from "../../services/offer.service";

const UpdatePassword = ({
    oldPasswordReference, 
    newPasswordReference, 
    confirmPasswordReference, 
    onClick, 
    accountEmail,
    confirmedPassword, 
    oldPassword,
    oldPasswordMatch,
    newPassword,
    newPasswordMatch,
    setIsError,
    setNewPassword,
    setNewPasswordMatch,
    setConfirmedPassword,
    setOldPassword,
    setOldPasswordMatch,
}) => {
    const { mutateAsync: verifyCurrentPassword, isLoading: isVerifyingPassword } = useVerifyCurrentPasswordMutation();
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const passwordInputTypes = {
        OLD: "OLD",
        NEW: "NEW",
        CONFIRM: "CONFIRM"
    };

    const onChangePasswordField = (inputReference, event) => {
        setIsError(false); // Reset any previously displayed error messages
        switch (inputReference) {
        case passwordInputTypes.OLD:
            handleOldPasswordInput(event.target.value);
            break;
        case passwordInputTypes.NEW:
            setNewPassword(event.target.value);
            setNewPasswordMatch(confirmedPassword === event.target.value); 
            break;
        case passwordInputTypes.CONFIRM:
            setConfirmedPassword(event.target.value);
            setNewPasswordMatch(newPassword === event.target.value); 
            break;
        default:
            undefined;
            break;
        }
    };

    const handleOldPasswordInput = (oldPasswordInput) => {
        setOldPassword(oldPasswordInput);
        if (oldPasswordInput.length > 0) {
            OfferService.debounceUserInput(async ()=> {     
                const currentPasswordValidation = await verifyCurrentPassword(
                    new VerifyCurrentPasswordDto(accountEmail,oldPasswordInput)).catch(err => err);
                if(!currentPasswordValidation.error) {
                    setOldPasswordMatch(PasswordMatchStates.VERIFIED_MATCH);
                } else {
                    setOldPasswordMatch(PasswordMatchStates.VERIFIED_NON_MATCH);
                }
            }, debounceTimeout, setDebounceTimeout);
        } else {
            OfferService.debounceUserInput(
                async ()=> setOldPasswordMatch(PasswordMatchStates.UNVERIFIED), 
                debounceTimeout, 
                setDebounceTimeout
            );
        }
    };

    return (
        <section className={styles.passwordContainer} onClickCapture={onClick}>
            <div className={styles.formItem}>
                <Input 
                    inputId="oldPass"
                    label="Old Password"
                    inputType="password" 
                    inputValue={oldPassword}
                    onChange={(event)=>onChangePasswordField(passwordInputTypes.OLD,event)}
                    additionalClasses={[]}
                    inputRef={oldPasswordReference}
                    autocomplete="current-password"
                />
                <AccountIcon 
                    className={`${styles.inputIcon} 
                    ${oldPasswordMatch === PasswordMatchStates.UNVERIFIED ? 
            undefined : oldPasswordMatch === PasswordMatchStates.VERIFIED_NON_MATCH 
                ? styles.negative : styles.positive }
                    ${isVerifyingPassword && styles.animate}`} 
                />
            </div>
            <div className={styles.formItem}>
                <Input 
                    inputId="newPass"
                    label="New Password"
                    inputType="password" 
                    inputValue={newPassword}
                    onChange={(event)=>onChangePasswordField(passwordInputTypes.NEW,event)}
                    inputRef={newPasswordReference}
                    autocomplete="new-password"
                />
                <AccountIcon 
                    className={`${styles.inputIcon} 
                ${!newPassword ? undefined : 
            !AccountService.enforcePasswordRules(newPassword) || newPassword === oldPassword ? 
                styles.negative : styles.positive}`} 
                />

            </div>
            <div className={styles.formItem}>
                <Input 
                    inputId="confirmPass"
                    label="Confirm New Password"
                    inputType="password" 
                    inputValue={confirmedPassword}
                    onChange={(event)=>onChangePasswordField(passwordInputTypes.CONFIRM,event)}
                    inputRef={confirmPasswordReference}
                    autocomplete="new-password"
                />
                <AccountIcon className={`${styles.inputIcon} 
                ${!confirmedPassword ? undefined : 
            !newPasswordMatch || newPassword === oldPassword 
                ? styles.negative : styles.positive}`}/>
            </div>
        </section>
    );
};

export default UpdatePassword;