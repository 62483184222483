import React, { useState } from 'react';
import { Input, invalidCharacters, invalidFieldLength} from '../../components/form/Form';
import styles from './RegisterForm.module.scss';
import RegistrationErrors from '../../models/registrationErrors';

const RegisterName = ({
    signupFirstName, 
    signupLastName, 
    setSignUpFirstName,
    setSignUpLastName,
}) => {
    const initialState = {msg:undefined,style:undefined};
    const [invalidFirstName, setInvalidFirstName] = useState(initialState);
    const [invalidLastName, setInvalidLastName] = useState(initialState);

    const handleNameChange = (event, isFirstName) => {
        const nameError = isFirstName ? RegistrationErrors.FIRST_NAME_ERR : RegistrationErrors.LAST_NAME_ERR;
        const name = event.target.value;
        if(name.length > 90) return;

        if(isFirstName) {
            setInvalidFirstName(undefined);
            setSignUpFirstName(name);
        } else {
            setInvalidLastName(undefined);
            setSignUpLastName(name);
        }
        
        if(invalidFieldLength(name,90) || invalidCharacters(name)) {
            isFirstName ?
                setInvalidFirstName({msg:nameError,style:styles.inputError}) :
                setInvalidLastName({msg:nameError,style:styles.inputError});
            return;
        }

        isFirstName ?
            setInvalidFirstName(initialState) :
            setInvalidLastName(initialState);
        return;
    };

    return (
        <section className={styles.formItem} >
            <div className={styles.inputContainer}>
                <Input 
                    inputId="firstName"
                    label="First Name"
                    inputType="text" 
                    inputValue={signupFirstName}
                    additionalClasses={[invalidFirstName.style]}
                    onChange={event=>handleNameChange(event, true)}
                />
                <span key={Date.now()} className={styles.errMsg}>{invalidFirstName.msg}</span>
            </div>
            <div className={styles.inputContainer}>
                <Input 
                    inputId="lastName"
                    label="Last Name"
                    inputType="text" 
                    inputValue={signupLastName}
                    additionalClasses={[invalidLastName.style]}
                    onChange={event=>handleNameChange(event, false)}
                />
                <span key={Date.now()} className={styles.errMsg}>{invalidLastName.msg}</span>
            </div>
        </section>
    );

};

export default RegisterName;