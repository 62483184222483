export default class ProductTypeId {
    static get ANY() {
        return 0;
    }
    static get PROVIDER() {
        return 1;
    }
    static get FACILITY() {
        return 2;
    }
    static get VENDOR() {
        return 3;
    }
}