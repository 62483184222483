import React from 'react';
import Layout from '../../components/layout/Layout';
import BasePage from '../base/BasePage';

const AccessDenied = () => {
    return (
        <Layout pageTitle={"Access Denied"}>
            <BasePage
                headline="Contact Your Health Plan"
                body={`Looks like there’s an issue with your registration. 
                Be sure you are using the link provided by your health plan to access Choices by WholeHealth Living.`}
                btnText="Login"
                form={null}
                isAccessDenied={true}
                supportText={
                    <p>
                        If you need additional support, 
                        email us at <a href="mailto:whlchoices@tivityhealth.com">whlchoices@tivityhealth.com</a>, 
                        contact us at <a href="tel:18002747526">1-800-274-7526</a>, or fill out the form below.
                    </p>
                }
            />
        </Layout>
    );
};

export default AccessDenied;