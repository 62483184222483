import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../action-button/ActionButton';
import styles from './InfoCard.module.scss';

const InfoCard = ({
    headline, 
    body, 
    btnText, 
    onClick, 
    linkText, 
    linkTarget="", 
    isAccessDenied = false, 
    supportText = null
}) => {
    const [animate, setAnimate] = useState(true);
    
    useEffect(()=> {
        if(!animate) setAnimate(true);
    },[headline,body]);

    return (
        <article 
            className={styles.cardContainer}
            onAnimationEnd={()=>setAnimate(false)}    
        >
            <h1 
                className={`${styles.cardTitle} ${animate ? styles.animate : undefined}`}
            >
                {headline}
            </h1>
            <section 
                className={`${styles.cardBody} ${animate ? styles.animate : undefined}`}
            >
                <p>{body}</p>
                {isAccessDenied ? supportText : undefined}
            </section>
            {!isAccessDenied &&
                <menu className={styles.cardMenu}>
                    <ActionButton onClick={onClick}>{btnText}</ActionButton>
                    {!linkText ? undefined : <Link className={styles.cardLink} to={"/"+linkTarget}>{linkText}</Link>}
                </menu>}
        </article>
    );
};

export default InfoCard;