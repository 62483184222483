//these key value pairs are temporary and used as a placeholder data source in ResourceCenterDetailsPage.  

export default [
    {
        headerText: "Find the latest news and updates from WholeHealth Living",
        buttonText: "Browse Now",
        buttonColor: "white",
        buttonSize: "large",
        buttonLinkTo: "google.com",
        articleHeader: "6 Ways to Stay on Top of Your Excercise Goals",
        articleReadTime: "5 min read",
        articleBGimg: "images/stretching_full.jpg",
        articleButtonLinkTo: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        articleButtonText: "View Now",
        articleButtonColor: "navy",
        articleButtonSize: "large"
    },
    // {
    //     cardBGimg: "",
    //     cardTitle: "",
    //     cardReadTime: "",
    //     cardButton: {
    //         cardButtonText: "",
    //         cardButtonColor: "",
    //         cardButtonSize: "",
    //         cardButtonLinkTo: ""
    //     }
    // },
];
