/* eslint-disable indent */
import React from "react";
import VisibilityFilter from "../../components/client-filter/VisibilityFilter";
import NavbarLink from "./NavbarLink";
import styles from './Navbar.module.scss';
import { useChoicesContext } from '../../context/ChoicesContext';
import EnvironmentService from "../../services/environment.service";
import {
    useGetClientByIdQuery
} from '../../services/react-query/choices.service';
import { useAuth0 } from '@auth0/auth0-react';

const AuthorizedNav = ({ onClick }) => {
    const accessToken = localStorage.getItem("choices-token");
    const storedExpiration = localStorage.getItem('choices-access-expires');

    const referingUrlBSBC = EnvironmentService
        .getConfigurationVariable("ECOMMERCE_CLIENT_URLS")
        .indexOf(window.sessionStorage['choices-referring-domainUrl']
        ?.toLowerCase()) > -1 ? true : false;
    const devEnvironment = EnvironmentService.getEnvironmentString() === "development"
        || EnvironmentService.getEnvironmentString() === 'qa';

    const choicesClientId = localStorage.getItem("choices-clientId") !== null
        ? localStorage.getItem("choices-clientId") : sessionStorage.getItem("choices-referring-clientId");

    const { data: clientData, error: clientError, isLoading: isGettingClient } =
        useGetClientByIdQuery(choicesClientId);

    const WellBeingMarketplaceClients = EnvironmentService
        .getConfigurationVariable("WELL_BEING_MARKETPLACE").toLowerCase();

    const { isAuthenticated } = useAuth0();
    if (isAuthenticated) {
        return (
            <>
                {/* Use visibility filter to hide provider and facility search from SilverSneakers members. */}

                { clientData && WellBeingMarketplaceClients.indexOf(clientData?.name?.toLowerCase()) < 0 &&

                    <VisibilityFilter className={styles.navLink}>
                        <NavbarLink onClick={onClick} pathTo="/practitioners" displayText="Practitioners" />
                    </VisibilityFilter>
                }

                <NavbarLink onClick={onClick} pathTo="/products" displayText="Products & Services" />

                { clientData && WellBeingMarketplaceClients.indexOf(clientData?.name?.toLowerCase()) < 0 &&
                    (
                        referingUrlBSBC ? <></> :
                        <VisibilityFilter className={styles.navLink}>
                            <NavbarLink
                                onClick={onClick}
                                pathTo="/fitness-centers"
                                displayText="Fitness & Wellness Centers"
                            />
                        </VisibilityFilter>
                    )
                }

                { devEnvironment &&
                    <NavbarLink onClick={onClick} pathTo="/resource-center" displayText="Resource Center" /> }

                <NavbarLink onClick={onClick} pathTo="/account" displayText="My Account" />
            </>
        );
    }

    return (
        <>
            <NavbarLink onClick={onClick} pathTo="/login" displayText="Login" />
        </>);  
};

export default AuthorizedNav;
