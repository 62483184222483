/* eslint-disable max-len */
import React from "react";
import styles from './CancelSubscriptionInput.module.scss';

const LogoMark = () => {
    return (
        <div className={styles.logoMark} aria-label="Wholehealth Living logo">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 70 70">
                <path fill="rgba(0,118,190, 0.2)"className="cls-2" d="M12.44,21.5C17.8,100,61.93,80.69,61.93,80.69l-1.26-3.76-4-3.47c-29.18,7.34-33.41-35-33.41-35A54,54,0,0,1,36,50.2L40.46,43C29,29.88,12.44,21.5,12.44,21.5Z" transform="translate(-12.44 -6.62)"></path>
                <path fill="rgba(0,118,190, 0.4)" d="M57.34,6.62C21.12,76.48,68.86,82.9,68.86,82.9L69.73,79,68.12,74c-28.75-8.9-10.33-47.31-10.33-47.31a63.57,63.57,0,0,1,5,18.13l7.78-3.37C68.09,24.2,57.34,6.62,57.34,6.62Z" transform="translate(-12.44 -6.62)"></path>
                <path fill="rgba(0,118,190, 0.6)" d="M73,92.91s-37.8-29.86,29-71.5c0,0,.91,42.48-21.36,55.19l-3.4-7.71s12.38-7.18,14.91-30c0,0-37.5,25-13.34,47.89Z" transform="translate(-12.44 -6.62)"></path>
            </svg>
        </div>
    );
};

export default LogoMark;