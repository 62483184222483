import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styles from './RecentActivityPage.module.scss';
import Loading from '../../components/loading/Loading';
import RecentActivityCard from '../../components/recent-activity/card/RecentActivityCard';
import Logo from '../../components/logo/Logo';
import RecentActivityPagination from '../../components/recent-activity/pagination/RecentActivityPagination';
import { useGetRecentActivityQuery } from '../../services/react-query/choices.service';
import Layout from '../../components/layout/Layout';
import NavigationColumn from '../../components/navigation-column/NavigationColumn';

const RecentActivityPage = ({ environment }) => {
    const { data, error, isLoading } = useGetRecentActivityQuery(localStorage.getItem("choices-email"));
    const [recentActivities, setRecentActivities] = useState([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const PAGE_LIMIT = 5;

    useEffect(() => {
        if (data) {
            setRecentActivities(data);
        }
    }, [data]);

    return (
        <Layout pageTitle={"Recent Activity"}>
            <section className={styles.recentActivityPageContainer}>
                <NavigationColumn
                    environment={environment}
                />
                <div className={styles.recentActivityContentContainer}>
                    <h1 className={styles.recentActivitytitle}>Recent Activity</h1>
                    <div className={styles.activityResults}>
                        {recentActivities.length < 1 && !isLoading ?
                            <p className={styles.noActivity}>
                                No activity recorded.
                            </p> :
                            recentActivities.slice(PAGE_LIMIT * (currentPageNumber - 1),
                                currentPageNumber !== 1 ? (PAGE_LIMIT * (currentPageNumber - 1)) + 5 : PAGE_LIMIT)
                                .map((activity, index) => {
                                    return (
                                        <div key={index}>
                                            <RecentActivityCard
                                                recentActivity={activity}
                                            />
                                        </div>
                                    );
                                })
                        }
                        {recentActivities.length < 1 || isLoading ? undefined :
                            <div className={styles.pagination}>
                                <RecentActivityPagination
                                    totalRecords={recentActivities.length}
                                    pageLimit={PAGE_LIMIT}
                                    pageState={{
                                        currentPageNumber: currentPageNumber,
                                        setCurrentPageNumber: setCurrentPageNumber
                                    }}
                                />
                            </div>}
                    </div>
                </div>

                <Loading isFullScreen={true} isLoading={isLoading} />
                <Logo />
            </section>
        </Layout>
    );
};

export default withAuthenticationRequired(RecentActivityPage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} /></div>,
});
