import React from "react";
import styles from "./DetailList.module.scss";

const DetailList = ({ productDetail, isTerms }) => {
    return (
        <section className={styles.listContainer}>
            {!productDetail ? undefined :
                <div>
                    <p className={styles.introduction}>{isTerms ? productDetail.offer : productDetail.introduction}</p>
                    <ul>
                        {isTerms ?
                            productDetail.conditions.map((condition, ind) => {
                                return (
                                    <li key={ind}>
                                        <span dangerouslySetInnerHTML={{ __html: condition }}></span>
                                    </li>
                                );
                            })
                            : productDetail.features.map((feature, index) => {
                                return (
                                    <li key={feature + index}>
                                        <span dangerouslySetInnerHTML={{ __html: feature }}></span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            }
        </section>
    );
};

export default DetailList;