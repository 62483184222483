import React from 'react';
import ActionButton from '../../action-button/ActionButton';
import styles from './RecentActivityCard.module.scss';
import OfferService from '../../../services/offer.service';
import { useNavigate } from 'react-router-dom';

const RecentActivityCard = ({recentActivity, isCarouselCard}) => {
    const navigate= useNavigate();
    const handleClick = () => {
        navigate(OfferService.getSearchPagePath(recentActivity.productType) + `/${recentActivity.productID}`);
    };
    
    // Each CSS class has an alternate - this alternate is used when the cards are displayed on a carousel.
    return (
        <section className={isCarouselCard ? styles.altCardContainer : styles.cardContainer}>
            <div className={isCarouselCard ? styles.altImgContainer : styles.imgContainer}>
                <img 
                    // eslint-disable-next-line max-len
                    src={`/images/${OfferService.getOfferItemType(recentActivity.productType)}-activity.svg`} 
                    alt="Product Type Icon"
                />
            </div>
            <div className={isCarouselCard ? styles.altContainer : styles.container}>
                <div className={isCarouselCard ? styles.altText : styles.text}>
                    <p className={isCarouselCard ? styles.altTitle : styles.title}>
                        {recentActivity.discount}% Discount on {recentActivity.name}
                    </p>
                    <p className={isCarouselCard ? styles.altAction : styles.action}>
                        {OfferService.getOfferActionVerb(recentActivity.purchaseType)
                        } on {new Date(recentActivity.purchaseDate).toLocaleDateString()}
                    </p>
                </div>
                <div className={isCarouselCard ? styles.altButton : styles.button}>
                    <ActionButton 
                        type="button"
                        onClick={handleClick}
                    >
                            View Details
                    </ActionButton>
                </div>
            </div>
        </section>
    );
};

export default RecentActivityCard;