import React from 'react';
import styles from './Filter.module.scss';

const FilterInput = ({id, label, inputType, inputValue, isLocation, onChange, isDisabled}) => {

    return (
        <div className={styles.filterElementContainer}>
            <label className={styles.filterElementLabel} htmlFor={id}>{label}</label>
            <img 
                loading="lazy" 
                className={styles.inputIcon} 
                src={`./images/${isLocation ? "locationIcon" : "carIcon"}.svg`} 
                alt={`${isLocation ? 'Location marker' : 'Vehicle'} icon`} />
            <input 
                id={id} 
                type={inputType} 
                className={!isDisabled ? styles.filterInput : styles.disabledFilterInput} 
                value={inputValue || ''} 
                onChange={onChange}
                disabled={isDisabled}
            />
            {!isLocation && <span className={styles.radiusInputText}>MILES</span>}
        </div>
    );
};

export default FilterInput;