import PropTypes from 'prop-types';
import styles from './ArticleCard.module.scss';
import StyledLink from '../styled-link/StyledLink';

const ArticleCard = ({ cardBGimg, cardButton, cardReadTime, cardTitle }) => {
    
    const BGstyle = {
        background: `url(${cardBGimg})`,
        backgroundSize: "cover",
        backgroundPosition: "95% 10%",
        backgroundRepeat: "no-repeat",
    };

    return (
        <>
            <div className={styles.articleCardContainer}>
                <div className={styles.bgContainer} style={BGstyle}>
                    <div className={styles.articleCard} >
                        <h3 className={styles.cardReadTime}>{cardReadTime}</h3>
                        <h1 className={styles.cardTitle}>{cardTitle}</h1>

                        <StyledLink
                            styledLinkText={cardButton.props.styledLinkText}
                            styledLinkColor={cardButton.props.styledLinkColor}
                            styledLinkSize={cardButton.props.styledLinkSize}
                            linkTo={cardButton.props.styledLinkLinkTo}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

ArticleCard.propTypes = {
    cardBGimg: PropTypes.string,
    cardButton: PropTypes.object,
    cardReadTime: PropTypes.string,
    cardTitle: PropTypes.string
};

export default ArticleCard;