import React  from "react";
import { Input } from "../form/Form";
import styles from './AccountForm.module.scss';
import { useChoicesContext } from "../../context/ChoicesContext";
import AccountService from "../../services/account.service";

const NameInput = ({firstNameRef, lastNameRef, onClick, clearErrorMessages}) => {
    const { firstName, lastName, setFirstName, setLastName } = useChoicesContext();

    const handleNameChange = () => {
        clearErrorMessages();
        setFirstName(firstNameRef.current.value);
        setLastName(lastNameRef.current.value);
    };

    return (
        <section className={styles.formItem} onClickCapture={onClick}>
            <div className={styles.inputContainer}>
                <Input 
                    inputId="firstName"
                    label="First Name"
                    inputType="text" 
                    inputValue={AccountService.isLooseNullOrUndefined(firstName)?"":firstName}
                    additionalClasses={[styles.accountInput]}
                    onChange={handleNameChange}
                    inputRef={firstNameRef}
                />
            </div>
            <div className={styles.inputContainer}>
                <Input 
                    inputId="lastName"
                    label="Last Name"
                    inputType="text" 
                    inputValue={AccountService.isLooseNullOrUndefined(lastName)?"":lastName}
                    onChange={handleNameChange}
                    inputRef={lastNameRef}
                />
            </div>
        </section>
    );
};

export default NameInput;