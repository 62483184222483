import React, { useMemo, useState } from "react";
import styles from "./ProductCard.module.scss";
import ActionButton from '../../components/action-button/ActionButton';
import { useNavigate } from "react-router-dom";
import OfferService from "../../services/offer.service";
import AnalyticsService from "../../services/analytics.service";

const ProductCard = ({product}) => {
    const [productName, setProductName] = useState(product.name);
    const [description, setDescription] = useState(JSON.parse(product.productDescription));
    const navigate = useNavigate();

    const handleClick = () => {
        AnalyticsService.trackOfferView(product);
        navigate(`/products/${product.id}`);
    };

    useMemo(()=> {
        const maxLength = 20;
        if(product.name.length > maxLength) {
            let maxLengthName = product.name.substring(0, maxLength);
            let lastWordTrimmedName = maxLengthName
                .substring(0, Math.min(maxLengthName.length, maxLengthName.lastIndexOf(" "))).replace(",","");
            if(lastWordTrimmedName.indexOf("–") > 0) {
                lastWordTrimmedName = lastWordTrimmedName.substring(0, lastWordTrimmedName.indexOf("–") - 1).trim();
            }
            setProductName(lastWordTrimmedName);
        }
    },[productName]);

    return (
        <article className={styles.cardContainer}>
            <header className={styles.cardHeader}>
                {productName.indexOf(" ") === -1 && productName.length > 11 ?
                    productName.substring(0,11) + " " + productName.substring(11,productName.length) : productName
                }
            </header>
            <div className={styles.imageContainer}>
                <img
                    src={`/images/vendors/${product.image}`}
                    alt={`A photo for the product ${product.name}`}
                    onError={(event)=>OfferService.useFallbackImage(
                        event,
                        "/images/product-fallback.jpg"
                    )}
                    loading="eager" />
            </div>
            <section className={styles.cardBody}>
                <div
                    className={styles.discountPercentage}
                    dangerouslySetInnerHTML={
                        { __html: (product.webDiscountVerbiage
                            || description.terms.offerDisplayHtml
                            || '<span>See offer details</span>')
                        }
                    }>
                </div>
                <div className={styles.btnContainer}>
                    <ActionButton
                        onClick={handleClick}
                        btnType="button"
                    >View Details</ActionButton>
                </div>
            </section>
        </article>
    );
};

export default ProductCard;
